import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BecksSnackbarComponent } from 'src/app/library/snackbar/components/becks-snackbar.component';

@Injectable()
export class SnackbarService {
  constructor(public snackBar: MatSnackBar) {}

  success(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 3000,
      panelClass: ['success', 'becks-snackbar']
    });
  }

  error(message: string) {
    const openSnack = this.snackBar.open(message, 'DISMISS', {
      panelClass: ['error', 'becks-snackbar']
    });
    const subscription = openSnack.onAction().subscribe(() => {
      openSnack.dismiss();
      subscription.unsubscribe();
    });
  }

  warn(message: string) {
    const openSnack = this.snackBar.openFromComponent(BecksSnackbarComponent, {
      panelClass: ['warn', 'becks-snackbar'],
      data: {
        type: 'warn',
        message,
        button: 'DISMISS'
      }
    });
    const subscription = openSnack.onAction().subscribe(() => {
      openSnack.dismiss();
      subscription.unsubscribe();
    });
  }
}
