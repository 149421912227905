import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IAcreageOverviewSummaryVM } from 'src/app/shared/models/view-model/acreage-overview-vm.interface';

import * as fromGlobal from '../reducers';
import * as fromAcreageOverview from '../reducers/acreage-overview.reducer';

export const getAcreageOverviewState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.acreageOverview
);

export const getAcreageOverview = createSelector(
  getAcreageOverviewState,
  fromAcreageOverview.getAcreageOverview
);
export const getAcreageOverviewPreviousSnapshot = createSelector(
  getAcreageOverviewState,
  fromAcreageOverview.getAcreageOverviewPreviousSnapshot
);
export const getAcreageOverviewLoaded = createSelector(
  getAcreageOverviewState,
  fromAcreageOverview.getAcreageOverviewLoaded
);
export const getAcreageOverviewLoading = createSelector(
  getAcreageOverviewState,
  fromAcreageOverview.getAcreageOverviewLoading
);
export const getAcreageOverviewErred = createSelector(
  getAcreageOverviewState,
  fromAcreageOverview.getAcreageOverviewErred
);
export const getAcreageOverviewIsPanelExpanded = createSelector(
  getAcreageOverviewState,
  fromAcreageOverview.getAcreageOverviewIsPanelExpanded
);

/** Derived Selectors */
export const getAcreageOverviewSummary: MemoizedSelector<
  object,
  IAcreageOverviewSummaryVM[]
> = createSelector(
  getAcreageOverview,
  getAcreageOverviewPreviousSnapshot,
  (overview, previousSnapshot) => {
    const forecast = {
      type: 'FC',
      fertile: overview.forecast.fertile,
      sterile: overview.forecast.sterile,
      total: overview.forecast.fertile + overview.forecast.sterile,
      ...(previousSnapshot
        ? {
            fertileDelta: Math.round(overview.forecast.fertile - previousSnapshot.forecast.fertile),
            sterileDelta: Math.round(overview.forecast.sterile - previousSnapshot.forecast.sterile),
            totalDelta: Math.round(
              overview.forecast.fertile +
                overview.forecast.sterile -
                (previousSnapshot.forecast.fertile + previousSnapshot.forecast.sterile)
            )
          }
        : {
            fertileDelta: 0,
            sterileDelta: 0,
            totalDelta: 0
          })
    };

    const plan = {
      type: 'PL',
      fertile: overview.plan.fertile,
      sterile: overview.plan.sterile,
      total: overview.plan.fertile + overview.plan.sterile,
      ...(previousSnapshot
        ? {
            fertileDelta: Math.round(overview.plan.fertile - previousSnapshot.plan.fertile),
            sterileDelta: Math.round(overview.plan.sterile - previousSnapshot.plan.sterile),
            totalDelta: Math.round(
              overview.plan.fertile +
                overview.plan.sterile -
                (previousSnapshot.plan.fertile + previousSnapshot.plan.sterile)
            )
          }
        : {
            fertileDelta: 0,
            sterileDelta: 0,
            totalDelta: 0
          })
    };

    return [forecast, plan];
  }
);
