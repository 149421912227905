import * as _ from 'lodash';
import { IPSUser } from '../../../../shared/models/data-model/user.interface';

import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { loadUsers, loadUsersFail, loadUsersSuccess } from '../actions';

export const usersAdapter = createEntityAdapter<IPSUser>({
  selectId: (user) => user.ID
});

interface UsersEntityState extends EntityState<IPSUser> {}

export interface UsersState {
  model: {
    users: UsersEntityState;
  };
  viewModel: {
    usersLoaded: boolean;
    usersError: boolean;
    usersLoading: boolean;
  };
}

export const initialState: UsersState = {
  model: {
    users: usersAdapter.getInitialState()
  },
  viewModel: {
    usersLoaded: false,
    usersError: false,
    usersLoading: false
  }
};

export const usersReducer = createReducer(
  initialState,

  on(loadUsersSuccess, (state, { users }) => ({
    model: {
      users: usersAdapter.setAll(users, state.model.users)
    },
    viewModel: {
      ...state.viewModel,
      usersLoaded: true,
      usersLoading: false
    }
  })),

  on(loadUsersFail, (state) => ({
    ...state,
    viewModel: {
      ...state.viewModel,
      usersError: true,
      usersLoading: false,
      usersLoaded: false
    }
  })),

  on(loadUsers, (state) => ({
    ...state,
    viewModel: {
      ...state.viewModel,
      usersLoading: true,
      usersLoaded: false
    }
  }))
);
