import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

import { IGRWGrower } from 'src/app/shared/models/data-model/growers-module.interface';
import {
  loadGrowersSuccess,
  updateGrowerSuccess,
  loadGrowersFailure,
  loadGrowersFromGrowerManagementPage
} from 'src/app/root-store/global-store/store/actions/growers.actions';

interface GrowersEntityState extends EntityState<IGRWGrower> {}

export interface GrowersState {
  model: {
    growers: GrowersEntityState;
  };
  viewModel: {
    growersLoaded: boolean;
    growersError: boolean;
  };
}

export const growerAdapter = createEntityAdapter<IGRWGrower>({
  selectId: (grower) => grower.ID
});

export const initialState: GrowersState = {
  model: {
    growers: growerAdapter.getInitialState()
  },
  viewModel: {
    growersLoaded: false,
    growersError: false
  }
};

export const reducer = createReducer(
  initialState,

  on(loadGrowersFromGrowerManagementPage, (state) => ({
    ...state,
    viewModel: {
      ...state.viewModel,
      growersLoaded: false,
      growersError: false
    }
  })),

  on(loadGrowersSuccess, (state, { growers }) => ({
    model: {
      growers: growerAdapter.setAll(growers, state.model.growers)
    },
    viewModel: {
      ...state.viewModel,
      growersLoaded: true
    }
  })),

  on(loadGrowersFailure, (state) => ({
    ...state,
    viewModel: {
      ...state.viewModel,
      growersError: true
    }
  })),

  on(updateGrowerSuccess, (state, { grower }) => ({
    ...state,
    model: {
      growers: growerAdapter.updateOne(
        {
          changes: grower,
          id: grower.ID
        },
        state.model.growers
      )
    }
  }))
);
