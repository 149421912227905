import { sanitizeForm } from 'src/app/shared/helpers/sanitizers.helper';
import { SalesForecastState } from './../reducers/sales-forecast.reducer';

export function sanitize(state: SalesForecastState): {} | undefined {
  if (state && state.model && state.viewModel) {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        filterForm: sanitizeForm(state.viewModel.filterForm)
      }
    };
  } else {
    return;
  }
}
