import { Action } from '@ngrx/store';
import { IAcreageOverview } from 'src/app/shared/models/data-model/acreage-overview.interface';

export enum AcreageOverviewActionTypes {
  LoadAcreageOverviewAction = '[Global: Acreage Overview] Load Acreage Overview',
  LoadAcreageOverviewFailAction = '[Global: Acreage Overview] Load Acreage Overview Fail',
  LoadAcreageOverviewSuccessAction = '[Global: Acreage Overview] Load Acreage Overview Success',

  ToggleAcreageOverviewPanelExpandedAction = '[Global: Acreage Overview] Toggle Panel Expanded'
}

export class LoadAcreageOverview implements Action {
  readonly type = AcreageOverviewActionTypes.LoadAcreageOverviewAction;
  constructor(public payload: boolean = false) {} // take snapshot
}
export class LoadAcreageOverviewFail implements Action {
  readonly type = AcreageOverviewActionTypes.LoadAcreageOverviewFailAction;
}
export class LoadAcreageOverviewSuccess implements Action {
  readonly type = AcreageOverviewActionTypes.LoadAcreageOverviewSuccessAction;
  constructor(public payload: IAcreageOverview) {}
}

export class ToggleAcreageOverviewPanelExpanded implements Action {
  readonly type = AcreageOverviewActionTypes.ToggleAcreageOverviewPanelExpandedAction;
}

export type AcreageOverviewActions =
  | LoadAcreageOverview
  | LoadAcreageOverviewFail
  | LoadAcreageOverviewSuccess
  | ToggleAcreageOverviewPanelExpanded;
