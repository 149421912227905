import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { ISite } from 'src/app/shared/models/data-model/region.interface';
import { SiteActions, SiteActionTypes } from '../actions';

interface SiteManagementSitesEntityState extends EntityState<ISite> {}
export const siteManagementSitesAdapter = createEntityAdapter<ISite>({
  selectId: (site) => site.ID
});

export interface SitesState {
  model: {
    sites: SiteManagementSitesEntityState;
  };
  viewModel: {
    sitesLoaded: boolean;
    sitesLoading: boolean;
    sitesErred: boolean;
  };
}

export const initialState: SitesState = {
  model: {
    sites: siteManagementSitesAdapter.getInitialState()
  },
  viewModel: {
    sitesLoaded: false,
    sitesLoading: false,
    sitesErred: false
  }
};

export function reducer(state = initialState, action: SiteActions): SitesState {
  switch (action.type) {
    case SiteActionTypes.LoadSitesAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          sitesLoading: true,
          sitesLoaded: false,
          sitesErred: false
        }
      };
    }
    case SiteActionTypes.LoadSitesFailAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          sitesLoaded: false,
          sitesLoading: false,
          sitesErred: true
        }
      };
    }
    case SiteActionTypes.LoadSitesSuccessAction: {
      return {
        ...state,
        model: {
          ...state.model,
          sites: siteManagementSitesAdapter.setAll(action.payload, state.model.sites)
        },
        viewModel: {
          ...state.viewModel,
          sitesLoaded: true,
          sitesLoading: false,
          sitesErred: false
        }
      };
    }

    default:
      return state;
  }
}
