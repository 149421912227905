import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import EscapeHtmlPipe from './escape-html.pipe';
import { FilterByPipe } from './filter-by.pipe';
import { FirstLetterPipe } from './first-letter.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { SafePipe } from './safe.pipe';
import { TypeOfPipe } from './type-of.pipe';

@NgModule({
  declarations: [
    FirstLetterPipe,
    PhoneNumberPipe,
    EscapeHtmlPipe,
    SafePipe,
    TypeOfPipe,
    FilterByPipe
  ],
  imports: [CommonModule],
  exports: [
    FirstLetterPipe,
    PhoneNumberPipe,
    EscapeHtmlPipe,
    SafePipe,
    TypeOfPipe,
    FilterByPipe,
    TitleCasePipe,
    DatePipe
  ]
})
export class PipesModule {}
