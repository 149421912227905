import { createAction, props } from '@ngrx/store';
import { IAppDefault } from 'src/app/shared/models/data-model/app-defaults.interface';

export const loadAppDefaults = createAction('[Global: App Defaults] Load App Defaults');
export const loadAppDefaultsSuccess = createAction(
  '[Global: App Defaults - PS API] Load App Defaults Success',
  props<{ appDefaults: IAppDefault[] }>()
);
export const loadAppDefaultsFailure = createAction(
  '[Global: App Defaults - PS API] Load App Defaults Failure',
  props<{ error: any }>()
);
