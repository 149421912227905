import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_CANCEL, ROUTER_ERROR, ROUTER_NAVIGATED, ROUTER_REQUEST } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SnackbarService } from 'src/app/library/snackbar';
import { getBaseUri } from 'src/app/shared/helpers/app-modules.helper';
import { getBreakpoints } from 'src/app/shared/helpers/layout.helper';
import { IBreakpoint } from 'src/app/shared/models/data-model/layout.interface';
import * as fromActions from '../actions';
import { SystemInfoActionTypes } from '../actions';
import * as fromReducers from '../reducers';
import * as fromSelectors from '../selectors';

@Injectable()
export class SystemInfoEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<fromReducers.GlobalState>,
    private _media: MediaObserver,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _snackbarService: SnackbarService
  ) {}

  navigationStarted$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_REQUEST),
      switchMap(() => {
        return of(new fromActions.ShowLoadingIndicator());
      })
    )
  );

  navigationEnded$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED, ROUTER_CANCEL, ROUTER_ERROR),
      switchMap(() => {
        return of(new fromActions.HideLoadingIndicator());
      })
    )
  );

  onMediaChanges$ = createEffect(() =>
    this._media.asObservable().pipe(
      switchMap((changes: MediaChange[]) => {
        const breakpointsMap = getBreakpoints();
        const breakpointArray = Object.values(breakpointsMap);

        breakpointArray.forEach((breakpoint: IBreakpoint) => {
          breakpoint.applied = this._breakpointObserver.isMatched(breakpoint.mediaQuery);
        });

        const appliedBreakpoints = _.keyBy(breakpointArray, 'id');
        return of(new fromActions.SetBreakpointEntities(appliedBreakpoints));
      })
    )
  );

  routeAppHome$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SystemInfoActionTypes.RouteToHomeAppPageAction),
        tap(() => {
          const baseUri = getBaseUri(window.location.pathname);
          this._router.navigate(['/', baseUri]);
        })
      ),
    { dispatch: false }
  );

  routeHome$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SystemInfoActionTypes.RouteToHomePageAction),
        tap((action: { errorMsg: string }) => {
          this._snackbarService.error(action.errorMsg);
          this._router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );

  routeToLogout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SystemInfoActionTypes.RouteToLogoutPageAction),
        tap(() => {
          this._router.navigate(['/logout']);
        })
      ),
    { dispatch: false }
  );

  reloadCurrentAppModule$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(SystemInfoActionTypes.ReloadCurrentAppModuleAction),
        withLatestFrom(this._store.select(fromSelectors.getSystemInfoActiveBaseUri)),
        tap(([action, baseUri]) => {
          // get assemble module app base url
          const url = `${window.location.origin}/${baseUri}`;
          // assign new url to force page reload
          window.location.href = url;
        })
      ),
    { dispatch: false }
  );
}
