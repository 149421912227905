<div class="home">
  <div class="home__navbar env {{ env }}">
    <div class="home__navbar-logo-wrapper">
      <a class="home__navbar-logo" href="/">
        <img *ngIf="env !== 'pre'" src="assets/images/prodsuite-logo.png" height="48" />
        <img *ngIf="env === 'pre'" src="assets/images/prodsuite-logo-white.svg" height="48" />
      </a>
      <ps-environment-badge *ngIf="env !== 'prod'" [sidebarOpened]="true" [isFromHome]="true"></ps-environment-badge>
    </div>
  </div>
  <div>
    <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate" class="home__loading-bar"></mat-progress-bar>
  </div>

  <div class="home__container">
    <img src="assets/images/home-banner.png" class="home__banner" />
    <div *ngIf="authorizedModules$ | async as authModules" class="home__dashboard">
      <mat-card
        *ngIf="authModules[enumAppModule.capps]"
        class="home__dashboard__link mat-elevation-zcustom clickable"
        routerLink="/capps"
        matRipple
        [matRippleCentered]="centered"
        [matRippleDisabled]="disabled"
        [matRippleUnbounded]="unbounded"
        [matRippleRadius]="radius"
        [matRippleColor]="color"
      >
        <section class="home__dashboard__img">
          <img src="assets/images/capps-logo-home.png" />
        </section>
        <section class="home__dashboard__desc">
          <p>Computer Assisted Production Planning System for planning out variety and hybrid seed production.</p>
        </section>
      </mat-card>
      <mat-card
        *ngIf="authModules[enumAppModule.seedtracker]"
        class="home__dashboard__link mat-elevation-zcustom clickable"
        routerLink="/seedtracker"
        matRipple
        [matRippleCentered]="centered"
        [matRippleDisabled]="disabled"
        [matRippleUnbounded]="unbounded"
        [matRippleRadius]="radius"
        [matRippleColor]="color"
      >
        <section class="home__dashboard__img">
          <img src="assets/images/st-logo-home.png" />
        </section>
        <section class="home__dashboard__desc">
          <p>SeedTracker is used to track and manage batches of seed from harvest to bagging.</p>
        </section>
      </mat-card>
      <mat-card
        *ngIf="authModules[enumAppModule.labtracker]"
        class="home__dashboard__link mat-elevation-zcustom clickable"
        routerLink="/labtracker"
        matRipple
        [matRippleCentered]="centered"
        [matRippleDisabled]="disabled"
        [matRippleUnbounded]="unbounded"
        [matRippleRadius]="radius"
        [matRippleColor]="color"
      >
        <section class="home__dashboard__img">
          <img src="assets/images/labtracker-logo-home.png" style="max-width: 90%" />
        </section>
        <section class="home__dashboard__desc">
          <p>LabTracker is used to track and manage seed samples from sampling to lab results.</p>
        </section>
      </mat-card>
      <section class="home__dashboard__grid">
        <mat-card
          *ngIf="authModules[enumAppModule.usermanagement]"
          class="home__dashboard__link mat-elevation-zcustom clickable"
          [routerLink]="getUMRoute(authorizedUmFeatures$ | async)"
          matRipple
          [matRippleCentered]="centered"
          [matRippleDisabled]="disabled"
          [matRippleUnbounded]="unbounded"
          [matRippleRadius]="radius"
          [matRippleColor]="color"
        >
          <i class="fas fa-users"></i>
          <div class="home__small-card-title">User Management</div>
        </mat-card>
        <mat-card
          class="home__dashboard__link mat-elevation-zcustom clickable"
          routerLink="/documentation/releasenotes"
          matRipple
          [matRippleCentered]="centered"
          [matRippleDisabled]="disabled"
          [matRippleUnbounded]="unbounded"
          [matRippleRadius]="radius"
          [matRippleColor]="color"
        >
          <i class="fas fa-comment-alt-lines"></i>
          <div class="home__small-card-title">Release Notes</div>
        </mat-card>
        <a href="mailto:support@beckshybrids.com">
          <mat-card
            class="home__dashboard__link mat-elevation-zcustom clickable"
            matTooltip="Please direct support requests via Helpdesk Tickets by sending an email with a descriptive
        subject title"
            matTooltipPosition="below"
          >
            <i class="fas fa-headset"></i>
            <div class="home__small-card-title">Support</div>
          </mat-card>
        </a>
      </section>
    </div>
  </div>
</div>
