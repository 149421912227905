import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'ps-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  error: string;

  constructor(private _authService: AuthService) {}

  ngOnInit() {
    /**
     * Logs out the application without redirect
     */
    this._authService.logoutRedirect();
  }

  handleClickHere() {
    this._authService.logoutRedirect();
  }
}
