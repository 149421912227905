import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IUser,
  IUserModule,
  IUserPermissions,
  IUserPreferences
} from 'src/app/shared/models/data-model/user.interface';
import { EAppModule } from '../../shared/models/data-model/app-module.interface';
import { ApiService } from './api.service';

@Injectable()
export class UserService {
  constructor(private _apiService: ApiService) {}

  public static hasAppModuleAccess(
    permissions: IUserPermissions,
    appModule: EAppModule | null
  ): boolean {
    if (!permissions || !appModule) {
      return false;
    }

    return appModule in permissions;
  }

  public static hasRoleGroup(
    permissions: IUserPermissions,
    appModule: EAppModule | null,
    code: string
  ): boolean {
    if (!permissions || !appModule) {
      return false;
    }

    const appPermissions = permissions[appModule];
    if (!appPermissions) {
      return false;
    }

    return code in appPermissions.featureGroups;
  }

  public static hasRole(
    permissions: IUserPermissions,
    appModule: EAppModule | null,
    role: string
  ): boolean {
    if (!permissions || !appModule) {
      return false;
    }

    const appPermissions = permissions[appModule];
    if (!appPermissions) {
      return false;
    }

    return role in appPermissions.features;
  }

  public static hasFeatureFromList(
    permissions: IUserPermissions,
    appModule: EAppModule | null,
    features: string[]
  ): boolean {
    const featureCodes: string[] = this.getUserFeatureCodes(permissions, appModule);
    return Boolean(_.intersection(featureCodes, features).length > 0);
  }

  public static getUserFeatureCodes(
    permissions: IUserPermissions,
    appModule: EAppModule | null
  ): string[] {
    if (!permissions || !appModule) {
      return [];
    }

    const appPermissions = permissions[appModule];
    if (!appPermissions) {
      return [];
    }

    const featureCodes: string[] = Object.keys(appPermissions.features);
    return featureCodes;
  }

  public static getUserFeatureGroups(
    permissions: IUserPermissions,
    appModule: EAppModule | null
  ): string[] {
    if (!permissions || !appModule) {
      return [];
    }

    const appPermissions = permissions[appModule];
    if (!appPermissions) {
      return [];
    }

    const featureGroups: string[] = Object.keys(appPermissions.featureGroups);
    return featureGroups;
  }

  public fetchUser(): Observable<IUser> {
    return this._apiService.get(`/auth/users`).pipe(map((body) => body.content));
  }

  public fetchUserPermissions(): Observable<IUserModule[]> {
    return this._apiService.get(`/auth/permissions`).pipe(map((body) => body.content));
  }

  public fetchUserRegions(): Observable<{ regionIDs: number[] }> {
    return this._apiService.get(`/auth/regions`).pipe(map((body) => body.content));
  }

  public fetchUserPreferences(): Observable<IUserPreferences> {
    return this._apiService.get(`/shared/preferences/general`).pipe(map((body) => body.content));
  }

  public saveUserPreferences(preferences: IUserPreferences): Observable<void> {
    return this._apiService.put(`/shared/preferences/general`, preferences);
  }
}
