import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../auth.service';

@Component({
  selector: 'ps-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent implements OnInit {
  error: string;

  constructor(private _authService: AuthService) {}

  ngOnInit() {
    /**
     * Handles the response from Okta and parses tokens.
     */
    this._authService.handleRedirect().catch((err) => {
      this.error = err.toString();
    });
  }

  handleClickHere() {
    this._authService.loginRedirect();
  }
}
