import { Injectable } from '@angular/core';
import { Store, Action, MemoizedSelector } from '@ngrx/store';
import { Observable, of, race, throwError } from 'rxjs';
import {
  catchError,
  filter,
  switchMap,
  take,
  tap,
  distinctUntilChanged,
  map
} from 'rxjs/operators';

@Injectable()
export class LoadActionGuardFactoryService {
  constructor(private _store: Store) {}

  canAdvance({
    loadAction,
    isLoadedSelector,
    hasErrorSelector,
    isBlocking = true
  }: {
    loadAction: Action;
    isLoadedSelector: MemoizedSelector<object, boolean>;
    hasErrorSelector: MemoizedSelector<object, boolean>;
    isBlocking?: boolean;
  }): Observable<boolean> {
    return this._checkStore(loadAction, isLoadedSelector, hasErrorSelector, isBlocking).pipe(
      // returns observable of true if things have gone correctly
      switchMap(() => of(true)),
      // else returns observable of false if things have gone awry
      catchError(() => of(false))
    );
  }

  private _checkStore(
    loadAction: Action,
    isLoadedSelector: MemoizedSelector<object, boolean>,
    hasErrorSelector: MemoizedSelector<object, boolean>,
    isBlocking: boolean
  ): Observable<boolean> {
    return race(
      this._waitForCollectionToLoad(loadAction, isLoadedSelector, isBlocking),
      this._waitForErrorToHappen(hasErrorSelector)
    );
  }

  _waitForCollectionToLoad(
    action: Action,
    selector: MemoizedSelector<object, boolean>,
    isBlocking: boolean
  ): Observable<boolean> {
    return this._store.select(selector).pipe(
      tap((loaded: boolean) => {
        if (!loaded) {
          this._store.dispatch(action);
        }
      }),
      map((loaded: boolean) => (isBlocking ? loaded : true)), // do not wait on non-blocking loads
      filter((loaded: boolean) => loaded),
      // after loaded has become true...
      take(1)
    );
  }

  _waitForErrorToHappen(selector: MemoizedSelector<object, boolean>): Observable<boolean> {
    return this._store.select(selector).pipe(
      distinctUntilChanged(),
      filter((erred: boolean) => erred),
      // after erred has become true return false to avoid page to keep going
      switchMap(() => throwError('Fail to load data'))
    );
  }
}
