import { ForecastState } from './../reducers/index';
import * as fromProductionForecastSanitizer from './production-forecast.sanitizer';
import * as fromVarietyForecastSanitizer from './variety-forecast.sanitizer';
import * as fromSalesForecastSanitizer from './sales-forecast.sanitizer';

export function sanitize(state: ForecastState): {} | undefined {
  if (state) {
    return {
      ...state,
      productionForecast: fromProductionForecastSanitizer.sanitize(state.productionForecast),
      varietyForecast: fromVarietyForecastSanitizer.sanitize(state.varietyForecast),
      salesForecast: fromSalesForecastSanitizer.sanitize(state.salesForecast)
    };
  } else {
    return;
  }
}
