import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IProductionYear } from 'src/app/shared/models/data-model/production-year.interface';
import { IProductionYearEnabledControls } from 'src/app/shared/models/view-model/production-year-vm.interface';
import * as fromGlobal from '../reducers';
import * as fromProductionYears from '../reducers/production-year.reducer';

export const getProductionYearsState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.productionYears
);

export const getProductionYearEntities = createSelector(
  getProductionYearsState,
  fromProductionYears.getProductionYearEntities
);
export const getProductionYearSelectedYear = createSelector(
  getProductionYearsState,
  fromProductionYears.getProductionYearSelectedYear
);
export const getProductionYearDefaultYear = createSelector(
  getProductionYearsState,
  fromProductionYears.getProductionYearDefaultYear
);
export const getProductionYearsLoading = createSelector(
  getProductionYearsState,
  fromProductionYears.getProductionYearsLoading
);
export const getProductionYearsLoaded = createSelector(
  getProductionYearsState,
  fromProductionYears.getProductionYearsLoaded
);
export const getProductionYearsErred = createSelector(
  getProductionYearsState,
  fromProductionYears.getProductionYearsErred
);
export const getProductionYearLabel = createSelector(
  getProductionYearsState,
  fromProductionYears.getProductionYearLabel
);
export const getProductionYearIsSelectorVisible = createSelector(
  getProductionYearsState,
  fromProductionYears.getProductionYearIsSelectorVisible
);
export const getProductionYearIsLabelVisible = createSelector(
  getProductionYearsState,
  fromProductionYears.getProductionYearIsLabelVisible
);

/** Derived Selectors */
export const getProductionYears: MemoizedSelector<object, IProductionYear[]> = createSelector(
  getProductionYearEntities,
  (entities) =>
    Object.keys(entities)
      .map((ID) => entities[ID])
      .sort((a: IProductionYear, b: IProductionYear) => a.YearNumber - b.YearNumber)
);

export const getProductionYearEnabledControls: MemoizedSelector<
  object,
  IProductionYearEnabledControls
> = createSelector(getProductionYears, getProductionYearSelectedYear, (years, selected) => {
  if (!years.length || !selected) {
    return {
      previous: true,
      next: true
    };
  }

  const first = years[0];
  const last = years[years.length - 1];

  return {
    previous: selected.YearNumber > first.YearNumber,
    next: selected.YearNumber < last.YearNumber
  };
});
