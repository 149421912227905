import { AppDefaultsGuard } from 'src/app/core/guards/app-defaults.guard';
import { UserGuard } from 'src/app/core/guards/user.guard';
import { UsersGuard } from 'src/app/core/guards/users.guard';
import { AcreageOverviewGuard } from './acreage-overview.guard';
import { ConfirmDeactivateGuard } from './confirm-deactivate.guard';
import { GrowersLoadedGuard } from './growers-loaded.guard';
import { NotificationsSocketConnectionGuard } from './notifications-socket-connection.guard';
import { NotificationsGuard } from './notifications.guard';
import { PackageTypesGuard } from './package-types.guard';
import { ProductCropInfoGuard } from './product-crop-info.guard';
import { ProductionYearsGuard } from './production-years.guard';
import { RegionsGuard } from './regions.guard';
import { SeedTreatmentsGuard } from './seed-treatments.guard';
import { SitesGuard } from './sites.guard';
import { UserPreferencesGuard } from './user-preferences.guard';
import { UserRegionsGuard } from 'src/app/core/auth/guards/user-regions.guard';

export const guards = [
  ConfirmDeactivateGuard,
  NotificationsSocketConnectionGuard,
  UserPreferencesGuard,
  ProductCropInfoGuard,
  NotificationsGuard,
  ProductionYearsGuard,
  AcreageOverviewGuard,
  PackageTypesGuard,
  SeedTreatmentsGuard,
  RegionsGuard,
  UserRegionsGuard,
  SitesGuard,
  UserGuard,
  UsersGuard,
  GrowersLoadedGuard,
  AppDefaultsGuard
];
