import { LotsActions, LotsActionTypes } from '../actions';

export interface LotsState {
  model: {};
  viewModel: {
    existence: {
      loading: boolean;
      ID: number | null;
    };
  };
}

export const initialState: LotsState = {
  model: {},
  viewModel: {
    existence: {
      loading: false,
      ID: null
    }
  }
};

export function reducer(state = initialState, action: LotsActions): LotsState {
  switch (action.type) {
    case LotsActionTypes.CheckLotExistsAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          existence: {
            ...state.viewModel.existence,
            loading: true,
            ID: null
          }
        }
      };
    }
    case LotsActionTypes.CheckLotExistsFailAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          existence: {
            ...state.viewModel.existence,
            loading: false,
            ID: null
          }
        }
      };
    }
    case LotsActionTypes.CheckLotExistsSuccessAction: {
      const ID = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          existence: {
            ...state.viewModel.existence,
            loading: false,
            ID
          }
        }
      };
    }

    default:
      return state;
  }
}

export const getLotsLotCheckLotID = (state: LotsState) => state.viewModel.existence.ID;
