import { UntypedFormGroup } from '@angular/forms';
import { Action } from '@ngrx/store';
import {
  IUser,
  IUserAppPreferences,
  IUserPermissions,
  IUserPreferences
} from 'src/app/shared/models/data-model/user.interface';

export enum UserActionTypes {
  InitializeUserAction = '[Global: User] Initialize User',
  InitializeUserFailAction = '[Global: User] Initialize User Fail',
  InitializeUserSuccessAction = '[Global: User] Initialize User Success',

  LoadUserPermissionsAction = '[Global: User] Load User Permissions',
  LoadUserPermissionsFailAction = '[Global: User] Load User Permissions Fail',
  LoadUserPermissionsSuccessAction = '[Global: User] Load User Permissions Success',

  LoadUserRegionsAction = '[Global: User] Load User Regions',
  LoadUserRegionsFailAction = '[Global: User] Load User Regions Fail',
  LoadUserRegionsSuccessAction = '[Global: User] Load User Regions Success',

  LoadUserPreferencesAction = '[Global: User] Load User Preferences',
  LoadUserPreferencesFailAction = '[Global: User] Load User Preferences Fail',
  LoadUserPreferencesSuccessAction = '[Global: User] Load User Preferences Success',

  SaveUserPreferencesAction = '[Global: User] Save User Preferences',
  SaveUserPreferencesFailAction = '[Global: User] Save User Preferences Fail',
  SaveUserPreferencesSuccessAction = '[Global: User] Save User Preferences Success',

  UserNotAuthorizedAction = '[Global: User] User Not Authorized',

  ResetUserStateAction = '[Global: User] Reset User State',

  SetUserDeSyncAction = '[Global: User] Set User DeSync',
  ResetUserDeSyncAction = '[Global: User] Reset User DeSync',

  InitializePreferencesFormAction = '[Global: User] Initialize Preferences Form',
  InitializePreferencesFormCompleteAction = '[Global: User] Initialize Preferences Form Complete',
  UpdatePreferencesFormAction = '[Global: User] Update Preferences Form',
  PreferencesFormChangedAction = '[Global: User] Preferences Form Changed',
  ResetPreferencesFormAction = '[Global: User] Reset Preferences Form',
  ResetPreferencesFormPropertyAction = '[Global: User] Reset Preferences Form Property'
}

export class InitializeUser implements Action {
  readonly type = UserActionTypes.InitializeUserAction;
}
export class InitializeUserFail implements Action {
  readonly type = UserActionTypes.InitializeUserFailAction;
  constructor(public payload: any) {}
}
export class InitializeUserSuccess implements Action {
  readonly type = UserActionTypes.InitializeUserSuccessAction;
  constructor(public payload: IUser) {}
}

export class LoadUserPermissions implements Action {
  readonly type = UserActionTypes.LoadUserPermissionsAction;
}
export class LoadUserPermissionsFail implements Action {
  readonly type = UserActionTypes.LoadUserPermissionsFailAction;
  constructor(public payload: any) {}
}
export class LoadUserPermissionsSuccess implements Action {
  readonly type = UserActionTypes.LoadUserPermissionsSuccessAction;
  constructor(public payload: IUserPermissions) {}
}

export class LoadUserRegions implements Action {
  readonly type = UserActionTypes.LoadUserRegionsAction;
}
export class LoadUserRegionsFail implements Action {
  readonly type = UserActionTypes.LoadUserRegionsFailAction;
  constructor(public payload: any) {}
}
export class LoadUserRegionsSuccess implements Action {
  readonly type = UserActionTypes.LoadUserRegionsSuccessAction;
  constructor(public payload: number[]) {}
}

export class LoadUserPreferences implements Action {
  readonly type = UserActionTypes.LoadUserPreferencesAction;
}
export class LoadUserPreferencesFail implements Action {
  readonly type = UserActionTypes.LoadUserPreferencesFailAction;
  constructor(public payload: any) {}
}
export class LoadUserPreferencesSuccess implements Action {
  readonly type = UserActionTypes.LoadUserPreferencesSuccessAction;
  constructor(public payload: IUserPreferences) {}
}

export class SaveUserPreferences implements Action {
  readonly type = UserActionTypes.SaveUserPreferencesAction;
  constructor(public payload: IUserAppPreferences) {}
}
export class SaveUserPreferencesFail implements Action {
  readonly type = UserActionTypes.SaveUserPreferencesFailAction;
  constructor(public payload: any) {}
}
export class SaveUserPreferencesSuccess implements Action {
  readonly type = UserActionTypes.SaveUserPreferencesSuccessAction;
  constructor(public payload: IUserPreferences) {}
}

export class UserNotAuthorized implements Action {
  readonly type = UserActionTypes.UserNotAuthorizedAction;
}

export class ResetUserState implements Action {
  readonly type = UserActionTypes.ResetUserStateAction;
}

export class SetUserDeSync implements Action {
  readonly type = UserActionTypes.SetUserDeSyncAction;
  constructor(public payload: IUser) {}
}
export class ResetUserDeSync implements Action {
  readonly type = UserActionTypes.ResetUserDeSyncAction;
}

export class InitializePreferencesForm implements Action {
  readonly type = UserActionTypes.InitializePreferencesFormAction;
}
export class InitializePreferencesFormComplete implements Action {
  readonly type = UserActionTypes.InitializePreferencesFormCompleteAction;
  constructor(public payload: UntypedFormGroup) {}
}
export class UpdatePreferencesForm implements Action {
  readonly type = UserActionTypes.UpdatePreferencesFormAction;
  constructor(public payload: UntypedFormGroup) {}
}
export class PreferencesFormChanged implements Action {
  readonly type = UserActionTypes.PreferencesFormChangedAction;
  constructor(public payload: UntypedFormGroup) {}
}
export class ResetPreferencesForm implements Action {
  readonly type = UserActionTypes.ResetPreferencesFormAction;
  constructor(public payload: UntypedFormGroup) {}
}
export class ResetPreferencesFormProperty implements Action {
  readonly type = UserActionTypes.ResetPreferencesFormPropertyAction;
  constructor(public payload: string) {}
}

export type UserActions =
  | InitializeUser
  | InitializeUserFail
  | InitializeUserSuccess
  | LoadUserPermissions
  | LoadUserPermissionsFail
  | LoadUserPermissionsSuccess
  | LoadUserPreferences
  | LoadUserPreferencesFail
  | LoadUserPreferencesSuccess
  | LoadUserRegions
  | LoadUserRegionsFail
  | LoadUserRegionsSuccess
  | SaveUserPreferences
  | SaveUserPreferencesFail
  | SaveUserPreferencesSuccess
  | UserNotAuthorized
  | ResetUserState
  | SetUserDeSync
  | ResetUserDeSync
  | InitializePreferencesForm
  | InitializePreferencesFormComplete
  | UpdatePreferencesForm
  | PreferencesFormChanged
  | ResetPreferencesForm
  | ResetPreferencesFormProperty;
