import { Action } from '@ngrx/store';
import { IRegion } from 'src/app/shared/models/data-model/region.interface';

export enum RegionActionTypes {
  LoadRegionsAction = '[Regions: List] Load Regions',
  LoadRegionsFailAction = '[Regions: List] Load Regions Fail',
  LoadRegionsSuccessAction = '[Regions: List] Load Regions Success'
}

export class LoadRegions implements Action {
  readonly type = RegionActionTypes.LoadRegionsAction;
}

export class LoadRegionsFail implements Action {
  readonly type = RegionActionTypes.LoadRegionsFailAction;
  constructor(public payload: any) {}
}

export class LoadRegionsSuccess implements Action {
  readonly type = RegionActionTypes.LoadRegionsSuccessAction;
  constructor(public payload: IRegion[]) {}
}

export type RegionActions = LoadRegions | LoadRegionsFail | LoadRegionsSuccess;
