import { createSelector } from '@ngrx/store';
import * as fromGlobal from '../reducers';
import * as fromReducers from '../reducers/sites.reducer';

export const getSitesState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.sites
);

const getModel = createSelector(getSitesState, (state) => state.model);

const getSitesAdapter = createSelector(getModel, (model) => model.sites);

export const {
  selectEntities: getSiteEntities,
  selectAll: getAllSites
} = fromReducers.siteManagementSitesAdapter.getSelectors(getSitesAdapter);

export const getSitesLoaded = createSelector(getSitesState, (state) => state.viewModel.sitesLoaded);
export const getSitesLoading = createSelector(
  getSitesState,
  (state) => state.viewModel.sitesLoading
);
export const getSitesErred = createSelector(getSitesState, (state) => state.viewModel.sitesErred);
