import { Action } from '@ngrx/store';
import { IBreakpointsObject } from 'src/app/shared/models/data-model/layout.interface';

export enum SystemInfoActionTypes {
  SetBreakpointEntitiesAction = '[Global: System Info] Set Breakpoint Entities',

  ShowLoadingIndicatorAction = '[Global: System Info] Show Loading Indicator',
  HideLoadingIndicatorAction = '[Global: System Info] Hide Loading Indicator',

  ReloadCurrentAppModuleAction = '[Global: System Info] Reload Current App Module',
  RouteToHomeAppPageAction = '[Global: System Info] Route to Home App Page',
  RouteToHomePageAction = '[Global: System Info] Route to Home Page',
  RouteToLogoutPageAction = '[Global: System Info] Route to Logout Page'
}

export class SetBreakpointEntities implements Action {
  readonly type = SystemInfoActionTypes.SetBreakpointEntitiesAction;
  constructor(public payload: IBreakpointsObject) {}
}

export class ShowLoadingIndicator implements Action {
  readonly type = SystemInfoActionTypes.ShowLoadingIndicatorAction;
}
export class HideLoadingIndicator implements Action {
  readonly type = SystemInfoActionTypes.HideLoadingIndicatorAction;
}

export class ReloadCurrentAppModule implements Action {
  readonly type = SystemInfoActionTypes.ReloadCurrentAppModuleAction;
}

export class RouteToHomeAppPage implements Action {
  readonly type = SystemInfoActionTypes.RouteToHomeAppPageAction;
}
export class RouteToHomePage implements Action {
  readonly type = SystemInfoActionTypes.RouteToHomePageAction;
  constructor(public errorMsg: string) {}
}
export class RouteToLogoutPage implements Action {
  readonly type = SystemInfoActionTypes.RouteToLogoutPageAction;
}

export type SystemInfoActions =
  | SetBreakpointEntities
  | ShowLoadingIndicator
  | HideLoadingIndicator
  | ReloadCurrentAppModule
  | RouteToHomeAppPage
  | RouteToHomePage
  | RouteToLogoutPage;
