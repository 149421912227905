import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

export interface IBecksSnackBarOptions {
  message: string;
  type: 'error' | 'warn' | 'success';
  button?: string;
}
@Component({
  selector: 'capps-becks-snackbar',
  template: ` <div class="becks-snackbar mat-simple-snackbar">
    <span>{{ data.message }}</span>
    <div *ngIf="data.button" class="mat-simple-snackbar-action">
      <button
        mat-button
        (click)="buttonClicked()"
        class="mat-focus-indicator mat-button mat-button-base"
      >
        <span class="mat-button-wrapper">{{ data.button }}</span>
        <span matripple class="mat-button-ripple mat-ripple"></span>
        <span class="mat-button-focus-overlay"></span>
      </button>
    </div>
    <mat-icon class="becks-snackbar__icon" *ngIf="data.type === 'warn'">warning</mat-icon>
  </div>`,
  styleUrls: ['./becks-snackbar.component.scss']
})
export class BecksSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IBecksSnackBarOptions,
    public snackBarRef: MatSnackBarRef<BecksSnackbarComponent>
  ) {}
  buttonClicked() {
    this.snackBarRef.dismissWithAction();
  }
}
