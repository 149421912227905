import * as _ from 'lodash';
import { IProductionYear } from 'src/app/shared/models/data-model/production-year.interface';
import { ProductionYearActions, ProductionYearActionTypes } from '../actions';

export interface ProductionYearState {
  model: {
    prodYearEntities: { [key: number]: IProductionYear };
  };
  viewModel: {
    selectedProdYear: IProductionYear | null;
    defaultProdYear: IProductionYear | null;
    prodYearsLoading: boolean;
    prodYearsLoaded: boolean;
    prodYearsErred: boolean;
    prodYearLabel: string;
    isProdYearSelectorVisible: boolean;
    isProdYearLabelVisible: boolean;
  };
}

export const initialState: ProductionYearState = {
  model: {
    prodYearEntities: {}
  },
  viewModel: {
    selectedProdYear: null,
    defaultProdYear: null,
    prodYearsLoading: false,
    prodYearsLoaded: false,
    prodYearsErred: false,
    prodYearLabel: '',
    isProdYearSelectorVisible: true,
    isProdYearLabelVisible: false
  }
};

export function reducer(state = initialState, action: ProductionYearActions): ProductionYearState {
  switch (action.type) {
    case ProductionYearActionTypes.LoadProductionYears: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          prodYearsLoading: true,
          prodYearsLoaded: false,
          prodYearsErred: false
        }
      };
    }

    case ProductionYearActionTypes.LoadProductionYearsFail: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          prodYearsLoading: false,
          prodYearsLoaded: false,
          prodYearsErred: true
        }
      };
    }
    case ProductionYearActionTypes.LoadProductionYearsSuccess: {
      const prodYears: IProductionYear[] = action.payload;
      const prodYearEntities = _.keyBy(prodYears, 'ID');
      const currentProdYear = _.find(prodYears, 'isCurrent');

      return {
        ...state,
        model: {
          ...state.model,
          prodYearEntities
        },
        viewModel: {
          ...state.viewModel,
          selectedProdYear: currentProdYear || null,
          defaultProdYear: currentProdYear || null,
          prodYearsLoading: false,
          prodYearsLoaded: true,
          prodYearsErred: false
        }
      };
    }

    case ProductionYearActionTypes.UpdateSelectedProductionYear: {
      const selectedProdYear = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          selectedProdYear
        }
      };
    }

    case ProductionYearActionTypes.UpdateProductionYearLabel: {
      const label = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          prodYearLabel: label
        }
      };
    }

    case ProductionYearActionTypes.ShowProductionYearSelector: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isProdYearSelectorVisible: true
        }
      };
    }
    case ProductionYearActionTypes.HideProductionYearSelector: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isProdYearSelectorVisible: false
        }
      };
    }

    case ProductionYearActionTypes.ShowProductionYearLabel: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isProdYearLabelVisible: true
        }
      };
    }
    case ProductionYearActionTypes.HideProductionYearLabel: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isProdYearLabelVisible: false
        }
      };
    }

    default:
      return state;
  }
}

export const getProductionYearEntities = (state: ProductionYearState) =>
  state.model.prodYearEntities;
export const getProductionYearSelectedYear = (state: ProductionYearState) =>
  state.viewModel.selectedProdYear;
export const getProductionYearDefaultYear = (state: ProductionYearState) =>
  state.viewModel.defaultProdYear;
export const getProductionYearsLoading = (state: ProductionYearState) =>
  state.viewModel.prodYearsLoading;
export const getProductionYearsLoaded = (state: ProductionYearState) =>
  state.viewModel.prodYearsLoaded;
export const getProductionYearsErred = (state: ProductionYearState) =>
  state.viewModel.prodYearsErred;
export const getProductionYearLabel = (state: ProductionYearState) => state.viewModel.prodYearLabel;
export const getProductionYearIsSelectorVisible = (state: ProductionYearState) =>
  state.viewModel.isProdYearSelectorVisible;
export const getProductionYearIsLabelVisible = (state: ProductionYearState) =>
  state.viewModel.isProdYearLabelVisible;
