export enum EAppModule {
  seedtracker = 'ST',
  capps = 'CAPPS',
  documentation = 'DOC',
  usermanagement = 'UM',
  labtracker = 'LAB'
}

export enum EAppModuleDesc {
  seedtracker = 'Seed Tracker',
  capps = 'CAPPS',
  documentation = 'Documentation',
  usermanagement = 'User Management',
  labtracker = 'Lab Tracker'
}
