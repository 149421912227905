import { Injectable } from '@angular/core';
import { RollbarErrorHandler } from '@becksdevteam/rollbar-angular';

@Injectable()
export class GlobalErrorHandler extends RollbarErrorHandler {
  handleError(err: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(err.message)) {
      // force the app to reload and fetch the new chunks
      window.location.reload();
      return;
    }

    // log error with Rollbar and to console
    super.handleError(err);
  }
}
