import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import * as fromActions from 'src/app/root-store/global-store/store/actions';
import * as fromSelectors from 'src/app/root-store/global-store/store/selectors';

import { LoadActionGuardFactoryService } from 'src/app/core/services/load-action-guard-factory.service';

@Injectable()
export class AppDefaultsGuard implements CanActivate {
  constructor(private _guardFactoryService: LoadActionGuardFactoryService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._guardFactoryService.canAdvance({
      loadAction: fromActions.loadAppDefaults(),
      isLoadedSelector: fromSelectors.getAppDefaultsLoaded,
      hasErrorSelector: fromSelectors.getAppDefaultsErred
    });
  }
}
