import { IBreakpointsObject } from 'src/app/shared/models/data-model/layout.interface';
import { SystemInfoActions, SystemInfoActionTypes } from '../actions';

export interface SystemInfoState {
  model: {};
  viewModel: {
    breakpointEntities: IBreakpointsObject | {};
    displayLoadingIndicator: boolean;
  };
}

export const initialState: SystemInfoState = {
  model: {},
  viewModel: {
    breakpointEntities: {},
    displayLoadingIndicator: false
  }
};

export function reducer(state = initialState, action: SystemInfoActions): SystemInfoState {
  switch (action.type) {
    case SystemInfoActionTypes.SetBreakpointEntitiesAction: {
      const breakpointEntities = action.payload;
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          breakpointEntities
        }
      };
    }

    case SystemInfoActionTypes.ShowLoadingIndicatorAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          displayLoadingIndicator: true
        }
      };
    }
    case SystemInfoActionTypes.HideLoadingIndicatorAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          displayLoadingIndicator: false
        }
      };
    }

    default:
      return state;
  }
}

export const getSystemInfoBreakpointEntities = (state: SystemInfoState) =>
  state.viewModel.breakpointEntities;
export const getDisplayLoadingIndicator = (state: SystemInfoState) =>
  state.viewModel.displayLoadingIndicator;
