import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from './services/dialog.service';

@NgModule({
  declarations: [ConfirmationDialogComponent],
  exports: [ConfirmationDialogComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule]
})
export class ConfirmationDialogModule {
  static forRoot(): ModuleWithProviders<ConfirmationDialogModule> {
    return {
      ngModule: ConfirmationDialogModule,
      providers: [DialogService]
    };
  }
}
