import { Observable, ReplaySubject } from 'rxjs';

type IAppEventHandler<T> = (data?: T) => void;
interface IAppEvent<T> {
  subscribe(handler: IAppEventHandler<T>): () => void;
  emit(data?: T): void;
}

export class AppEvent<T> implements IAppEvent<T> {
  private handlers: IAppEventHandler<T>[] = [];

  private value: ReplaySubject<T> = new ReplaySubject(1);
  private value$: Observable<T> = this.value.asObservable();

  public subscribe(handler: IAppEventHandler<T>) {
    this.handlers.push(handler);
    let isSubscribed = true;
    return () => {
      if (!isSubscribed) {
        return;
      }
      isSubscribed = false;

      this.handlers = this.handlers.filter((h) => h !== handler);
    };
  }

  public emit(data?: T) {
    this.value.next(data);

    if (this.handlers) {
      this.handlers.slice(0).forEach((h) => h(data));
    }
  }

  public asObservable() {
    return this.value$;
  }
}
