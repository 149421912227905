import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { IRegion } from 'src/app/shared/models/data-model/region.interface';
import { RegionActions, RegionActionTypes } from '../actions';

interface SiteManagementRegionsEntityState extends EntityState<IRegion> {}
export const siteManagementRegionsAdapter = createEntityAdapter<IRegion>({
  selectId: (region) => region.ID
});

export interface RegionsState {
  model: {
    regions: SiteManagementRegionsEntityState;
  };
  viewModel: {
    regionsLoaded: boolean;
    regionsLoading: boolean;
    regionsErred: boolean;
  };
}

export const initialState: RegionsState = {
  model: {
    regions: siteManagementRegionsAdapter.getInitialState()
  },
  viewModel: {
    regionsLoaded: false,
    regionsLoading: false,
    regionsErred: false
  }
};

export function reducer(state = initialState, action: RegionActions): RegionsState {
  switch (action.type) {
    case RegionActionTypes.LoadRegionsAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          regionsLoading: true,
          regionsLoaded: false,
          regionsErred: false
        }
      };
    }
    case RegionActionTypes.LoadRegionsFailAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          regionsLoaded: false,
          regionsLoading: false,
          regionsErred: true
        }
      };
    }
    case RegionActionTypes.LoadRegionsSuccessAction: {
      return {
        ...state,
        model: {
          ...state.model,
          regions: siteManagementRegionsAdapter.setAll(action.payload, state.model.regions)
        },
        viewModel: {
          ...state.viewModel,
          regionsLoaded: true,
          regionsLoading: false,
          regionsErred: false
        }
      };
    }

    default:
      return state;
  }
}
