import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { IPSUser } from '../../shared/models/data-model/user.interface';

@Injectable()
export class UsersService {
  constructor(private _apiService: ApiService) {}

  public fetchUsers(): Observable<IPSUser[]> {
    return this._apiService.get('/shared/security/users').pipe(map((payload) => payload.users));
  }
}
