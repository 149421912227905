import { createSelector } from '@ngrx/store';
import { getRouterReducerRouteData } from 'src/app/root-store/store';

import * as fromGlobal from '../reducers';
import * as fromLayout from '../reducers/layout.reducer';

export const getLayoutState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.layout
);

export const getLayoutUserLayout = createSelector(getLayoutState, fromLayout.getLayoutUserLayout);
export const getLayoutTitleBar = createSelector(getLayoutState, fromLayout.getLayoutTitleBar);
export const getLayoutFeatures = createSelector(getLayoutState, fromLayout.getLayoutFeatures);
export const getLayoutUserLayoutLoaded = createSelector(
  getLayoutState,
  fromLayout.getLayoutUserLayoutLoaded
);
export const getLayoutUserLayoutLoading = createSelector(
  getLayoutState,
  fromLayout.getLayoutUserLayoutLoading
);
export const getLayoutIsSidebarOpen = createSelector(
  getLayoutState,
  fromLayout.getLayoutIsSidebarOpen
);
export const getLayoutTitleBarQuickSearchValue = createSelector(
  getLayoutState,
  fromLayout.getLayoutTitleBarQuickSearchValue
);

/** Derived Selectors */
export const getLayoutShowGlobalQuickSearch = createSelector(
  getRouterReducerRouteData,
  ({ showGlobalTitleBarSearch }) => showGlobalTitleBarSearch
);
