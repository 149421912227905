import { Action } from '@ngrx/store';
import { IProductionYear } from 'src/app/shared/models/data-model/production-year.interface';

export enum ProductionYearActionTypes {
  LoadProductionYears = '[Global: Production Year] Load Production Years',
  LoadProductionYearsFail = '[Global: Production Year] Load Production Years Fail',
  LoadProductionYearsSuccess = '[Global: Production Year] Load Production Years Success',

  UpdateSelectedProductionYear = '[Global: Production Year] Update Selected Production Year',
  GoToPreviousProductionYear = '[Global: Production Year] Go To Previous Production Year',
  GoToNextProductionYear = '[Global: Production Year] Go To Next Production Year',

  UpdateProductionYearLabel = '[Global: Production Year] Update Production Year Label',

  ShowProductionYearSelector = '[Global: Production Year] Show Production Year Selector',
  HideProductionYearSelector = '[Global: Production Year] Hide Production Year Selector',

  ShowProductionYearLabel = '[Global: Production Year] Show Production Year Label',
  HideProductionYearLabel = '[Global: Production Year] Hide Production Year Label'
}

export class LoadProductionYearsAction implements Action {
  readonly type = ProductionYearActionTypes.LoadProductionYears;
}
export class LoadProductionYearsFailAction implements Action {
  readonly type = ProductionYearActionTypes.LoadProductionYearsFail;
  constructor(public payload: any) {}
}
export class LoadProductionYearsSuccessAction implements Action {
  readonly type = ProductionYearActionTypes.LoadProductionYearsSuccess;
  constructor(public payload: IProductionYear[]) {}
}

export class UpdateSelectedProductionYearAction implements Action {
  readonly type = ProductionYearActionTypes.UpdateSelectedProductionYear;
  constructor(public payload: IProductionYear) {}
}

export class GoToPreviousProductionYearAction implements Action {
  readonly type = ProductionYearActionTypes.GoToPreviousProductionYear;
}
export class GoToNextProductionYearAction implements Action {
  readonly type = ProductionYearActionTypes.GoToNextProductionYear;
}

export class UpdateProductionYearLabelAction implements Action {
  readonly type = ProductionYearActionTypes.UpdateProductionYearLabel;
  constructor(public payload: string) {}
}

export class ShowProductionYearSelectorAction implements Action {
  readonly type = ProductionYearActionTypes.ShowProductionYearSelector;
}
export class HideProductionYearSelectorAction implements Action {
  readonly type = ProductionYearActionTypes.HideProductionYearSelector;
}

export class ShowProductionYearLabelAction implements Action {
  readonly type = ProductionYearActionTypes.ShowProductionYearLabel;
}
export class HideProductionYearLabelAction implements Action {
  readonly type = ProductionYearActionTypes.HideProductionYearLabel;
}

export type ProductionYearActions =
  | LoadProductionYearsAction
  | LoadProductionYearsSuccessAction
  | LoadProductionYearsFailAction
  | UpdateSelectedProductionYearAction
  | GoToPreviousProductionYearAction
  | GoToNextProductionYearAction
  | UpdateProductionYearLabelAction
  | ShowProductionYearSelectorAction
  | HideProductionYearSelectorAction
  | ShowProductionYearLabelAction
  | HideProductionYearLabelAction;
