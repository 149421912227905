import { createSelector } from '@ngrx/store';
import { getProductionYearSelectedYear } from 'src/app/root-store/global-store/store/selectors/production-year.selector';
import { IAppDefault } from 'src/app/shared/models/data-model/app-defaults.interface';
import { IProductionYear } from 'src/app/shared/models/data-model/production-year.interface';
import * as fromGlobal from '../reducers';

export const getAppDefaultsState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.appDefaults
);

export const getAppDefaultsLoaded = createSelector(
  getAppDefaultsState,
  (state) => state.viewModel.appDefaultsLoaded
);

export const getAppDefaultsLoading = createSelector(
  getAppDefaultsState,
  (state) => state.viewModel.appDefaultsLoading
);

export const getAppDefaultsErred = createSelector(
  getAppDefaultsState,
  (state) => state.viewModel.appDefaultsErred
);

export const getAppDefaultsEntities = createSelector(
  getAppDefaultsState,
  (state) => state.model.appDefaults.entities
);

// derived selectors
export const getAppDefaults = createSelector(getAppDefaultsState, (state) => {
  const { ids, entities } = state.model.appDefaults;
  return (ids as number[]).map((id) => entities[id]);
});

// gets an stdEntryCode map that contains values for each code for the currently chosen production year
export const getStdEntryCodeMapForActiveProductionYear = createSelector(
  getAppDefaults,
  getProductionYearSelectedYear,
  (appDefaults: IAppDefault[], productionYear: IProductionYear) => {
    const stdEntryCodeMap: { [stdEntryCode: string]: string | number } = {};

    appDefaults.forEach((appDefault) => {
      if (appDefault.prodYearID !== productionYear?.ID) {
        return;
      }

      stdEntryCodeMap[appDefault.stdEntryCode] = appDefault.stdEntryValue;
    });

    return stdEntryCodeMap;
  }
);
