import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Data,
  Route,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private _authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canProceed(this._authService, route.data, state.url);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(next, state);
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.canProceed(this._authService, route.data);
  }

  private canProceed(oktaService: AuthService, data?: Data, url?: string): Observable<boolean> {
    return oktaService.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return of(true);
        }

        /**
         * Store the current path and then send to log in
         */
        oktaService.loginRedirect(url);

        return of(false);
      })
    );
  }
}
