import { Params } from '@angular/router';
import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import * as fromGlobal from '../reducers';
import { CustomRouterState } from '../reducers';

export const getRouterReducerState = createSelector(
  fromGlobal.getRouterState,
  (state) => state?.state
);
export const getRouterReducerStateUrl = createSelector(
  getRouterReducerState,
  (routerState) => routerState?.url
);
export const getRouterReducerStateUrlWithoutQueryParams = createSelector(
  getRouterReducerState,
  (routerState) => routerState?.urlWithoutQueryParams
);

export const getRouterQueryParams = createSelector(
  getRouterReducerState,
  (routerReducerState: CustomRouterState) => routerReducerState?.queryParams
);

export const getRouterParams = createSelector(
  getRouterReducerState,
  (routerReducerState: CustomRouterState) => routerReducerState?.params
);

export const getAllParams = createSelector(
  getRouterReducerState,
  (routerReducerState: CustomRouterState) => routerReducerState?.allParams
);

// returns the data object of the current route
export const getRouterReducerRouteData = createSelector(getRouterReducerState, ({ data }) => data);

// returns an object array that represents the data objects on each route layer
export const getRouterReducerAllRoutesData = createSelector(
  getRouterReducerState,
  ({ allData }) => allData
);

// pass in the query param key in string format and this selector returns
// the value for that key in the queryParams array
export const getRouterQueryParamByToken = createSelector(
  getRouterQueryParams,
  (queryParams, props) => {
    if (queryParams && props) {
      return queryParams[props];
    }
  }
);

export const getRouterParamsFlattened = createSelector(getAllParams, (allParamsAsArray) => {
  const allParams: object = {};

  _.each(allParamsAsArray, (paramArray: Params[]) => {
    Object.assign(allParams, { ...paramArray });
  });

  return allParams;
});
