import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { IPollinatorClass } from 'src/app/shared/models/data-model/pollinator-class.interface';
import {
  ICornProduct,
  ICropFamily,
  IInbred,
  IInventoryGraph,
  IPackageType,
  IPlantingPattern,
  IProduct,
  IProductsRequest
} from 'src/app/shared/models/data-model/products.interface';
import { ISeedTreatment } from 'src/app/shared/models/data-model/seed-treatment.interface';

@Injectable()
export class ProductsService {
  constructor(private _apiService: ApiService) {}

  fetchCropFamilies(): Observable<ICropFamily[]> {
    return this._apiService.get('/shared/products/crops').pipe(map((body) => body.crops));
  }

  fetchInbreds(): Observable<{ inbreds: IInbred[] }> {
    return this._apiService.get('/shared/products/inbreds?CropCode=CORN');
  }

  fetchPlantingPatterns(): Observable<{ patterns: IPlantingPattern[] }> {
    return this._apiService.get('/shared/products/plantingPatterns');
  }

  fetchPollinatorClasses(): Observable<{ pollinatorClasses: IPollinatorClass[] }> {
    return this._apiService.get('/shared/blocks/pollinatorClasses');
  }

  fetchProducts(productsRequest: IProductsRequest): Observable<IProduct[] | ICornProduct[]> {
    return this._apiService
      .get(
        `/shared/products?CropCode=${productsRequest.cropCode}&ProductionYearID=${productsRequest.productionYear}`
      )
      .pipe(map((body) => body.content));
  }

  fetchPackageTypes(): Observable<IPackageType[]> {
    return this._apiService
      .get('/shared/products/packageTypes')
      .pipe(map((body) => body.packageTypes));
  }

  fetchSeedTreatments(): Observable<ISeedTreatment[]> {
    return this._apiService
      .get('/seedTracker/imqc/treatments')
      .pipe(map((body) => body.treatments));
  }

  getProductInventoryGraph(productId: number): Observable<IInventoryGraph> {
    return this._apiService.get(`/seedTracker/products/${productId}/inventoryGraph`);
  }

  saveProduct(
    product: IProduct,
    productionYearID: number
  ): Observable<{ content: IProduct | ICornProduct; status: { code: number; message: string } }> {
    return this._apiService.put(`/shared/products/${product.ID}`, {
      cropID: product.cropID,
      product,
      productionYearID
    });
  }
}
