import { createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import {
  ECropCode,
  ICornProduct,
  ICropFamily,
  IProduct
} from 'src/app/shared/models/data-model/products.interface';
import * as fromGlobal from '../reducers';
import * as fromProducts from '../reducers/products.reducer';

export const getProductsState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.products
);

export const getCropFamilyEntities = createSelector(
  getProductsState,
  fromProducts.getCropFamilyEntities
);
export const getCropFamilyEntitiesByCropID = createSelector(getCropFamilyEntities, (cropEntities) =>
  _.keyBy(cropEntities, 'ID')
);
export const getCropFamiliesLoaded = createSelector(
  getProductsState,
  fromProducts.getCropFamiliesLoaded
);
export const getCropFamiliesLoading = createSelector(
  getProductsState,
  fromProducts.getCropFamiliesLoading
);
export const getCropFamiliesErred = createSelector(
  getProductsState,
  fromProducts.getCropFamiliesErred
);

export const getIsCornProductLoading = createSelector(
  getProductsState,
  (state) => state.viewModel.cornProductsLoading
);

export const getProductEntities = createSelector(getProductsState, fromProducts.getProductEntities);
export const getCornProductsAll = createSelector(getProductEntities, (products) => {
  return !products[ECropCode.CORN]
    ? []
    : (Object.keys(products[ECropCode.CORN]).map(
        (x) => products[ECropCode.CORN][x]
      ) as ICornProduct[]);
});

export const getSoybeanProductsAll = createSelector(getProductEntities, (products) => {
  return !products[ECropCode.SOY]
    ? []
    : (Object.keys(products[ECropCode.SOY]).map((x) => products[ECropCode.SOY][x]) as IProduct[]);
});
export const getWheatProductsAll = createSelector(getProductEntities, (products) => {
  return !products[ECropCode.WHEAT]
    ? []
    : (Object.keys(products[ECropCode.WHEAT]).map(
        (x) => products[ECropCode.WHEAT][x]
      ) as IProduct[]);
});

export const getPackageTypeEntities = createSelector(
  getProductsState,
  fromProducts.getPackageTypeEntities
);
export const getPackageTypesLoaded = createSelector(
  getProductsState,
  fromProducts.getPackageTypesLoaded
);
export const getPackageTypesLoading = createSelector(
  getProductsState,
  fromProducts.getPackageTypesLoading
);
export const getPackageTypesErred = createSelector(
  getProductsState,
  fromProducts.getPackageTypesErred
);

export const getAllSeedTreatments = createSelector(
  getProductsState,
  fromProducts.getSeedTreatments
);
export const getSeedTreatmentEntities = createSelector(
  getProductsState,
  fromProducts.getSeedTreatmentsEntities
);
export const getSeedTreatmentsLoaded = createSelector(
  getProductsState,
  fromProducts.getSeedTreatmentsLoaded
);
export const getSeedTreatmentsLoading = createSelector(
  getProductsState,
  fromProducts.getSeedTreatmentsLoading
);
export const getSeedTreatmentsErred = createSelector(
  getProductsState,
  fromProducts.getSeedTreatmentsErred
);

/** Derived selectors */
export const getCropFamilies = createSelector(getCropFamilyEntities, (entities) =>
  Object.keys(entities).map((crop) => {
    return entities[crop];
  })
);

export const getVarietyCropFamilies = createSelector(getCropFamilyEntities, (entities) => {
  return Object.values(entities).filter((crop) => crop.code !== 'CORN');
});
// soy products
export const getSoybeanProducts = createSelector(getProductEntities, (entities) => {
  return Object.keys(entities[ECropCode.SOY]).map((product) => {
    return entities[ECropCode.SOY][product];
  });
});
// wheat products
export const getWheatProducts = createSelector(getProductEntities, (entities) => {
  return Object.keys(entities[ECropCode.WHEAT]).map((product) => {
    return entities[ECropCode.WHEAT][product];
  });
});

export const getCropFamiliesByCropID = createSelector(getCropFamilyEntitiesByCropID, (entities) =>
  Object.keys(entities).map((crop) => {
    return entities[crop];
  })
);

export const getPackageTypes = createSelector(getPackageTypeEntities, (entities) =>
  Object.keys(entities).map((packageType) => {
    return entities[packageType];
  })
);

export const getCropFamiliesFromArray = (cropTypes: ECropCode[]) =>
  createSelector(getCropFamilyEntities, (entities: { [code: string]: ICropFamily }) => {
    return Object.keys(entities)
      .filter((crop) => cropTypes.includes(crop as ECropCode))
      .map((crop) => entities[crop]);
  });
