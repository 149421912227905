import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { CustomRouterState } from './router.reducer';

// create global state
export interface State {
  routerReducer: fromRouter.RouterReducerState<CustomRouterState>;
}

// map metal-level reducers that do not have their own module but
// need to be part of state
export const reducers: ActionReducerMap<State> = {
  routerReducer: fromRouter.routerReducer
};

export * from './router.reducer';
