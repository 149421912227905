import { HttpHeaders } from '@angular/common/http';

export enum ContentType {
  Json = 'application/json',
  Text = 'text/plain',
  Pdf = 'application/pdf',
  FormData = 'multipart/form-data'
}

export type ResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';

export interface RequestOptions {
  headers?: HttpHeaders | Record<string, string | string[]>;
  params?: Record<string, any>;
  reportProgress?: boolean;
  responseType?: ResponseType;
  withCredentials?: boolean;
}
