import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { getRegionsLoaded, getRegionsErred } from 'src/app/root-store/global-store/store/selectors';
import { LoadRegions } from 'src/app/root-store/global-store/store/actions';
import { LoadActionGuardFactoryService } from 'src/app/core/services/load-action-guard-factory.service';

@Injectable()
export class RegionsGuard implements CanActivate {
  constructor(private _guardFactory: LoadActionGuardFactoryService) {}

  canActivate(): Observable<boolean> {
    return this._guardFactory.canAdvance({
      loadAction: new LoadRegions(),
      isLoadedSelector: getRegionsLoaded,
      hasErrorSelector: getRegionsErred
    });
  }
}
