import { Action } from '@ngrx/store';
import { EAppModule } from 'src/app/shared/models/data-model/app-module.interface';
import {
  ILayoutMap,
  IMenuItem,
  ITitleBar
} from 'src/app/shared/models/data-model/layout.interface';

export enum LayoutActionTypes {
  SetUserLayoutAction = '[Global: Layout] Set User Layout',
  SetUserLayoutCompleteAction = '[Global: Layout] Set User Layout Complete',

  ShowSidebarAction = '[Global: Layout] Show Side Bar',
  HideSidebarAction = '[Global: Layout] Hide Side Bar',
  ToggleSidebarAction = '[Global: Layout] Toggle Side Bar',

  ShowMobileBreakpointAction = '[Global: Layout] Show Mobile Breakpoint',
  HideMobileBreakpointAction = '[Global: Layout] Hide Mobile Breakpoint',

  UpdateTitleBarAction = '[Global: Layout] Update Title Bar',

  ResetUserLayoutAction = '[Global: Layout] Set Active Module App',

  UpdateUserSidebarMenuAction = '[Global: Layout] Update User Sidebar Menu',

  TitleBarQuickSearchValueUpdatedAction = '[Global: Layout] Title Bar Quick Search Value Updated'
}

export class SetUserLayout implements Action {
  readonly type = LayoutActionTypes.SetUserLayoutAction;
}
export class SetUserLayoutComplete implements Action {
  readonly type = LayoutActionTypes.SetUserLayoutCompleteAction;
  constructor(public payload: ILayoutMap) {}
}

export class ShowSidebar implements Action {
  readonly type = LayoutActionTypes.ShowSidebarAction;
}
export class HideSidebar implements Action {
  readonly type = LayoutActionTypes.HideSidebarAction;
}

export class ToggleSidebar implements Action {
  readonly type = LayoutActionTypes.ToggleSidebarAction;
}

export class ShowMobileBreakpoint implements Action {
  readonly type = LayoutActionTypes.ShowMobileBreakpointAction;
}
export class HideMobileBreakpoint implements Action {
  readonly type = LayoutActionTypes.HideMobileBreakpointAction;
}

export class UpdateTitleBar implements Action {
  readonly type = LayoutActionTypes.UpdateTitleBarAction;
  constructor(public payload: ITitleBar) {}
}

export class ResetUserLayout implements Action {
  readonly type = LayoutActionTypes.ResetUserLayoutAction;
}
export class UpdateUserSidebarMenu implements Action {
  readonly type = LayoutActionTypes.UpdateUserSidebarMenuAction;
  constructor(public payload: { menu: IMenuItem[]; appModule: EAppModule }) {}
}
export class TitleBarQuickSearchValueUpdated implements Action {
  readonly type = LayoutActionTypes.TitleBarQuickSearchValueUpdatedAction;
  constructor(public payload: { searchValue: string }) {}
}

export type LayoutActions =
  | SetUserLayout
  | SetUserLayoutComplete
  | ResetUserLayout
  | ShowSidebar
  | HideSidebar
  | ShowMobileBreakpoint
  | HideMobileBreakpoint
  | ToggleSidebar
  | UpdateUserSidebarMenu
  | SetUserLayoutComplete
  | UpdateTitleBar
  | TitleBarQuickSearchValueUpdated;
