import { createSelector } from '@ngrx/store';
import * as fromGlobal from '../reducers';
import * as fromLots from '../reducers/lots.reducer';

export const getLotsState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.lots
);

export const getLotsLotCheckLotID = createSelector(getLotsState, fromLots.getLotsLotCheckLotID);

/** Derived Selectors */
export const getLotsLotCheckLotExists = createSelector(
  getLotsState,
  (state: fromLots.LotsState) => state.viewModel.existence
);
