import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '../auth.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // we retrieve the token, if any
    return from(this._authService.getAccessToken()).pipe(
      switchMap((accessToken) => {
        // make sure token is only being added for internal apps
        const url = new URL(req.url);
        const isBeckUrl = url.hostname.endsWith('.beckshybrids.com');
        const isLocalhost = url.hostname === 'localhost';
        const isAppUrl = isBeckUrl || isLocalhost;

        let newHeaders = req.headers;
        if (accessToken && isAppUrl) {
          // if we have a token, we append it to our new headers
          newHeaders = newHeaders.append('Authorization', `Bearer ${accessToken}`);
        }

        // finally we have to clone our request with our new headers (HttpRequests are immutable)
        const authReq = req.clone({ headers: newHeaders });

        // return an Observable that will run the request
        // or pass it to the next interceptor if any
        return next.handle(authReq);
      })
    );
  }
}
