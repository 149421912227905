import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SnackbarService } from 'src/app/library/snackbar';
import { UsersService } from '../../../../core/services/users.service';
import { loadUsers, loadUsersFail, loadUsersSuccess } from '../actions';

@Injectable()
export class UsersEffects {
  constructor(
    private _actions$: Actions,
    private _usersService: UsersService,
    public _snackbarService: SnackbarService
  ) {}

  loadUsers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadUsers),
      switchMap(() =>
        this._usersService.fetchUsers().pipe(
          map((users) => {
            return loadUsersSuccess({ users });
          }),
          catchError((error) => {
            this._snackbarService.error('Failed to load users');
            return of(loadUsersFail({ error }));
          })
        )
      )
    )
  );
}
