import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProductionYearService } from 'src/app/core/services/production-year.service';
import { SnackbarService } from 'src/app/library/snackbar';
import * as fromRoot from 'src/app/root-store/store/reducers';
import * as fromActions from '../actions';
import { ProductionYearActionTypes } from '../actions';
import * as fromReducers from '../reducers';
import * as fromSelectors from '../selectors';

@Injectable()
export class ProductionYearEffects {
  constructor(
    private _actions$: Actions,
    private _prodYearService: ProductionYearService,
    private _snackbarService: SnackbarService,
    private _store: Store<fromReducers.GlobalState>,
    private _rootStore: Store<fromRoot.State>
  ) {}

  loadProductionYears$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductionYearActionTypes.LoadProductionYears),
      switchMap(() => {
        return this._prodYearService.fetchYears().pipe(
          map((payload) => {
            return new fromActions.LoadProductionYearsSuccessAction(payload);
          }),
          catchError((error) => {
            this._snackbarService.error('Error Loading Production Years');
            return of(new fromActions.LoadProductionYearsFailAction(error));
          })
        );
      })
    )
  );

  previousProductionYear$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductionYearActionTypes.GoToPreviousProductionYear),
      withLatestFrom(
        this._store.select(fromSelectors.getProductionYears),
        this._store.select(fromSelectors.getProductionYearSelectedYear)
      ),
      switchMap(([action, prodYears, selectedYear]) => {
        const currentYearNumber = selectedYear ? selectedYear.YearNumber : null;
        const previousYear = _.find(
          prodYears,
          (prodYear) => prodYear.YearNumber === currentYearNumber - 1
        );

        return of(new fromActions.UpdateSelectedProductionYearAction(previousYear));
      })
    )
  );

  nextProductionYear$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductionYearActionTypes.GoToNextProductionYear),
      withLatestFrom(
        this._store.select(fromSelectors.getProductionYears),
        this._store.select(fromSelectors.getProductionYearSelectedYear)
      ),
      switchMap(([action, prodYears, selectedYear]) => {
        const currentYearNumber = selectedYear ? selectedYear.YearNumber : null;
        const previousYear = _.find(
          prodYears,
          (prodYear) => prodYear.YearNumber === currentYearNumber + 1
        );

        return of(new fromActions.UpdateSelectedProductionYearAction(previousYear));
      })
    )
  );

  productYearsLoaded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          ProductionYearActionTypes.LoadProductionYearsSuccess,
          ProductionYearActionTypes.UpdateSelectedProductionYear
        ),
        withLatestFrom(
          this._store.select(fromSelectors.getProductionYears),
          this._store.select(fromSelectors.getProductionYearDefaultYear),
          this._store.select(fromSelectors.getProductionYearSelectedYear)
        ),
        map(([action, prodYears, defaultYear, selectedYear]) => {
          // update production year
          this._prodYearService.onProdYearChanged$.emit({
            years: prodYears,
            default: defaultYear,
            current: selectedYear
          });
        })
      ),
    { dispatch: false }
  );

  hideProductionYearSelectorOnNavigation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(this._rootStore.select(fromRoot.getRouterState)),
      filter(([action, routerState]) => routerState.state.data.hideProdYearSelector),
      switchMap(() => {
        return of(new fromActions.HideProductionYearSelectorAction());
      })
    )
  );

  showProductionYearSelectorOnNavigation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(
        this._rootStore.select(fromRoot.getRouterState),
        this._store.select(fromSelectors.getProductionYearIsSelectorVisible)
      ),
      filter(([action, routerState, selectorIsVisible]) => {
        return !routerState.state.data.hideProdYearSelector && !selectorIsVisible;
      }),
      switchMap(() => {
        return of(new fromActions.ShowProductionYearSelectorAction());
      })
    )
  );

  hideProductionYearLabel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductionYearActionTypes.UpdateProductionYearLabel),
      map((action: fromActions.UpdateProductionYearLabelAction) => action.payload),
      filter((label) => !label),
      switchMap(() => {
        return of(new fromActions.HideProductionYearLabelAction());
      })
    )
  );

  displayProductionYearLabel$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductionYearActionTypes.UpdateProductionYearLabel),
      map((action: fromActions.UpdateProductionYearLabelAction) => action.payload),
      filter((label) => !!label),
      switchMap(() => {
        return of(new fromActions.ShowProductionYearLabelAction());
      })
    )
  );

  hideProductionYearLabelOnNavigation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(
        this._rootStore.select(fromRoot.getRouterState),
        this._store.select(fromSelectors.getProductionYearIsLabelVisible)
      ),
      filter(([action, routerState, labelIsVisible]) => {
        return !routerState.state.data.prodYearSelectorLabel && labelIsVisible;
      }),
      switchMap(() => {
        return of(new fromActions.UpdateProductionYearLabelAction(''));
      })
    )
  );

  showProductionYearLabelOnNavigation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      withLatestFrom(this._rootStore.select(fromRoot.getRouterState)),
      filter(([action, routerState]) => {
        return !!routerState.state.data.prodYearSelectorLabel;
      }),
      switchMap(([action, routerState]) => {
        const label: string = routerState.state.data.prodYearSelectorLabel;
        return of(new fromActions.UpdateProductionYearLabelAction(label));
      })
    )
  );
}
