import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { GlobalService } from 'src/app/core/services/global.service';
import * as fromActions from '../actions';
import { AcreageOverviewActionTypes } from '../actions';
import * as fromReducers from '../reducers';
import * as fromSelectors from '../selectors';

@Injectable()
export class AcreageOverviewEffects {
  constructor(
    private _actions$: Actions,
    private _globalService: GlobalService,
    private _store: Store<fromReducers.GlobalState>
  ) {}

  loadAcreageOverview$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AcreageOverviewActionTypes.LoadAcreageOverviewAction),
      withLatestFrom(this._store.select(fromSelectors.getProductionYearSelectedYear)),
      switchMap(([action, selectedYear]) => {
        return this._globalService.fetchAcreageOverview(selectedYear.ID).pipe(
          map((overview) => new fromActions.LoadAcreageOverviewSuccess(overview)),
          catchError(() => of(new fromActions.LoadAcreageOverviewFail()))
        );
      })
    )
  );

  closeExpandedTable$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        fromActions.LayoutActionTypes.HideSidebarAction,
        fromActions.LayoutActionTypes.ToggleSidebarAction
      ),
      withLatestFrom(
        this._store.select(fromSelectors.getLayoutIsSidebarOpen),
        this._store.select(fromSelectors.getAcreageOverviewIsPanelExpanded)
      ),
      filter(([action, isSidebarOpen, isPanelExpanded]) => !isSidebarOpen && isPanelExpanded),
      switchMap(() => of(new fromActions.ToggleAcreageOverviewPanelExpanded()))
    )
  );

  reloadAcreageOverviewOnProductionYearChange$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.ProductionYearActionTypes.UpdateSelectedProductionYear),
      switchMap(() => of(new fromActions.LoadAcreageOverview()))
    )
  );
}
