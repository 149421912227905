import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: CanComponentDeactivate) {
    // in the event of re-directs because a component didn't have the query parameters that it needed
    // this gets called and explodes thus halting the redirect

    return component?.canDeactivate ? component.canDeactivate() : true;
  }
}
