import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AuthConfig } from './auth.models';
import { AuthRoutingModule } from './auth.routing';
import { AuthService } from './auth.service';
import { BECKS_AUTH_CONFIG } from './auth.tokens';
import { guards } from './guards';
import { AuthHttpInterceptor } from './interceptors/auth.interceptor';

import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { LogoutComponent } from './components/logout/logout.component';

@NgModule({
  imports: [AuthRoutingModule, CommonModule],
  declarations: [LogoutComponent, LoginCallbackComponent]
})
export class AuthModule {
  static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        ...guards,
        AuthService,
        { provide: BECKS_AUTH_CONFIG, useValue: config },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
      ]
    };
  }
}
