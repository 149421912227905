import { Action } from '@ngrx/store';
import { INotification } from 'src/app/shared/models/data-model/notifications.interface';

export enum NotificationsActionTypes {
  InitializeNotificationsSocketConnectionAction = '[Global: Notifications] Initialize Notifications Socket Connection',
  InitializeNotificationsSocketConnectionFailAction = '[Global: Notifications] Initialize Notifications Socket Connection Fail',
  InitializeNotificationsSocketConnectionSuccessAction = '[Global: Notifications] Initialize Notifications Socket Connection Success',

  ShowNotificationsAction = '[Global: Notifications] Show Notifications',
  HideNotificationsAction = '[Global: Notifications] Hide Notifications',
  ToggleNotificationsAction = '[Global: Notifications] Toggle Notifications',

  StartNotificationBadgeAnimationAction = '[Global: Notifications] Start Notification Badge Animation',
  StopNotificationBadgeAnimationAction = '[Global: Notifications] Stop Notification Badge Animation',

  LoadNotificationsAction = '[Global: Notifications] Load Notifications',
  LoadNotificationsFailAction = '[Global: Notifications] Load Notifications Fail',
  LoadNotificationsSuccessAction = '[Global: Notifications] Load Notifications Success',

  SetSeenNotificationAction = '[Global: Notifications] Set Seen Notification',
  SetSeenNotificationFailAction = '[Global: Notifications] Set Seen Notification Fail',
  SetSeenNotificationSuccessAction = '[Global: Notifications] Set Seen Notification Success',

  SetClearNotificationAction = '[Global: Notifications] Set Clear Notification',
  SetClearNotificationFailAction = '[Global: Notifications] Set Clear Notification Fail',
  SetClearNotificationSuccessAction = '[Global: Notifications] Set Clear Notification Success',

  SetSeenNotificationBatchAction = '[Global: Notifications] Set Seen Notification Batch',
  SetSeenNotificationBatchFailAction = '[Global: Notifications] Set Seen Notification Batch Fail',
  SetSeenNotificationBatchSuccessAction = '[Global: Notifications] Set Seen Notification Batch Success',

  SetClearNotificationBatchAction = '[Global: Notifications] Set Clear Notification Batch',
  SetClearNotificationBatchFailAction = '[Global: Notifications] Set Clear Notification Batch Fail',
  SetClearNotificationBatchSuccessAction = '[Global: Notifications] Set Clear Notification Batch Success',

  AddNotificationToEntitiesAction = '[Global: Notifications] Add Notification To Entities',

  NavigateToNotificationLinkAction = '[Global: Notifications] Navigate To Notification Link',

  ResetNotificationsStateAction = '[Global: Notifications] Reset Notifications State'
}

// SOCKET CONNECTION
export class InitializeNotificationsSocketConnection implements Action {
  readonly type = NotificationsActionTypes.InitializeNotificationsSocketConnectionAction;
}
export class InitializeNotificationsSocketConnectionFail implements Action {
  readonly type = NotificationsActionTypes.InitializeNotificationsSocketConnectionFailAction;
  constructor(public payload: any) {}
}
export class InitializeNotificationsSocketConnectionSuccess implements Action {
  readonly type = NotificationsActionTypes.InitializeNotificationsSocketConnectionSuccessAction;
}

export class ShowNotifications implements Action {
  readonly type = NotificationsActionTypes.ShowNotificationsAction;
}
export class HideNotifications implements Action {
  readonly type = NotificationsActionTypes.HideNotificationsAction;
}
export class ToggleNotifications implements Action {
  readonly type = NotificationsActionTypes.ToggleNotificationsAction;
}

export class StartNotificationBadgeAnimation implements Action {
  readonly type = NotificationsActionTypes.StartNotificationBadgeAnimationAction;
}
export class StopNotificationBadgeAnimation implements Action {
  readonly type = NotificationsActionTypes.StopNotificationBadgeAnimationAction;
}

// NOTIFICATIONS
export class LoadNotifications implements Action {
  readonly type = NotificationsActionTypes.LoadNotificationsAction;
}
export class LoadNotificationsFail implements Action {
  readonly type = NotificationsActionTypes.LoadNotificationsFailAction;
  constructor(public payload: any) {}
}
export class LoadNotificationsSuccess implements Action {
  readonly type = NotificationsActionTypes.LoadNotificationsSuccessAction;
  constructor(public payload: INotification[]) {}
}

export class SetSeenNotification implements Action {
  readonly type = NotificationsActionTypes.SetSeenNotificationAction;
  constructor(public payload: INotification) {}
}
export class SetSeenNotificationFail implements Action {
  readonly type = NotificationsActionTypes.SetSeenNotificationFailAction;
  constructor(public payload: any) {}
}
export class SetSeenNotificationSuccess implements Action {
  readonly type = NotificationsActionTypes.SetSeenNotificationSuccessAction;
  constructor(public payload: INotification) {}
}

export class SetClearNotification implements Action {
  readonly type = NotificationsActionTypes.SetClearNotificationAction;
  constructor(public payload: INotification) {}
}
export class SetClearNotificationFail implements Action {
  readonly type = NotificationsActionTypes.SetClearNotificationFailAction;
  constructor(public payload: any) {}
}
export class SetClearNotificationSuccess implements Action {
  readonly type = NotificationsActionTypes.SetClearNotificationSuccessAction;
  constructor(public payload: INotification) {}
}

export class SetSeenNotificationBatch implements Action {
  readonly type = NotificationsActionTypes.SetSeenNotificationBatchAction;
}
export class SetSeenNotificationBatchFail implements Action {
  readonly type = NotificationsActionTypes.SetSeenNotificationBatchFailAction;
  constructor(public payload: any) {}
}
export class SetSeenNotificationBatchSuccess implements Action {
  readonly type = NotificationsActionTypes.SetSeenNotificationBatchSuccessAction;
}

export class SetClearNotificationBatch implements Action {
  readonly type = NotificationsActionTypes.SetClearNotificationBatchAction;
}
export class SetClearNotificationBatchFail implements Action {
  readonly type = NotificationsActionTypes.SetClearNotificationBatchFailAction;
  constructor(public payload: any) {}
}
export class SetClearNotificationBatchSuccess implements Action {
  readonly type = NotificationsActionTypes.SetClearNotificationBatchSuccessAction;
}

export class NavigateToNotificationLink implements Action {
  readonly type = NotificationsActionTypes.NavigateToNotificationLinkAction;
  constructor(public payload: INotification) {}
}

export class AddNotificationToEntities implements Action {
  readonly type = NotificationsActionTypes.AddNotificationToEntitiesAction;
  constructor(public payload: INotification) {}
}

export class ResetNotificationsState implements Action {
  readonly type = NotificationsActionTypes.ResetNotificationsStateAction;
}

export type NotificationsActions =
  | InitializeNotificationsSocketConnection
  | InitializeNotificationsSocketConnectionFail
  | InitializeNotificationsSocketConnectionSuccess
  | ShowNotifications
  | HideNotifications
  | ToggleNotifications
  | StartNotificationBadgeAnimation
  | StopNotificationBadgeAnimation
  | LoadNotifications
  | LoadNotificationsFail
  | LoadNotificationsSuccess
  | SetSeenNotification
  | SetSeenNotificationFail
  | SetSeenNotificationSuccess
  | SetClearNotification
  | SetClearNotificationFail
  | SetClearNotificationSuccess
  | SetSeenNotificationBatch
  | SetSeenNotificationBatchFail
  | SetSeenNotificationBatchSuccess
  | SetClearNotificationBatch
  | SetClearNotificationBatchFail
  | SetClearNotificationBatchSuccess
  | NavigateToNotificationLink
  | AddNotificationToEntities
  | ResetNotificationsState;
