import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { getAllRegions } from 'src/app/root-store/global-store/store/selectors/regions.selector';
import { FeatureCodes } from 'src/app/shared/helpers/features.helper';
import { EUserType, IUserPermissions } from 'src/app/shared/models/data-model/user.interface';
import * as fromGlobal from '../reducers';
import * as fromUser from '../reducers/user.reducer';

export const getUserState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.user
);

export const getUserStateModel = createSelector(getUserState, ({ model }) => {
  return model;
});
export const getUser = createSelector(getUserState, fromUser.getUser);
export const getUserLoaded = createSelector(getUserState, fromUser.getUserLoaded);
export const getUserLoading = createSelector(getUserState, fromUser.getUserLoading);
export const getUserErred = createSelector(getUserState, fromUser.getUserErred);
export const getUserDeSync = createSelector(getUserState, fromUser.getUserDeSync);

export const getUserPermissions = createSelector(getUserState, fromUser.getUserPermissions);
export const getUserPermissionsLoaded = createSelector(
  getUserState,
  fromUser.getUserPermissionsLoaded
);
export const getUserPermissionsLoading = createSelector(
  getUserState,
  fromUser.getUserPermissionsLoading
);
export const getUserPermissionsErred = createSelector(
  getUserState,
  fromUser.getUserPermissionsErred
);

export const getUserRegionIDs = createSelector(getUserState, fromUser.getUserRegions);
export const getUserRegionsLoaded = createSelector(getUserState, fromUser.getUserRegionsLoaded);
export const getUserRegionsLoading = createSelector(getUserState, fromUser.getUserRegionsLoading);
export const getUserRegionsErred = createSelector(getUserState, fromUser.getUserRegionsErred);

export const getUserPreferences = createSelector(getUserState, fromUser.getUserPreferences);
export const getUserPreferencesLoaded = createSelector(
  getUserState,
  fromUser.getUserPreferencesLoaded
);
export const getUserPreferencesLoading = createSelector(
  getUserState,
  fromUser.getUserPreferencesLoading
);
export const getUserPreferencesErred = createSelector(
  getUserState,
  fromUser.getUserPreferencesErred
);

export const getUserPreferenceTypes = createSelector(getUserState, fromUser.getUserPreferenceTypes);
export const getUserPreferencesForm = createSelector(getUserState, fromUser.getUserPreferencesForm);

/** Derived Selectors */
export const getUserPermissionCodes = createSelector(getUserStateModel, ({ permissions }) => {
  return _extractFeatures(permissions);
});

export const getUserIsInternal: MemoizedSelector<object, boolean> = createSelector(
  getUser,
  (user) => !!(user && user.type === EUserType.AD_INTERNAL)
);

export const getUserIsLoggedIn: MemoizedSelector<object, boolean> = createSelector(
  getUser,
  (user) => !!(user && user.userName !== '')
);

export const getRegionsAllowedForUser = createSelector(
  getUserRegionIDs,
  getAllRegions,
  (userRegionIDs, regions) => userRegionIDs.map((regionID) => regions[regionID])
);

// pass this selector an array of feature permission codes, if the user has any of them
// the selector will return true
// USAGE EX.
// hasDeletePermission$ = this._store.select(getUserHasFeatureCodeFromList, ['WPRODPLAN']);
export const getUserHasFeatureCodeFromList = (
  userPermissions: string[],
  props: { features: string[] }
) =>
  createSelector(getUserPermissionCodes, () => {
    return Boolean(_.intersection(userPermissions, props.features).length > 0);
  });

export const getUserAuthorizedModules = createSelector(
  getUserPermissions,
  ({ ST, CAPPS, UM, LAB }) => {
    const authorizedModules = {
      CAPPS: Boolean(CAPPS),
      ST: Boolean(ST),
      UM: Boolean(UM),
      LAB: Boolean(LAB)
    };

    return authorizedModules;
  }
);

export const getAuthorizedUMFeatures = createSelector(getUserPermissions, (permissions) => {
  const UM = permissions.UM;

  const authorizedUMFeatures = {
    UMCAPPS: Boolean(UM?.features[FeatureCodes.WUSR]),
    UMST: Boolean(UM?.features[FeatureCodes.WSTUSERS]),
    UMLAB: Boolean(UM?.features[FeatureCodes.WLABUSERS])
  };

  return authorizedUMFeatures;
});

/* Helper Functions */
const _extractFeatures = (permissions: IUserPermissions) => {
  let featureCodes = [];

  Object.keys(permissions).forEach((moduleKey: string) => {
    featureCodes = [...featureCodes, ...Object.keys(permissions[moduleKey].features)];
  });
  return featureCodes;
};
