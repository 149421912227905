/* Modules */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { BecksAvatarModule } from 'src/app/library/components/becks-avatar/becks-avatar.module';
import { BecksSearchInputModule } from 'src/app/library/components/becks-search-input/becks-search-input.module';
import { MaterialModule } from 'src/app/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
/** Components */
import { HeaderWellComponent } from './components/header-well/header-well.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { TitleComponent } from './components/title/title.component';
import { UserDeSyncComponent } from './components/user-de-sync/user-de-sync.component';
import { LayoutComponent } from './containers/layout/layout.component';
import { NotificationsComponent } from './containers/notifications/notifications.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    SharedModule,
    BecksAvatarModule,
    BecksSearchInputModule
  ],
  exports: [
    LayoutComponent,
    TitleBarComponent,
    TitleComponent,
    HeaderComponent,
    HeaderWellComponent
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    TitleBarComponent,
    TitleComponent,
    NotificationsComponent,
    NotificationListComponent,
    HeaderWellComponent,
    UserDeSyncComponent
  ],
  providers: [],
  schemas: []
})
export class LayoutModule {}
