import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SnackbarService } from 'src/app/library/snackbar';

import { AppDefaultsService } from 'src/app/core/services/app-defaults.service';

import * as fromActions from '../actions';

@Injectable()
export class AppDefaultsEffects {
  constructor(
    private _actions$: Actions,
    private _snackbarService: SnackbarService,
    private _defaultsService: AppDefaultsService
  ) {}

  loadAppDefaults$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromActions.loadAppDefaults),
      withLatestFrom(),
      switchMap(() => {
        return this._defaultsService.fetchAppDefaults().pipe(
          map((appDefaults) => fromActions.loadAppDefaultsSuccess({ appDefaults })),
          catchError((error) => {
            this._snackbarService.error('Failed Loading App Defaults');
            return of(fromActions.loadAppDefaultsFailure({ error }));
          })
        );
      })
    )
  );
}
