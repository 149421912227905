import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import { Action, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { GlobalStoreModule } from './global-store/global-store.module';

import environment from 'src/app/app.config';

// Import top-level stores to use sanitizers
// low level sanitizers should be handled in their feature modules
import * as fromForecastSanitizers from '../capps/forecast/store/sanitizers';
import * as fromGlobalSanitizers from './global-store/store/sanitizers';

import { CustomRouteSerializer, effects, reducers } from './store';

/* Store Related Init */
// not used in production
export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];

export function actionSanitizer(action: Action) {
  return action;
}

export function stateSanitizer(state: any) {
  // state sanitizer is used to replace slices of state for processing within the
  // store devTools ONLY.
  // currently this is only being used to prevent the devTools from crashing on state
  // storage that is too large for the browser extension to handle

  return {
    ...state,
    global: fromGlobalSanitizers.sanitize(state.global),
    forecastState: fromForecastSanitizers.sanitize(state.forecastState)
  };
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    /* store */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
    /* Note: This breaks the Angular profiler,
     * but without it, Redux devtools don't work */
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          actionSanitizer,
          stateSanitizer
        }),
    GlobalStoreModule
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouteSerializer
    }
  ]
})
export class RootStoreModule {}
