import * as _ from 'lodash';
import { IUserModule, IUserPermissions } from '../models/data-model/user.interface';

export function extractFeatures(modules: IUserModule[]): IUserPermissions {
  const permissions: IUserPermissions = {};

  _.forEach(modules, (mod) => {
    const featureGroups: { [key: string]: boolean } = {};
    const features: { [key: string]: boolean } = {};

    // go over each feature group
    _.forEach(mod.featureGroups, (group) => {
      featureGroups[group.featureGroupCode] = true;
      // go over each feature
      _.forEach(group.features, (feature) => {
        features[feature] = true;
      });
    });

    permissions[mod.moduleCode] = {
      featureGroups,
      features
    };
  });

  return permissions;
}

export enum FeatureCodes {
  RBINS = 'RBINS',
  WTRANS = 'WTRANS',
  WRESTR = 'WRESTR',
  RBINHIST = 'RBINHIST',
  RALLINV = 'RALLINV',
  WGRWRBIN = 'WGRWRBIN',
  WAIR = 'WAIR',
  WCLEAN = 'WCLEAN',
  WOFFLINE = 'WOFFLINE',
  RLOTSH = 'RLOTSH',
  WLOTSH = 'WLOTSH',
  RAO = 'RAO',
  RBLKMGT = 'RBLKMGT',
  WBLKMGT = 'WBLKMGT',
  RFLDMGT = 'RFLDMGT',
  WFLDMGT = 'WFLDMGT',
  WFSFIELD = 'WFSFIELD',
  RMAP = 'RMAP',
  WMAP = 'WMAP',
  RGENETICSUPPLY = 'RGENETICSUPPLY',
  WGENETICSUPPLY = 'WGENETICSUPPLY',
  RBRECIPE = 'RBRECIPE',
  WBRECIPE = 'WBRECIPE',
  RBSTAGGER = 'RBSTAGGER',
  WBSTAGGER = 'WBSTAGGER',
  RINVENTORY = 'RINVENTORY',
  WINVENTORY = 'WINVENTORY',
  RW_VARIETY_INVENTORY = 'RW_VARIETY_INVENTORY',
  R_C_LTFC = 'R-C-LTFC',
  W_C_LTFC = 'W-C-LTFC',
  E_C_LTFC = 'E-C-LTFC',
  W_C_LTFC_DEAD = 'W-C-LTFC-DEAD',
  R_C_LTFC_DEAD = 'R-C-LTFC-DEAD',
  W_C_LTFC_TRT = 'W-C-LTFC-TRT',
  W_C_LTFC_TCT = 'W-C-LTFC-TCT',
  W_C_LTFC_TCC = 'W-C-LTFC-TCC',
  R_S_LTFC = 'R-S-LTFC',
  W_S_LTFC = 'W-S-LTFC',
  E_S_LTFC = 'E-S-LTFC',
  W_S_LTFC_TRT = 'W-S-LTFC-TRT',
  W_S_LTFC_DEAD = 'W-S-LTFC-DEAD',
  R_S_LTFC_DEAD = 'R-S-LTFC-DEAD',
  W_S_LTFC_TCC = 'W-S-LTFC-TCC',
  W_W_LTFC_TCC = 'W-W-LTFC-TCC',
  R_W_LTFC = 'R-W-LTFC',
  W_W_LTFC = 'W-W-LTFC',
  E_W_LTFC = 'E-W-LTFC',
  W_W_LTFC_DEAD = 'W-W-LTFC-DEAD',
  R_W_LTFC_DEAD = 'R-W-LTFC-DEAD',
  W_W_LTFC_TRT = 'W-W-LTFC-TRT',
  RPRODFC = 'RPRODFC',
  WPRODFC = 'WPRODFC',
  RVARIETYFC = 'RVARIETYFC',
  WVARIETYFC = 'WVARIETYFC',
  RPRODPLAN = 'RPRODPLAN',
  WPRODPLAN = 'WPRODPLAN',
  RVARIETYPLAN = 'RVARIETYPLAN',
  WVARIETYPLAN = 'WVARIETYPLAN',
  RPT_IRRIGATION = 'RPT_IRRIGATION',
  RPT_BLKFLD = 'RPT_BLKFLD',
  RPT_ACRES = 'RPT_ACRES',
  RPT_HDISTROU = 'RPT_HDISTROU',
  RPT_HDISTROB = 'RPT_HDISTROB',
  RPT_HDISTRO = 'RPT_HDISTRO',
  RPT_SDISTRO = 'RPT_SDISTRO',
  RPT_HFSDIST = 'RPT_HFSDIST',
  RPT_PRODFC = 'RPT_PRODFC',
  RPT_GENEVNT = 'RPT_GENEVNT',
  RPT_HEVENT = 'RPT_HEVENT',
  RPT_FLDBATCH = 'RPT_FLDBATCH',
  RPT_INSTRUCT = 'RPT_INSTRUCT',
  RPT_PLANTER = 'RPT_PLANTER',
  RPT_IBDETAIL = 'RPT_IBDETAIL',
  RPT_GSSO = 'RPT_GSSO',
  RPT_FIELDXFERS = 'RPT_FIELDXFERS',
  RPT_BATCHBALANCES = 'RPT_BATCHBALANCES',
  RPT_STAGGER = 'RPT_STAGGER',
  RPT_OPSMASTER = 'RPT_OPSMASTER',
  RPT_PLANHYBRID = 'RPT_PLANHYBRID',
  RPT_PLANFIELD = 'RPT_PLANFIELD',
  RPT_FHLIST = 'RPT_FHLIST',
  RPT_PLANINBRED = 'RPT_PLANINBRED',
  RPT_DELTA = 'RPT_DELTA',
  RPT_DELTA2 = 'RPT_DELTA2',
  RPT_FHDEFOL = 'RPT_FHDEFOL',
  RPT_GROWERSS = 'RPT_GROWERSS',
  RPT_VFP = 'RPT_VFP',
  RPT_STAGGEREX = 'RPT_STAGGEREX',
  RPT_BIONIKEX = 'RPT_BIONIKEX',
  CAPPSUSER = 'CAPPSUSER',
  LABUSER = 'LABUSER',
  RGRWR = 'RGRWR',
  WGRWR = 'WGRWR',
  RHYB = 'RHYB',
  WHYB = 'WHYB',
  C_WNG = 'C_WNG',
  WREGION = 'WREGION',
  RGRWRWL = 'RGRWRWL',
  WGRWRWL = 'WGRWRWL',
  LOTUPCLASS = 'LOTUPCLASS',
  RLOTDOC = 'RLOTDOC',
  WLOTDOC = 'WLOTDOC',
  RLOTGO = 'RLOTGO',
  WLOTGO = 'WLOTGO',
  NEWGOA = 'NEWGOA',
  RNOTES = 'RNOTES',
  WNOTES = 'WNOTES',
  RCORNQA = 'RCORNQA',
  RQA = 'RQA',
  WPHI = 'WPHI',
  WELISA = 'WELISA',
  WSAMPLE = 'WSAMPLE',
  RSAMPLE = 'RSAMPLE',
  RLOTRUNS = 'RLOTRUNS',
  RRUNPLAN = 'RRUNPLAN',
  WRUNPLAN = 'WRUNPLAN',
  RRPWQA = 'RRPWQA',
  PRINTRPCALENDAR = 'PRINTRPCALENDAR',
  WRUNS = 'WRUNS',
  WSNRETAIL = 'WSNRETAIL',
  WSNSEEDSTOCK = 'WSNSEEDSTOCK',
  RSN = 'RSN',
  S_R_WSRUN = 'S-R-WSRUN',
  S_W_WSRUN = 'S-W-WSRUN',
  S_GENERATE_RUN = 'S-GENERATE-RUN',
  S_GENERATE_WSRUN = 'S-GENERATE-WSRUN',
  S_W_SEEDSTOCK_RUN = 'S-W-SEEDSTOCK-RUN',
  S_DELIVER_RUN = 'S-DELIVER-RUN',
  S_W_RETAIL_RUN = 'S-W-RETAIL-RUN',
  S_R_RUNPLAN = 'S-R-RUNPLAN',
  S_FIN_RUN = 'S-FIN-RUN',
  S_FIN_WSRUN = 'S-FIN-WSRUN',
  WT_FIN_WSRUN = 'WT-FIN-WSRUN',
  WT_FIN_RUN = 'WT-FIN-RUN',
  WT_R_RUNPLAN = 'WT-R-RUNPLAN',
  WT_W_RETAIL_RUN = 'WT-W-RETAIL-RUN',
  WT_DELIVER_RUN = 'WT-DELIVER-RUN',
  WT_R_WSRUN = 'WT-R-WSRUN',
  WT_W_WSRUN = 'WT-W-WSRUN',
  WT_GENERATE_WSRUN = 'WT-GENERATE-WSRUN',
  WT_GENERATE_RUN = 'WT-GENERATE-RUN',
  WT_W_SEEDSTOCK_RUN = 'WT-W-SEEDSTOCK-RUN',
  RGREENC = 'RGREENC',
  WGREENC = 'WGREENC',
  RSITETR = 'RSITETR',
  WSITETR = 'WSITETR',
  RPT_FIELDLOTBYPROD = 'RPT_FIELDLOTBYPROD',
  RPT_FIELDLOTBYFLD = 'RPT_FIELDLOTBYFLD',
  RPT_FIELDLOTBYLOT = 'RPT_FIELDLOTBYLOT',
  RPT_GRADEOUT = 'RPT_GRADEOUT',
  RPT_GREENCORNBYSITE = 'RPT_GREENCORNBYSITE',
  RPT_GREENCORNBYPROD = 'RPT_GREENCORNBYPROD',
  RPT_GBQA = 'RPT_GBQA',
  RPT_GFQA = 'RPT_GFQA',
  RPT_GBI = 'RPT_GBI',
  RPT_GBQA2 = 'RPT_GBQA2',
  RPT_BINV = 'RPT_BINV',
  RPT_RUNSOURCINGQA = 'RPT_RUNSOURCINGQA',
  RPT_RUNSOURCING = 'RPT_RUNSOURCING',
  RPT_RUNINSTRUCT = 'RPT_RUNINSTRUCT',
  RPT_RIRAW = 'RPT_RIRAW',
  RPT_SAMPLES = 'RPT_SAMPLES',
  RPT_CORNQA = 'RPT_CORNQA',
  RPT_SHELLINGBYPROD = 'RPT_SHELLINGBYPROD',
  RPT_SHELLINGBYSITE = 'RPT_SHELLINGBYSITE',
  RLOTSO = 'RLOTSO',
  WLOTSO = 'WLOTSO',
  NEWSO = 'NEWSO',
  STUSER = 'STUSER',
  RGOCONF = 'RGOCONF',
  WGOCONF = 'WGOCONF',
  RLOTASS = 'RLOTASS',
  WLOTASS = 'WLOTASS',
  WNG = 'WNG',
  RSITEMGMT = 'RSITEMGMT',
  WSITEMGMT = 'WSITEMGMT',
  WTOWER = 'WTOWER',
  RUSR = 'RUSR',
  WUSR = 'WUSR',
  RSTUSERS = 'RSTUSERS',
  WSTUSERS = 'WSTUSERS',
  RLABUSERS = 'RLABUSERS',
  WLABUSERS = 'WLABUSERS',
  RW_HU = 'RW-HU',
  VCR = 'VCR',
  ECR = 'ECR',
  DCR = 'DCR',
  LAB_SUBMIT_FG_SUBMIT = 'LAB-SUBMIT-FG-SUBMIT',
  LAB_SUBMIT_FG_RECV = 'LAB-SUBMIT-FG-RECV',
  LAB_PROCESS_FG_VIEW = 'LAB-PROCESS-FG-VIEW',
  LAB_PROCESS_FG_EDIT = 'LAB-PROCESS-FG-EDIT',
  LAB_PROCESS_FG_DEL = 'LAB-PROCESS-FG-DEL',
  LAB_RESULT_FG_VIEW = 'LAB-RESULT-FG-VIEW',
  LAB_RESULTS_FG_ADMIN = 'LAB-RESULTS-FG-ADMIN',
  WUSERLABACCESS = 'WUSERLABACCESS',
  WUSERCUSTACCESS = 'WUSERCUSTACCESS',
  RPT_LABRESULTS = 'RPT_LABRESULTS',
  RPT_LABVSODAK = 'RPT_LABVSODAK',
  RPT_LABDATESUMMARY = 'RPT_LABDATESUMMARY',
  RPT_LABDATEDETAILS = 'RPT_LABDATEDETAILS',
  RPT_LABNOTSTARTED = 'RPT_LABNOTSTARTED'
}

export enum GroupCodes {
  BINSTATUS = 'BINSTATUS',
  C_AO = 'C_AO',
  C_BLKMGT = 'C_BLKMGT',
  C_FMAP = 'C_FMAP',
  C_GS = 'C_GS',
  C_INV = 'C_INV',
  C_LTFC = 'C_LTFC',
  C_PPLAN = 'C_PPLAN',
  C_RPTC = 'C_RPTC',
  CAPPS_HIDDEN = 'CAPPS_HIDDEN',
  LAB_HIDDEN = 'LAB_HIDDEN',
  CAPPSADMIN = 'CAPPSADMIN',
  LABADMIN = 'LABADMIN',
  HARVESTLOTS = 'HARVESTLOTS',
  IMQC = 'IMQC',
  RECEIVING = 'RECEIVING',
  RPT = 'RPT',
  SHAKEOUTS = 'SHAKEOUTS',
  ST_HIDDEN = 'ST_HIDDEN',
  STADMIN = 'STADMIN',
  UMCAPPS = 'UMCAPPS',
  UMST = 'UMST',
  UMLAB = 'UMLAB',
  L_RPT = 'L_RPT'
}
