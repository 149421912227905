import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LotsService } from 'src/app/core/services/lots.service';
import * as fromActions from '../actions';
import { LotsActionTypes } from '../actions';
import * as fromReducers from '../reducers';
import * as fromSelectors from '../selectors';

@Injectable()
export class LotsEffects {
  constructor(
    private _actions$: Actions,
    private _lotsService: LotsService,
    private _router: Router,
    private _store: Store<fromReducers.GlobalState>
  ) {}

  checkLotCodeExists$ = createEffect(() =>
    this._actions$.pipe(
      ofType(LotsActionTypes.CheckLotExistsAction),
      map((action: fromActions.CheckLotExists) => action.payload),
      switchMap((lotCode) => {
        return this._lotsService.lotExists(lotCode).pipe(
          map((lotID) => new fromActions.CheckLotExistsSuccess(lotID)),
          catchError(() => of(new fromActions.CheckLotExistsFail()))
        );
      })
    )
  );

  navigateToLotPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(LotsActionTypes.NavigateToLotPageAction),
        withLatestFrom(this._store.select(fromSelectors.getLotsLotCheckLotID)),
        map(([action, lotID]) => {
          this._router.navigate([`/seedtracker/lot-central/${lotID}/details`]);
        })
      ),
    { dispatch: false }
  );
}
