<div class="confirmation-modal">
  <h1 mat-dialog-title class="modal-title">{{ title }}</h1>
  <div mat-dialog-content>{{ message }}</div>

  <div mat-dialog-actions>
    <ng-container>
      <button mat-raised-button [ngClass]="{ ok: isUnsavedModal }" (click)="dialogRef.close(false)">{{ cancelText }}</button>
      <button mat-raised-button [ngClass]="{ ok: isConfirmModal, danger: isDeleteModal }" (click)="dialogRef.close(true)">
        {{ confirmText }}
      </button>
    </ng-container>
  </div>
</div>
