import { FeatureCodes, GroupCodes } from 'src/app/shared/helpers/features.helper';
import { EAppModule } from '../models/data-model/app-module.interface';
import {
  EBreakpoints,
  ELayoutFeatures,
  IBreakpointsObject,
  ILayoutFeaturesMap,
  ILayoutMap,
  IMenuItem,
  IMenuMap
} from '../models/data-model/layout.interface';

const cappsMenu: IMenuItem[] = [
  {
    name: 'Forecasts',
    path: '',
    icon: 'fa-chart-area',
    subMenuItems: [
      {
        name: 'LT Forecast',
        path: '/capps/forecast/sales',
        icon: '',
        features: [FeatureCodes.R_C_LTFC, FeatureCodes.R_S_LTFC, FeatureCodes.R_W_LTFC]
      },
      {
        name: 'Corn Forecast',
        path: '/capps/forecast/production',
        icon: '',
        features: [FeatureCodes.RPRODFC]
      },
      {
        name: 'Variety Forecast',
        path: '/capps/forecast/variety',
        icon: '',
        features: [FeatureCodes.RVARIETYFC]
      }
    ]
  },
  {
    name: 'Inventory',
    path: '',
    icon: 'fa-truck',
    subMenuItems: [
      {
        name: 'Corn Seedstock',
        path: '/capps/inventory/corn-seedstock',
        icon: '',
        features: [FeatureCodes.RINVENTORY]
      },
      {
        name: 'Variety Seedstock',
        path: '/capps/inventory/variety',
        icon: '',
        features: [FeatureCodes.RW_VARIETY_INVENTORY]
      },
      {
        name: 'BioNik Recipe Plans',
        path: '/capps/inventory/bionik',
        icon: '',
        features: [FeatureCodes.RBRECIPE]
      }
    ]
  },
  {
    name: 'Genetics',
    path: '',
    icon: 'fa-flask',
    groups: ['C_GS'],
    subMenuItems: [
      { name: 'Genetic Supply', path: '/capps/genetics/supply', icon: '' },
      { name: 'Supplier Management', path: '/capps/genetics/suppliers', icon: '' }
    ]
  },
  {
    name: 'Acreage',
    path: '',
    icon: 'fa-arrows',
    subMenuItems: [
      {
        name: 'Block Management',
        path: '/capps/acreage/blocks',
        icon: '',
        features: [FeatureCodes.RBLKMGT]
      },
      {
        name: 'Field Management',
        path: '/capps/acreage/fields',
        icon: '',
        features: [FeatureCodes.RFLDMGT]
      }
    ]
  },
  {
    name: 'Production Plans',
    path: '',
    icon: 'fa-book',
    subMenuItems: [
      {
        name: 'Corn Plan',
        path: '/capps/plan/corn-plan',
        icon: '',
        features: [FeatureCodes.RPRODPLAN]
      },
      {
        name: 'Variety Plan',
        path: '/capps/plan/variety-plan',
        icon: '',
        features: [FeatureCodes.RVARIETYPLAN]
      },
      {
        name: 'Grower Field Plan',
        path: '/capps/plan/grower-field-plan',
        icon: '',
        features: [FeatureCodes.RVARIETYPLAN]
      }
    ]
  },
  { name: 'Reports', path: '/capps/capps-reports', icon: 'fa-print', groups: [GroupCodes.C_RPTC] },
  {
    name: 'Admin',
    path: '',
    icon: 'fa-connectdevelop',
    subMenuItems: [
      {
        name: 'Hybrid Management',
        path: '/capps/administration/hybrid-management',
        icon: '',
        features: [FeatureCodes.RHYB, FeatureCodes.WHYB]
      },
      {
        name: 'Grower Management',
        path: '/capps/growers',
        icon: '',
        features: [FeatureCodes.RGRWR]
      },
      {
        name: 'Region Access',
        path: '/capps/administration/region-access',
        icon: '',
        features: [FeatureCodes.WREGION]
      },
      {
        name: 'Notification Groups',
        path: '/capps/administration/notification-groups',
        icon: '',
        features: [FeatureCodes.C_WNG]
      }
    ]
  }
];

const labTrackerMenu: IMenuItem[] = [
  {
    name: 'Shipping',
    path: '/labtracker/shipping',
    icon: 'fa-truck',
    features: [FeatureCodes.LAB_SUBMIT_FG_SUBMIT, FeatureCodes.LAB_SUBMIT_FG_RECV]
  },
  {
    name: 'Preparation',
    path: '/labtracker/preparation',
    icon: 'fa-bag-seedling',
    features: [FeatureCodes.LAB_SUBMIT_FG_SUBMIT, FeatureCodes.LAB_SUBMIT_FG_RECV]
  },
  // {
  //   name: 'Samples',
  //   path: '/labtracker/samples',
  //   icon: 'fa-truck',
  //   features: [FeatureCodes.LAB_SUBMIT_FG_SUBMIT, FeatureCodes.LAB_SUBMIT_FG_RECV]
  // },
  {
    name: 'Testing',
    path: '/labtracker/testing',
    icon: 'fa-flask',
    subMenuItems: [
      {
        name: 'Purity',
        path: '/labtracker/testing/purity',
        icon: '',
        features: [
          FeatureCodes.LAB_PROCESS_FG_VIEW,
          FeatureCodes.LAB_PROCESS_FG_EDIT,
          FeatureCodes.LAB_PROCESS_FG_DEL
        ]
      },
      {
        name: 'Warm',
        path: '/labtracker/testing/warm',
        icon: '',
        features: [
          FeatureCodes.LAB_PROCESS_FG_VIEW,
          FeatureCodes.LAB_PROCESS_FG_EDIT,
          FeatureCodes.LAB_PROCESS_FG_DEL
        ]
      },
      {
        name: 'Cold',
        path: '/labtracker/testing/cold',
        icon: '',
        features: [
          FeatureCodes.LAB_PROCESS_FG_VIEW,
          FeatureCodes.LAB_PROCESS_FG_EDIT,
          FeatureCodes.LAB_PROCESS_FG_DEL
        ]
      },
      {
        name: 'Herbicide',
        path: '/labtracker/testing/herbicide',
        icon: '',
        features: [
          FeatureCodes.LAB_PROCESS_FG_VIEW,
          FeatureCodes.LAB_PROCESS_FG_EDIT,
          FeatureCodes.LAB_PROCESS_FG_DEL
        ]
      },
      {
        name: 'Elisa',
        path: '/labtracker/testing/elisa',
        icon: '',
        features: [
          FeatureCodes.LAB_PROCESS_FG_VIEW,
          FeatureCodes.LAB_PROCESS_FG_EDIT,
          FeatureCodes.LAB_PROCESS_FG_DEL
        ]
      }
    ]
  },
  {
    name: 'Results',
    path: '/labtracker/results',
    icon: 'fa-file-pdf',
    features: [FeatureCodes.LAB_RESULT_FG_VIEW]
  },
  {
    name: 'Reports',
    path: '/labtracker/labtracker-reports',
    icon: 'fa-print',
    groups: [GroupCodes.L_RPT]
  },
  {
    name: 'Admin',
    path: '/labtracker/admin',
    icon: 'fa-connectdevelop',
    subMenuItems: [
      {
        name: 'Lab Access',
        path: '/labtracker/admin/lab-access',
        icon: '',
        features: [FeatureCodes.WUSERLABACCESS]
      },
      {
        name: 'Customer Access',
        path: '/labtracker/admin/customer-access',
        icon: '',
        features: [FeatureCodes.WUSERCUSTACCESS]
      },
      {
        name: 'Lab Management',
        path: '/labtracker/admin/lab-management',
        icon: '',
        features: [FeatureCodes.WUSERLABACCESS]
      },
      {
        name: 'Customer Management',
        path: '/labtracker/admin/customer-management',
        icon: '',
        features: [FeatureCodes.WUSERCUSTACCESS]
      },
      {
        name: 'Test Management',
        path: '/labtracker/admin/test-management',
        icon: '',
        features: [FeatureCodes.LAB_RESULTS_FG_ADMIN]
      }
    ]
  }
];

const seedtrackerMenu: IMenuItem[] = [
  {
    name: 'Products',
    path: '/seedtracker/products',
    icon: 'fa-barcode',
    features: [FeatureCodes.RALLINV]
  },
  {
    name: 'Harvest Lots',
    path: '/seedtracker/lotlist',
    icon: 'fa-th-large',
    queryParams: { cropCode: 'CORN' },
    features: [
      FeatureCodes.RSAMPLE,
      FeatureCodes.RLOTDOC,
      FeatureCodes.RLOTGO,
      FeatureCodes.RNOTES,
      FeatureCodes.LOTUPCLASS
    ]
  },
  {
    name: 'Receiving',
    path: '',
    icon: 'fa-truck',
    subMenuItems: [
      {
        name: 'Green Corn',
        path: '/seedtracker/receiving/green-corn',
        icon: '',
        features: [FeatureCodes.RGREENC]
      },
      {
        name: 'Site Transfers',
        path: '/seedtracker/receiving/site-transfers',
        icon: '',
        features: [FeatureCodes.RSITETR]
      }
    ]
  },
  {
    name: 'Bin Status',
    path: '',
    icon: 'fa-battery-3',
    features: ['RBINS'],
    subMenuItems: [
      { name: 'Internal Bins', path: '/seedtracker/bin-status-internal', icon: '' },
      { name: 'Grower Bins', path: '/seedtracker/bin-status-grower', icon: '' }
    ]
  },
  {
    name: 'Shakeouts',
    path: '/seedtracker/shake-outs',
    icon: 'fa-calculator',
    features: [FeatureCodes.RLOTSO, FeatureCodes.WLOTSO, FeatureCodes.NEWSO]
  },
  {
    name: 'IMQC',
    path: '',
    icon: 'fa-dropbox',
    subMenuItems: [
      {
        name: 'Seed Needs',
        path: '/seedtracker/seed-needs',
        icon: '',
        features: [FeatureCodes.RSN]
      },
      {
        name: 'Run Planning',
        path: '/seedtracker/run-planning',
        icon: '',
        features: [
          FeatureCodes.RRUNPLAN,
          FeatureCodes.S_R_RUNPLAN,
          FeatureCodes.WT_R_RUNPLAN,
          FeatureCodes.S_R_WSRUN,
          FeatureCodes.WT_R_WSRUN
        ]
      },
      {
        name: 'Conditioning',
        path: '/seedtracker/conditioning',
        icon: '',
        features: [FeatureCodes.VCR]
      },
      {
        name: 'Run Sourcing',
        path: '/seedtracker/run-sourcing',
        icon: '',
        features: [FeatureCodes.WRUNS]
      }
    ]
  },
  {
    name: 'Reports',
    path: '/seedtracker/seedtracker-reports',
    icon: 'fa-print',
    groups: [GroupCodes.RPT]
  },
  {
    name: 'Admin',
    path: '',
    icon: 'fa-connectdevelop',
    subMenuItems: [
      {
        name: 'Gradeout Configurations',
        path: '/seedtracker/grade-outs',
        icon: '',
        features: [FeatureCodes.RGOCONF, FeatureCodes.WGOCONF]
      },
      {
        name: 'Harvest Lot Assignments',
        path: '/seedtracker/administration/lot-assignment',
        icon: '',
        features: [FeatureCodes.RLOTASS]
      },
      {
        name: 'QA Management',
        path: '/seedtracker/administration/qa-management',
        icon: '',
        features: [FeatureCodes.RCORNQA]
      },
      {
        name: 'Site Management',
        path: '/seedtracker/administration/site-management',
        icon: '',
        features: [FeatureCodes.RSITEMGMT]
      },
      {
        name: 'Tower Access',
        path: '/seedtracker/administration/tower-access',
        icon: '',
        features: [FeatureCodes.WTOWER]
      },
      {
        name: 'Notification Groups',
        path: '/seedtracker/administration/notification-groups',
        icon: '',
        features: [FeatureCodes.WNG]
      }
    ]
  }
];

const userManagementMenu: IMenuItem[] = [
  { name: 'CAPPS', path: '/userMgmt/capps', icon: 'fa-seedling', features: [FeatureCodes.WUSR] },
  {
    name: 'Seed Tracker',
    path: '/userMgmt/st',
    icon: 'fa-tractor',
    features: [FeatureCodes.WSTUSERS]
  },
  {
    name: 'Lab Tracker',
    path: '/userMgmt/lab',
    icon: 'fa-flask',
    features: [FeatureCodes.WLABUSERS]
  }
];

export function getBreakpoints(): IBreakpointsObject {
  return {
    xl: {
      id: EBreakpoints.XL,
      mediaQuery: 'screen and (min-width: 1920px)',
      applied: false
    },
    'gt-lg': {
      id: EBreakpoints.GT_LG,
      mediaQuery: 'screen and (min-width: 1920px)',
      applied: false
    },
    lg: {
      id: EBreakpoints.LG,
      mediaQuery: 'screen and (min-width: 1280px) and (max-width: 1919px)',
      applied: false
    },
    'gt-md': {
      id: EBreakpoints.GT_MD,
      mediaQuery: 'screen and (min-width: 1280px)',
      applied: false
    },
    md: {
      id: EBreakpoints.MD,
      mediaQuery: 'screen and (min-width: 960px) and (max-width: 1279px)',
      applied: false
    },
    'lt-md': {
      id: EBreakpoints.LT_MD,
      mediaQuery: 'screen and (max-width: 960px)',
      applied: false
    },
    'gt-sm': {
      id: EBreakpoints.GT_SM,
      mediaQuery: 'screen and (min-width: 960px)',
      applied: false
    },
    'ipad-landscape': {
      id: EBreakpoints.IPAD_LANDSCAPE,
      mediaQuery:
        'screen and (min-width: 768px)' +
        ' and (max-width: 1024px) and (orientation: landscape)' +
        ' and (-webkit-min-device-pixel-ratio: 2)',
      applied: false
    },
    'ipad-pro': {
      id: EBreakpoints.IPAD_PRO,
      mediaQuery:
        '(min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5)',
      applied: false
    },
    'ipad-pro-landscape': {
      id: EBreakpoints.IPAD_PRO_LANDSCAPE,
      mediaQuery:
        '(min-width: 1024px) and (max-device-width: 1366px)' +
        ' and (max-height: 1366px) and (orientation: landscape)' +
        ' and (-webkit-min-device-pixel-ratio: 1.5)',
      applied: false
    },
    sm: {
      id: EBreakpoints.SM,
      mediaQuery: 'screen and (min-width: 600px) and (max-width: 959px)',
      applied: false
    },
    'gt-xs': {
      id: EBreakpoints.GT_XS,
      mediaQuery: 'screen and (min-width: 600px)',
      applied: false
    },
    'xs-max': {
      id: EBreakpoints.XS_MAX,
      mediaQuery:
        'screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3)',
      applied: false
    },
    xs: {
      id: EBreakpoints.XS,
      mediaQuery: 'screen and (max-width: 599px)',
      applied: false
    },
    'xs-landscape': {
      id: EBreakpoints.XS_LANDSCAPE,
      mediaQuery:
        'screen and (min-width: 640px) and (max-width: 812px) and (orientation: landscape)',
      applied: false
    },
    'xxs-landscape': {
      id: EBreakpoints.XXS_LANDSCAPE,
      mediaQuery:
        'screen and (min-width: 569px) and (max-width: 667px) and (orientation: landscape)',
      applied: false
    },
    'xxxs-landscape': {
      id: EBreakpoints.XXXS_LANDSCAPE,
      mediaQuery:
        'screen and (min-width: 558px) and (max-width: 568px) and (orientation: landscape)',
      applied: false
    },
    xxs: {
      id: EBreakpoints.XXS,
      mediaQuery: 'screen and (max-width: 375px)',
      applied: false
    },
    xxxs: {
      id: EBreakpoints.XXS,
      mediaQuery: 'screen and (max-width: 320px)',
      applied: false
    },
    'tw-sm': {
      id: EBreakpoints.TW_SMALL,
      mediaQuery: '(min-width: 640px)',
      applied: false
    },
    'tw-md': {
      id: EBreakpoints.TW_MEDIUM,
      mediaQuery: '(min-width: 768px)',
      applied: false
    },
    'tw-lg': {
      id: EBreakpoints.TW_LARGE,
      mediaQuery: '(min-width: 1024px)',
      applied: false
    },
    'tw-xl': {
      id: EBreakpoints.TW_XL,
      mediaQuery: '(min-width: 1280px)',
      applied: false
    },
    'tw-xxl': {
      id: EBreakpoints.TW_XXL,
      mediaQuery: '(min-width: 1536px)',
      applied: false
    }
  };
}

export function getLayouts(): ILayoutMap {
  return {
    [EAppModule.capps]: {
      basePath: '/capps',
      header: {
        logoPath: 'assets/images/capps-logo.png'
      },
      sidebar: {
        brandPath: 'assets/images/tractor2.svg',
        mobileLogoPath: 'assets/images/capps-logo.svg',
        menu: []
      }
    },
    [EAppModule.seedtracker]: {
      basePath: '/seedtracker',
      header: {
        logoPath: 'assets/images/st-logo.png'
      },
      sidebar: {
        brandPath: 'assets/images/st-logo-alt.png',
        mobileLogoPath: 'assets/images/st-logo.svg',
        menu: []
      }
    },
    [EAppModule.labtracker]: {
      basePath: '/labtracker',
      header: {
        logoPath: 'assets/images/labtracker-logo.svg'
      },
      sidebar: {
        brandPath: 'assets/images/labtracker-icon.png',
        mobileLogoPath: 'assets/images/labtracker-logo.svg',
        menu: []
      }
    },
    [EAppModule.documentation]: {
      basePath: '/documentation',
      header: {
        logoPath: 'assets/images/becks-logo.png'
      },
      sidebar: {
        brandPath: 'assets/images/ic_description_white_48dp_2x.png',
        mobileLogoPath: 'assets/images/ic_description_white_48dp_2x.png',
        menu: []
      }
    },
    [EAppModule.usermanagement]: {
      basePath: '/userMgmt',
      header: {
        logoPath: 'assets/images/becks-logo.png'
      },
      sidebar: {
        brandPath: 'assets/images/st-logo-alt.png',
        mobileLogoPath: 'assets/images/st-logo-alt.png',
        menu: []
      }
    }
  };
}

export function getMenus(): IMenuMap {
  return {
    [EAppModule.capps]: cappsMenu,
    [EAppModule.seedtracker]: seedtrackerMenu,
    [EAppModule.labtracker]: labTrackerMenu,
    [EAppModule.documentation]: [],
    [EAppModule.usermanagement]: userManagementMenu
  };
}

export function getOpenAppModules(): EAppModule[] {
  return [EAppModule.documentation];
}

export function getFeatures(): ILayoutFeaturesMap {
  return {
    [EAppModule.capps]: {
      [ELayoutFeatures.acreageOverview]: true
    },
    [EAppModule.documentation]: {},
    [EAppModule.usermanagement]: {}
  };
}
