import * as _ from 'lodash';
import { INotification } from 'src/app/shared/models/data-model/notifications.interface';
import { NotificationsActions, NotificationsActionTypes } from '../actions/notifications.actions';

export interface NotificationsState {
  model: {
    notificationEntities: { [key: number]: INotification };
  };
  viewModel: {
    showNotifications: boolean;
    showAnimation: boolean;
    notificationsLoaded: boolean;
    notificationsLoading: boolean;
    notificationsErred: boolean;
    socket: {
      initializing: boolean;
      initialized: boolean;
      erred: boolean;
    };
  };
}

export const initialState: NotificationsState = {
  model: {
    notificationEntities: {}
  },
  viewModel: {
    showNotifications: false,
    showAnimation: false,
    notificationsLoaded: false,
    notificationsLoading: false,
    notificationsErred: false,
    socket: {
      initializing: false,
      initialized: false,
      erred: false
    }
  }
};

export function reducer(state = initialState, action: NotificationsActions): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.InitializeNotificationsSocketConnectionAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          socket: {
            ...state.viewModel.socket,
            initialized: false,
            initializing: true,
            erred: false
          }
        }
      };
    }
    case NotificationsActionTypes.InitializeNotificationsSocketConnectionFailAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          socket: {
            ...state.viewModel.socket,
            initializing: false,
            initialized: false,
            erred: false
          }
        }
      };
    }
    case NotificationsActionTypes.InitializeNotificationsSocketConnectionSuccessAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          socket: {
            ...state.viewModel.socket,
            initialized: true,
            initializing: false,
            erred: false
          }
        }
      };
    }

    case NotificationsActionTypes.ShowNotificationsAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          showNotifications: true
        }
      };
    }
    case NotificationsActionTypes.HideNotificationsAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          showNotifications: false
        }
      };
    }
    case NotificationsActionTypes.ToggleNotificationsAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          showNotifications: !state.viewModel.showNotifications
        }
      };
    }

    case NotificationsActionTypes.StartNotificationBadgeAnimationAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          showAnimation: true
        }
      };
    }
    case NotificationsActionTypes.StopNotificationBadgeAnimationAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          showAnimation: false
        }
      };
    }

    // notification loading
    case NotificationsActionTypes.LoadNotificationsAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          notificationsLoaded: false,
          notificationsLoading: true,
          notificationsErred: false
        }
      };
    }
    case NotificationsActionTypes.LoadNotificationsFailAction: {
      return {
        ...state,
        model: {
          ...state.model,
          notificationEntities: {}
        },
        viewModel: {
          ...state.viewModel,
          notificationsLoaded: false,
          notificationsLoading: false,
          notificationsErred: false
        }
      };
    }
    case NotificationsActionTypes.LoadNotificationsSuccessAction: {
      const notifications = action.payload;

      return {
        ...state,
        model: {
          ...state.model,
          notificationEntities: _.keyBy(notifications, 'ID')
        },
        viewModel: {
          ...state.viewModel,
          notificationsLoaded: true,
          notificationsLoading: false,
          notificationsErred: false
        }
      };
    }

    case NotificationsActionTypes.AddNotificationToEntitiesAction: {
      const notification = action.payload;
      const notificationEntities = _.cloneDeep(state.model.notificationEntities);
      // add new notification to the list
      notificationEntities[notification.ID] = notification;

      return {
        ...state,
        model: {
          ...state.model,
          notificationEntities
        }
      };
    }

    // single and batch updates
    case NotificationsActionTypes.SetSeenNotificationSuccessAction: {
      const notification = action.payload;

      return {
        ...state,
        model: {
          ...state.model,
          notificationEntities: {
            ...state.model.notificationEntities,
            [notification.ID]: {
              ...notification,
              notificationSeen: true
            }
          }
        }
      };
    }
    case NotificationsActionTypes.SetClearNotificationSuccessAction: {
      const notification = action.payload;

      const notificationEntities = _.cloneDeep(state.model.notificationEntities);
      delete notificationEntities[notification.ID];

      return {
        ...state,
        model: {
          ...state.model,
          notificationEntities
        }
      };
    }
    case NotificationsActionTypes.SetSeenNotificationBatchSuccessAction: {
      const notificationEntities = _.cloneDeep(state.model.notificationEntities);
      // mark all notifications as seen
      _.forEach(notificationEntities, (notification) => (notification.notificationSeen = true));

      return {
        ...state,
        model: {
          ...state.model,
          notificationEntities
        }
      };
    }
    case NotificationsActionTypes.SetClearNotificationBatchSuccessAction: {
      return {
        ...state,
        model: {
          ...state.model,
          notificationEntities: {}
        }
      };
    }

    case NotificationsActionTypes.ResetNotificationsStateAction: {
      return initialState;
    }

    default:
      return state;
  }
}

export const getNotificationsSocketInitialized = (state: NotificationsState) =>
  state.viewModel.socket.initialized;
export const getNotificationsSocketInitializing = (state: NotificationsState) =>
  state.viewModel.socket.initializing;
export const getNotificationsSocketErred = (state: NotificationsState) =>
  state.viewModel.socket.erred;
export const getNotificationEntities = (state: NotificationsState) =>
  state.model.notificationEntities;
export const getNotificationsShowNotifications = (state: NotificationsState) =>
  state.viewModel.showNotifications;
export const getNotificationsShowAnimation = (state: NotificationsState) =>
  state.viewModel.showAnimation;
export const getNotificationsLoaded = (state: NotificationsState) =>
  state.viewModel.notificationsLoaded;
export const getNotificationsLoading = (state: NotificationsState) =>
  state.viewModel.notificationsLoading;
export const getNotificationsErred = (state: NotificationsState) =>
  state.viewModel.notificationsErred;
