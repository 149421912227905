import { IPSUser } from '../../../../shared/models/data-model/user.interface';
import { createAction, props } from '@ngrx/store';

export const loadUsers = createAction('[Users Guard] Load Users');
export const loadUsersSuccess = createAction(
  '[Production Suite API] Load Users Success',
  props<{ users: IPSUser[] }>()
);
export const loadUsersFail = createAction(
  '[Production Suite API] Load Users Fail',
  props<{ error: any }>()
);
