import { createSelector } from '@ngrx/store';

import * as fromGlobal from '../reducers';

export const getGrowerState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.growers
);

export const getGrowersLoaded = createSelector(
  getGrowerState,
  (state) => state.viewModel.growersLoaded
);

export const getGrowersError = createSelector(
  getGrowerState,
  (state) => state.viewModel.growersError
);

export const getGrowers = createSelector(getGrowerState, (state) => {
  const { ids, entities } = state.model.growers;
  return (ids as number[]).map((id) => entities[id]);
});

export const getGrowerEntities = createSelector(
  getGrowerState,
  (state) => state.model.growers.entities
);
