import {
  Data,
  Params,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  RouterState
} from '@angular/router';

import { Injectable } from '@angular/core';
import { createFeatureSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import * as _ from 'lodash';

export interface CustomRouterState {
  url: string;
  urlWithoutQueryParams: string;
  queryParams: Params;
  params: Params;
  allParams: Params[];
  data: Data;
  allData: Data[];
}

export const getRouterStateUrl = (state: RouterState) => state.root.url;
export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<CustomRouterState>
>('routerReducer');

@Injectable()
export class CustomRouteSerializer implements fromRouter.RouterStateSerializer<CustomRouterState> {
  serialize(routerState: RouterStateSnapshot): CustomRouterState {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    const urlWithoutQueryParams = url.indexOf('?') > 0 ? url.substring(0, url.indexOf('?')) : url;

    // traverse router state tree to take a few properties and bind to our own ngrx state tree
    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    // get the params of the current route only
    const { params, data } = state;

    // traverse the router state tree and compile ALL
    // of the parameters into one object to be accessible by the store
    const allParams: any[] = [{ ...params }];
    const allData: any[] = [{ ...data }];

    while (state.parent) {
      state = state.parent;
      // get ancestor's params
      if (Object.keys(state.params).length > 0 && !_.find(allParams, state.params)) {
        allParams.push(state.params);
      }
      // get ancestor's data
      if (Object.keys(state.data).length > 0 && !_.find(allData, state.data)) {
        allData.push(state.data);
      }
    }

    return { url, urlWithoutQueryParams, queryParams, params, allParams, data, allData };
  }
}
