import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BecksSnackbarComponent } from 'src/app/library/snackbar/components/becks-snackbar.component';
import { SnackbarService } from './services/snackbar.service';

@NgModule({
  declarations: [BecksSnackbarComponent],
  imports: [CommonModule, MatSnackBarModule, MatIconModule]
})
export class SnackbarModule {
  static forRoot(): ModuleWithProviders<SnackbarModule> {
    return {
      ngModule: SnackbarModule,
      providers: [SnackbarService]
    };
  }
}
