import * as _ from 'lodash';
import { getFeatures, getLayouts } from 'src/app/shared/helpers/layout.helper';
import {
  ILayout,
  ILayoutFeaturesMap,
  ILayoutMap,
  ITitleBar
} from 'src/app/shared/models/data-model/layout.interface';
import { LayoutActionTypes, LayoutActions } from '../actions';

export interface LayoutState {
  model: {
    userLayout: ILayoutMap;
    titleBar: ITitleBar;
    features: ILayoutFeaturesMap;
  };
  viewModel: {
    userLayoutLoaded: boolean;
    userLayoutLoading: boolean;
    isSidebarOpen: boolean;
    titleBarQuickSearchValue: string;
  };
}

export const initialState: LayoutState = {
  model: {
    userLayout: getLayouts(),
    titleBar: {
      title: '',
      subTitle: '',
      headerTag: ''
    },
    features: getFeatures()
  },
  viewModel: {
    userLayoutLoaded: false,
    userLayoutLoading: false,
    isSidebarOpen: true,
    titleBarQuickSearchValue: ''
  }
};

export function reducer(state = initialState, action: LayoutActions): LayoutState {
  switch (action.type) {
    case LayoutActionTypes.SetUserLayoutAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          userLayoutLoading: true
        }
      };
    }
    case LayoutActionTypes.SetUserLayoutCompleteAction: {
      const userLayout = action.payload;

      return {
        ...state,
        model: {
          ...state.model,
          userLayout
        },
        viewModel: {
          ...state.viewModel,
          userLayoutLoading: false,
          userLayoutLoaded: true
        }
      };
    }

    case LayoutActionTypes.ShowSidebarAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isSidebarOpen: true
        }
      };
    }
    case LayoutActionTypes.HideSidebarAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isSidebarOpen: false
        }
      };
    }
    case LayoutActionTypes.ToggleSidebarAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isSidebarOpen: !state.viewModel.isSidebarOpen
        }
      };
    }
    case LayoutActionTypes.UpdateUserSidebarMenuAction: {
      const { menu, appModule } = action.payload;

      const userLayoutUpdate: ILayout = _.cloneDeep(state.model.userLayout[appModule]);
      userLayoutUpdate.sidebar.menu = menu;

      return {
        ...state,
        model: {
          ...state.model,
          userLayout: {
            ...state.model.userLayout,
            [appModule]: userLayoutUpdate
          }
        }
      };
    }

    case LayoutActionTypes.UpdateTitleBarAction: {
      const titleBar = action.payload;

      return {
        ...state,
        model: {
          ...state.model,
          titleBar
        }
      };
    }

    case LayoutActionTypes.ResetUserLayoutAction: {
      return initialState;
    }

    case LayoutActionTypes.TitleBarQuickSearchValueUpdatedAction: {
      const { searchValue } = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          titleBarQuickSearchValue: searchValue
        }
      };
    }

    default:
      return state;
  }
}

export const getLayoutUserLayout = (state: LayoutState) => state.model.userLayout;
export const getLayoutTitleBar = (state: LayoutState) => state.model.titleBar;
export const getLayoutFeatures = (state: LayoutState) => state.model.features;
export const getLayoutUserLayoutLoaded = (state: LayoutState) => state.viewModel.userLayoutLoaded;
export const getLayoutUserLayoutLoading = (state: LayoutState) => state.viewModel.userLayoutLoading;
export const getLayoutIsSidebarOpen = (state: LayoutState) => state.viewModel.isSidebarOpen;
export const getLayoutTitleBarQuickSearchValue = (state: LayoutState) =>
  state.viewModel.titleBarQuickSearchValue;
