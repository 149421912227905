import { ModuleWithProviders, NgModule } from '@angular/core';

import { SocketIoService } from 'src/app/sockets/socket.io.service';
import { SocketIoConfig } from './socket-io.model';
import { SOCKET_CONFIG_TOKEN } from './socket-io.token';

@NgModule({})
export class SocketIoModule {
  static forRoot(config: SocketIoConfig): ModuleWithProviders<SocketIoModule> {
    return {
      ngModule: SocketIoModule,
      providers: [{ provide: SOCKET_CONFIG_TOKEN, useValue: config }, SocketIoService]
    };
  }
}
