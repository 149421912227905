import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromAcreageOverview from './acreage-overview.reducer';
import * as fromLayout from './layout.reducer';
import * as fromLots from './lots.reducer';
import * as fromUser from './user.reducer';
import * as fromNotifications from './notifications.reducer';
import * as fromSystemInfo from './system-info.reducer';
import * as fromProducts from './products.reducer';
import * as fromProductionYears from './production-year.reducer';
import * as fromRegions from './regions.reducer';
import * as fromSites from './sites.reducer';
import * as fromGrowers from './growers.reducer';
import * as fromUsers from './users.reducer';
import * as fromAppDefaults from './app-defaults.reducer';
// create global state
export interface GlobalState {
  acreageOverview: fromAcreageOverview.AcreageOverviewState;
  growers: fromGrowers.GrowersState;
  layout: fromLayout.LayoutState;
  lots: fromLots.LotsState;
  user: fromUser.UserState;
  notifications: fromNotifications.NotificationsState;
  systemInfo: fromSystemInfo.SystemInfoState;
  products: fromProducts.ProductsState;
  productionYears: fromProductionYears.ProductionYearState;
  sites: fromSites.SitesState;
  regions: fromRegions.RegionsState;
  users: fromUsers.UsersState;
  appDefaults: fromAppDefaults.AppDefaultsState;
}

// map metal-level reducers that do not have their own module but
//  need to be part of state
export const reducers: ActionReducerMap<GlobalState> = {
  acreageOverview: fromAcreageOverview.reducer,
  growers: fromGrowers.reducer,
  layout: fromLayout.reducer,
  lots: fromLots.reducer,
  notifications: fromNotifications.reducer,
  productionYears: fromProductionYears.reducer,
  products: fromProducts.reducer,
  regions: fromRegions.reducer,
  sites: fromSites.reducer,
  systemInfo: fromSystemInfo.reducer,
  user: fromUser.reducer,
  users: fromUsers.usersReducer,
  appDefaults: fromAppDefaults.appDefaultsReducer
};

export const getGlobalState = createFeatureSelector<GlobalState>('global');
