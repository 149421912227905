import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { UserService } from 'src/app/core/services/user.service';
import {
  ELayoutFeatures,
  ILayoutFeatures
} from 'src/app/shared/models/data-model/layout.interface';
import { IRegion, ISite } from 'src/app/shared/models/data-model/region.interface';
import { IUserAppPreferences } from 'src/app/shared/models/data-model/user.interface';
import * as fromLayout from './layout.selector';
import * as fromRegions from './regions.selector';
import * as fromSites from './sites.selector';
import * as fromSystemInfo from './system-info.selector';
import * as fromUser from './user.selector';

export * from './acreage-overview.selector';
export * from './app-defaults.selectors';
export * from './layout.selector';
export * from './lots.selector';
export * from './notifications.selector';
export * from './production-year.selector';
export * from './products.selector';
export * from './regions.selector';
export * from './sites.selector';
export * from './system-info.selector';
export * from './user.selector';
export * from './users.selector';

/**
 * Root Derived Selectors
 * These selectors depend on more than one individual selector file
 * This avoids circular dependencies
 */
export const globalDataLoaded = createSelector(fromUser.getUserLoaded, (userLoaded) => {
  return userLoaded;
});

export const getUserFeatureGroups = createSelector(
  fromUser.getUserPermissions,
  fromSystemInfo.getSystemInfoActiveAppModule,
  (permissions, activeAppModule) => UserService.getUserFeatureGroups(permissions, activeAppModule)
);

export const getUserHasFeatureGroups = (featureGroup: string) =>
  createSelector(
    fromUser.getUserPermissions,
    fromSystemInfo.getSystemInfoActiveAppModule,
    (permissions, activeAppModule) =>
      UserService.hasRoleGroup(permissions, activeAppModule, featureGroup)
  );

export const getUserFeatureCodes = createSelector(
  fromUser.getUserPermissions,
  fromSystemInfo.getSystemInfoActiveAppModule,
  (permissions, activeAppModule) => UserService.getUserFeatureCodes(permissions, activeAppModule)
);

export const getUserHasFeatureCode = (featureCode: string) =>
  createSelector(
    fromUser.getUserPermissions,
    fromSystemInfo.getSystemInfoActiveAppModule,
    (permissions, activeAppModule) => UserService.hasRole(permissions, activeAppModule, featureCode)
  );

export const getAppModuleUserLayout = createSelector(
  fromSystemInfo.getSystemInfoActiveAppModule,
  fromLayout.getLayoutUserLayout,
  (activeAppModule, layoutMap) => {
    // if no active app module is present
    if (!activeAppModule || !layoutMap || !(activeAppModule in layoutMap)) {
      return null;
    }

    return layoutMap[activeAppModule];
  }
);

export const getAppModuleUserPreferences: MemoizedSelector<
  object,
  IUserAppPreferences
> = createSelector(
  fromSystemInfo.getSystemInfoActiveAppModule,
  fromUser.getUserPreferences,
  (activeAppModule, preferences) => {
    // if no active app module is present
    if (!activeAppModule || !preferences || !(activeAppModule in preferences)) {
      return {};
    }

    return preferences[activeAppModule];
  }
);

export const getAppModuleUserPreferenceByName = (
  name: string
): MemoizedSelector<object, any | null> =>
  createSelector(getAppModuleUserPreferences, (preferences) => {
    if (!name || !(name in preferences)) {
      return null;
    }

    return preferences[name];
  });

export const getAppModuleUserPreferenceTypes: MemoizedSelector<object, string[]> = createSelector(
  fromSystemInfo.getSystemInfoActiveAppModule,
  fromUser.getUserPreferenceTypes,
  (activeAppModule, preferenceTypes) => {
    // if no active app module is present
    if (!activeAppModule || !preferenceTypes || !(activeAppModule in preferenceTypes)) {
      return [];
    }

    return preferenceTypes[activeAppModule];
  }
);

export const getAppModuleLayoutFeatures: MemoizedSelector<object, ILayoutFeatures> = createSelector(
  fromSystemInfo.getSystemInfoActiveAppModule,
  fromLayout.getLayoutFeatures,
  (activeAppModule, layoutFeatures) => {
    if (!activeAppModule || !(activeAppModule in layoutFeatures)) {
      return {};
    }

    return layoutFeatures[activeAppModule];
  }
);

export const getAppModuleLayoutFeaturesEnabledByName = (props: { feature: ELayoutFeatures }) =>
  createSelector(getAppModuleLayoutFeatures, (appFeatures: ILayoutFeatures) => {
    if (!(props.feature in appFeatures)) {
      return false;
    }

    return appFeatures[props.feature];
  });

export const getAllRegionsWithSites: MemoizedSelector<
  object,
  (IRegion & { sites: ISite[] })[]
> = createSelector(fromRegions.getAllRegions, fromSites.getAllSites, (regions, sites) => {
  const groupedSites = _.groupBy(sites, 'regionID');

  return regions.map((region) => {
    return {
      ...region,
      sites: groupedSites[region.ID] ?? []
    };
  });
});

export const getUserHasAcreageOverviewViewPermission = createSelector(
  fromUser.getUserPermissions,
  fromSystemInfo.getSystemInfoActiveAppModule,
  (permissions, appModule) => UserService.hasRole(permissions, appModule, 'RAO')
);
