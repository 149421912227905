import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Data,
  Params,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import * as fromActions from 'src/app/root-store/global-store/store/actions';
import * as fromReducers from 'src/app/root-store/global-store/store/reducers';
import * as fromSelectors from 'src/app/root-store/global-store/store/selectors';
import { UserService } from '../../services/user.service';

@Injectable()
export class CropAuthorizationGuard implements CanActivate, CanActivateChild {
  constructor(private _store: Store<fromReducers.GlobalState>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkStore(next.params, next.data).pipe(
      // returns observable of true if things have gone correctly
      switchMap((isAuthorized) => of(isAuthorized)),
      // else returns observable of false if things have gone awry
      catchError(() => of(false))
    );
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivateChild(next, state);
  }

  checkStore(params: Params | undefined, data: Data | undefined): Observable<boolean> {
    // there is not data object
    if (!params || !params.cropCode || !data || !data.crops) {
      return of(true);
    }

    const cropCode: string = params.cropCode;

    // no features are required
    const roles: string[] = data.crops[cropCode];
    if (!Array.isArray(roles) || roles.length === 0) {
      return of(true);
    }

    return this._store.select(fromSelectors.getUserPermissions).pipe(
      withLatestFrom(this._store.select(fromSelectors.getSystemInfoActiveAppModule)),
      map(([permissions, activeAppModule]) => {
        const isAuthorized = UserService.hasFeatureFromList(permissions, activeAppModule, roles);

        if (!isAuthorized) {
          this._store.dispatch(new fromActions.UserNotAuthorized());
        }

        return isAuthorized;
      }),
      take(1)
    );
  }
}
