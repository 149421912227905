import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProductsService } from 'src/app/core/services/products.service';
import { SnackbarService } from 'src/app/library/snackbar';
import { ICornProduct } from 'src/app/shared/models/data-model/products.interface';
import * as fromActions from '../actions';
import * as fromReducers from '../reducers';
import * as fromSelectors from '../selectors';
import { ProductsActionTypes } from './../actions/products.actions';

@Injectable()
export class ProductsEffects {
  constructor(
    private _actions$: Actions,
    private _productsService: ProductsService,
    private _snackbarService: SnackbarService,
    private _store: Store<fromReducers.GlobalState>
  ) {}

  reloadProductsOnProductionYearChange$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        fromActions.ProductionYearActionTypes.UpdateSelectedProductionYear,
        fromActions.ProductionYearActionTypes.LoadProductionYearsSuccess
      ),
      switchMap(() => of(new fromActions.FetchAllProductsAction()))
    )
  );

  loadCropFamilies$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductsActionTypes.LoadCropFamilies),
      switchMap(() => {
        return this._productsService.fetchCropFamilies().pipe(
          map((response) => new fromActions.LoadCropFamiliesSuccessAction(response)),
          catchError((error) => of(new fromActions.LoadCropFamiliesFailAction(error)))
        );
      })
    )
  );

  loadCropFamiliesSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductsActionTypes.LoadCropFamiliesSuccess),
      switchMap(() => of(new fromActions.FetchAllProductsAction()))
    )
  );

  loadPackageTypes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductsActionTypes.LoadPackageTypes),
      switchMap(() => {
        return this._productsService.fetchPackageTypes().pipe(
          map((response) => new fromActions.LoadPackageTypesSuccessAction(response)),
          catchError((error) => of(new fromActions.LoadPackageTypesFailAction(error)))
        );
      })
    )
  );

  loadSeedTreatments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductsActionTypes.LoadSeedTreatments),
      switchMap(() => {
        return this._productsService.fetchSeedTreatments().pipe(
          map((response) => new fromActions.LoadSeedTreatmentsSuccessAction(response)),
          catchError((error) => of(new fromActions.LoadSeedTreatmentsFailAction(error)))
        );
      })
    )
  );

  fetchAllProducts$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductsActionTypes.FetchAllProducts),
      withLatestFrom(
        this._store.select(fromSelectors.getProductionYearSelectedYear),
        this._store.select(fromSelectors.getProductionYearsLoaded),
        this._store.select(fromSelectors.getCropFamiliesLoaded)
      ),
      filter(
        ([action, selectedYear, productionYearsLoaded, cropsLoaded]) =>
          productionYearsLoaded && cropsLoaded
      ),
      mergeMap(([action, selectedYear]) => {
        const actions: Action[] = [];

        if (selectedYear) {
          actions.push(
            new fromActions.LoadSoybeanProductsAction({
              cropCode: 'SOY',
              productionYear: selectedYear.ID
            })
          );
          actions.push(
            new fromActions.LoadCornProductsAction({
              cropCode: 'CORN',
              productionYear: selectedYear.ID
            })
          );
          actions.push(
            new fromActions.LoadWheatProductsAction({
              cropCode: 'WHEAT',
              productionYear: selectedYear.ID
            })
          );
        }
        return actions;
      })
    )
  );

  loadSoybeanProducts$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductsActionTypes.LoadSoybeanProducts),
      map((action: fromActions.LoadSoybeanProductsAction) => action.payload),
      switchMap((payload) => {
        return this._productsService.fetchProducts(payload).pipe(
          map((response) => {
            return new fromActions.LoadSoybeanProductsSuccessAction(response);
          }),
          catchError((error) => {
            this._snackbarService.error('Error Fetching Soybean Products');
            return of(new fromActions.LoadSoybeanProductsFailAction(error));
          })
        );
      })
    )
  );

  loadCornProducts$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductsActionTypes.LoadCornProducts),
      map((action: fromActions.LoadCornProductsAction) => action.payload),
      switchMap((payload) => {
        return this._productsService.fetchProducts(payload).pipe(
          map((response) => {
            const products = response as ICornProduct[];
            return new fromActions.LoadCornProductsSuccessAction(products);
          }),
          catchError((error) => {
            this._snackbarService.error('Error Fetching Corn Products');
            return of(new fromActions.LoadCornProductsFailAction(error));
          })
        );
      })
    )
  );

  loadWheatProducts$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ProductsActionTypes.LoadWheatProducts),
      map((action: fromActions.LoadWheatProductsAction) => action.payload),
      switchMap((payload) => {
        return this._productsService.fetchProducts(payload).pipe(
          map((response) => {
            return new fromActions.LoadWheatProductsSuccessAction(response);
          }),
          catchError((error) => {
            this._snackbarService.error('Error Fetching Wheat Products');
            return of(new fromActions.LoadWheatProductsFailAction(error));
          })
        );
      })
    )
  );
}
