import { sanitizeForm } from 'src/app/shared/helpers/sanitizers.helper';
import { VarietyForecastState } from './../reducers/variety-forecast.reducer';

export function sanitize(state: VarietyForecastState): {} | undefined {
  if (state && state.model && state.viewModel) {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        productsForm: sanitizeForm(state.viewModel.productsForm)
      }
    };
  } else {
    return;
  }
}
