import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAcreageOverview } from '../../shared/models/data-model/acreage-overview.interface';

@Injectable()
export class GlobalService {
  constructor(private _apiService: ApiService) {}

  fetchAcreageOverview(productionYearID: number): Observable<IAcreageOverview> {
    return this._apiService
      .get(`/capps/forecast/production/acreageOverview?ProductionYearID=${productionYearID}`)
      .pipe(map((body) => body.content));
  }
}
