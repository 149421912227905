import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { AppEvent } from 'src/app/core/classes/app-event.class';
import { IProductionYear } from 'src/app/shared/models/data-model/production-year.interface';
import { ApiService } from '../../core/services/api.service';

@Injectable()
export class ProductionYearService {
  _productionYear$: Observable<{
    years: IProductionYear[];
    default: IProductionYear;
    current: IProductionYear;
  }>;

  public onProdYearChanged$ = new AppEvent<{
    years: IProductionYear[];
    default: IProductionYear;
    current: IProductionYear;
  }>();

  constructor(private _apiService: ApiService) {
    this._productionYear$ = this.onProdYearChanged$.asObservable();
  }

  fetchYears(): Observable<IProductionYear[]> {
    return this._apiService.get('/shared/prodYears');
  }

  getCurrentYear(): Observable<IProductionYear> {
    return this._productionYear$.pipe(pluck('current'));
  }
}
