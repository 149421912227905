import { AppAccessGuard } from './app-access.guard';
import { AuthenticationGuard } from './authentication.guard';
import { AuthorizationGuard } from './authorization.guard';
import { CropAuthorizationGuard } from './crop-authorization.guard';
import { PermissionsGuard } from './permissions.guard';

export const guards = [
  AuthorizationGuard,
  PermissionsGuard,
  AppAccessGuard,
  AuthenticationGuard,
  CropAuthorizationGuard
];
