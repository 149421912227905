<div class="logout">
  <div class="logout__container">
    <div class="logout__container-logo"></div>
    <div *ngIf="!!error" class="logout__container-error">
      <i class="icon"></i>
      {{ error }}
    </div>
    <h3 *ngIf="!error" class="logout__container-title">You are being logged out...</h3>
    <p class="logout__container-content">
      If your browser does not redirect you back, please
      <a (click)="handleClickHere()">click here</a>
      to continue.
    </p>
  </div>
</div>
