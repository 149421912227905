import { createAction, props } from '@ngrx/store';

import { IGRWGrower } from 'src/app/shared/models/data-model/growers-module.interface';

export const loadGrowersFromGuard = createAction('[Grower Guard] Load Growers');
export const loadGrowersFromGrowerManagementPage = createAction(
  '[Grower Management Page: Import Growers Button] Load Growers'
);

export const loadGrowersSuccess = createAction(
  '[Production Suite API] Load Growers Success',
  props<{ growers: IGRWGrower[] }>()
);

export const loadGrowersFailure = createAction(
  '[Production Suite API] Load Growers Failure',
  props<{ error: any }>()
);

export const updateGrower = createAction(
  '[Grower Detail Page] Update Grower',
  props<{ grower: IGRWGrower }>()
);

export const updateGrowerSuccess = createAction(
  '[Production Suite API] Update Grower Success',
  props<{ grower: IGRWGrower }>()
);

export const updateGrowerFailure = createAction(
  '[Production Suite API] Update Grower Failure',
  props<{ error: any }>()
);
