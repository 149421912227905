import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'src/app/core/services/api.service';
import { IGRWGrower } from 'src/app/shared/models/data-model/growers-module.interface';

@Injectable()
export class GrowersService {
  constructor(private _apiService: ApiService) {}

  fetchGrowers(): Observable<IGRWGrower[]> {
    return this._apiService
      .get('/shared/growers?showDeleted=true')
      .pipe(map((payload) => payload.growers));
  }

  updateGrower(grower: IGRWGrower): Observable<IGRWGrower> {
    const payload = {
      grower
    };
    return this._apiService
      .put('/shared/growers/' + payload.grower.ID, payload)
      .pipe(map((res) => res.grower));
  }
}
