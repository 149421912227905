import { Action } from '@ngrx/store';
import {
  ICornProduct,
  ICropFamily,
  IPackageType,
  IProduct,
  IProductsRequest
} from 'src/app/shared/models/data-model/products.interface';
import { ISeedTreatment } from 'src/app/shared/models/data-model/seed-treatment.interface';

export enum ProductsActionTypes {
  LoadCropFamilies = '[Global: Products] Load Crop Families',
  LoadCropFamiliesFail = '[Global: Products] Load Crop Families Fail',
  LoadCropFamiliesSuccess = '[Global: Products] Load Crop Families Success',

  LoadSeedTreatments = '[Global: Products] Load Seed Treatments',
  LoadSeedTreatmentsFail = '[Global: Products] Load Seed Treatments Fail',
  LoadSeedTreatmentsSuccess = '[Global: Products] Load Seed Treatments Success',

  LoadSoybeanProducts = '[Global: Products] Load Soybean Products',
  LoadSoybeanProductsFail = '[Global: Products] Load Soybean Products Fail',
  LoadSoybeanProductsSuccess = '[Global: Products] Load Soybean Products Success',

  LoadCornProducts = '[Global: Products] Load Corn Products',
  LoadCornProductsFail = '[Global: Products] Load Corn Products Fail',
  LoadCornProductsSuccess = '[Global: Products] Load Corn Products Success',

  LoadWheatProducts = '[Global: Products] Load Wheat Products',
  LoadWheatProductsFail = '[Global: Products] Load Wheat Products Fail',
  LoadWheatProductsSuccess = '[Global: Products] Load Wheat Products Success',

  FetchAllProducts = '[Global: Products] Fetch All Products',
  LoadPackageTypes = '[Global: Products] Load Package Types',
  LoadPackageTypesFail = '[Global: Products] Load Package Types Fail',
  LoadPackageTypesSuccess = '[Global: Products] Load Package Types Success'
}

export class LoadCropFamiliesAction implements Action {
  readonly type = ProductsActionTypes.LoadCropFamilies;
}
export class LoadCropFamiliesFailAction implements Action {
  readonly type = ProductsActionTypes.LoadCropFamiliesFail;
  constructor(public payload: any) {}
}
export class LoadCropFamiliesSuccessAction implements Action {
  readonly type = ProductsActionTypes.LoadCropFamiliesSuccess;
  constructor(public payload: ICropFamily[]) {}
}

export class LoadSeedTreatmentsAction implements Action {
  readonly type = ProductsActionTypes.LoadSeedTreatments;
}
export class LoadSeedTreatmentsFailAction implements Action {
  readonly type = ProductsActionTypes.LoadSeedTreatmentsFail;
  constructor(public payload: any) {}
}
export class LoadSeedTreatmentsSuccessAction implements Action {
  readonly type = ProductsActionTypes.LoadSeedTreatmentsSuccess;
  constructor(public payload: ISeedTreatment[]) {}
}

// Soybean products
export class LoadSoybeanProductsAction implements Action {
  readonly type = ProductsActionTypes.LoadSoybeanProducts;
  constructor(public payload: IProductsRequest) {}
}
export class LoadSoybeanProductsFailAction implements Action {
  readonly type = ProductsActionTypes.LoadSoybeanProductsFail;
  constructor(public payload: any) {}
}
export class LoadSoybeanProductsSuccessAction implements Action {
  readonly type = ProductsActionTypes.LoadSoybeanProductsSuccess;
  constructor(public payload: IProduct[]) {}
}

// Corn products
export class LoadCornProductsAction implements Action {
  readonly type = ProductsActionTypes.LoadCornProducts;
  constructor(public payload: IProductsRequest) {}
}
export class LoadCornProductsFailAction implements Action {
  readonly type = ProductsActionTypes.LoadCornProductsFail;
  constructor(public payload: any) {}
}
export class LoadCornProductsSuccessAction implements Action {
  readonly type = ProductsActionTypes.LoadCornProductsSuccess;
  constructor(public payload: ICornProduct[]) {}
}

// Wheat products
export class LoadWheatProductsAction implements Action {
  readonly type = ProductsActionTypes.LoadWheatProducts;
  constructor(public payload: IProductsRequest) {}
}
export class LoadWheatProductsFailAction implements Action {
  readonly type = ProductsActionTypes.LoadWheatProductsFail;
  constructor(public payload: any) {}
}
export class LoadWheatProductsSuccessAction implements Action {
  readonly type = ProductsActionTypes.LoadWheatProductsSuccess;
  constructor(public payload: IProduct[]) {}
}
export class FetchAllProductsAction implements Action {
  readonly type = ProductsActionTypes.FetchAllProducts;
}

// Package Types
export class LoadPackageTypesAction implements Action {
  readonly type = ProductsActionTypes.LoadPackageTypes;
}
export class LoadPackageTypesFailAction implements Action {
  readonly type = ProductsActionTypes.LoadPackageTypesFail;
  constructor(public payload: any) {}
}
export class LoadPackageTypesSuccessAction implements Action {
  readonly type = ProductsActionTypes.LoadPackageTypesSuccess;
  constructor(public payload: IPackageType[]) {}
}

export type ProductsActions =
  | LoadCropFamiliesAction
  | LoadCropFamiliesFailAction
  | LoadCropFamiliesSuccessAction
  | LoadCornProductsAction
  | LoadCornProductsFailAction
  | LoadCornProductsSuccessAction
  | LoadSoybeanProductsAction
  | LoadSoybeanProductsFailAction
  | LoadSoybeanProductsSuccessAction
  | LoadWheatProductsAction
  | LoadWheatProductsFailAction
  | LoadWheatProductsSuccessAction
  | FetchAllProductsAction
  | LoadPackageTypesAction
  | LoadPackageTypesFailAction
  | LoadPackageTypesSuccessAction
  | LoadSeedTreatmentsAction
  | LoadSeedTreatmentsFailAction
  | LoadSeedTreatmentsSuccessAction;
