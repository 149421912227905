import { createSelector, MemoizedSelector } from '@ngrx/store';
import { INotification } from 'src/app/shared/models/data-model/notifications.interface';
import { INotificationGroupVM } from 'src/app/shared/models/view-model/notifications-vm.interface';

import * as _ from 'lodash';

import * as fromFeature from '../reducers';
import * as fromReducer from '../reducers/notifications.reducer';

export const getNotificationsState = createSelector(
  fromFeature.getGlobalState,
  (state: fromFeature.GlobalState) => state.notifications
);

export const getNotificationsSocketInitialized = createSelector(
  getNotificationsState,
  fromReducer.getNotificationsSocketInitialized
);
export const getNotificationsSocketInitializing = createSelector(
  getNotificationsState,
  fromReducer.getNotificationsSocketInitializing
);
export const getNotificationsSocketErred = createSelector(
  getNotificationsState,
  fromReducer.getNotificationsSocketErred
);

// Notifications
export const getNotificationEntities = createSelector(
  getNotificationsState,
  fromReducer.getNotificationEntities
);
export const getNotificationsShowNotifications = createSelector(
  getNotificationsState,
  fromReducer.getNotificationsShowNotifications
);
export const getNotificationsShowAnimation = createSelector(
  getNotificationsState,
  fromReducer.getNotificationsShowAnimation
);
export const getNotificationsLoaded = createSelector(
  getNotificationsState,
  fromReducer.getNotificationsLoaded
);
export const getNotificationsLoading = createSelector(
  getNotificationsState,
  fromReducer.getNotificationsLoading
);
export const getNotificationsErred = createSelector(
  getNotificationsState,
  fromReducer.getNotificationsErred
);

/** DERIVED SELECTORS (no direct export from reducers) */

export const getNotifications: MemoizedSelector<object, INotification[]> = createSelector(
  getNotificationEntities,
  (entities) =>
    Object.keys(entities).map((ID) => {
      return entities[parseInt(ID, 10)];
    })
);

export const getNotificationGroups: MemoizedSelector<
  object,
  INotificationGroupVM[]
> = createSelector(getNotifications, (notifications) => {
  // return empty array if no notifications are available
  return (
    _.chain(notifications)
      // sort notifications by date
      .sortBy('date')
      .reverse()
      // group notifications by day
      .groupBy((notification) => new Date(notification.date).toDateString())
      .map((list, day) => {
        return {
          day,
          notifications: list
        };
      })
      .value()
  );
});

export const getNotificationsUnseenCount: MemoizedSelector<
  object,
  number
> = createSelector(getNotifications, (notifications) =>
  notifications.reduce((total, n) => total + (n.notificationSeen ? 0 : 1), 0)
);
