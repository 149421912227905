import { sanitizeForm } from 'src/app/shared/helpers/sanitizers.helper';
import { ProductionForecastState } from './../reducers/production-forecast.reducer';

export function sanitize(state: ProductionForecastState): {} | undefined {
  if (state && state.model && state.viewModel) {
    return {
      ...state,
      viewModel: {
        ...state.viewModel,
        detailsForm: sanitizeForm(state.viewModel.detailsForm)
      }
    };
  } else {
    return;
  }
}
