import { IAcreageOverview } from 'src/app/shared/models/data-model/acreage-overview.interface';
import { AcreageOverviewActions, AcreageOverviewActionTypes } from '../actions';

const defaultOverview: IAcreageOverview = {
  forecast: {
    fertile: 0,
    sterile: 0
  },
  plan: {
    fertile: 0,
    sterile: 0
  }
};

export interface AcreageOverviewState {
  model: {
    overview: IAcreageOverview;
    previousSnapshot: IAcreageOverview | null;
  };
  viewModel: {
    isPanelExpanded: boolean;
    isOverviewLoading: boolean;
    isOverviewLoaded: boolean;
    isOverviewErred: boolean;
    isSnapshotEnabled: boolean;
  };
}

export const initialState: AcreageOverviewState = {
  model: {
    overview: defaultOverview,
    previousSnapshot: null
  },
  viewModel: {
    isPanelExpanded: false,
    isOverviewLoading: false,
    isOverviewLoaded: false,
    isOverviewErred: false,
    isSnapshotEnabled: false
  }
};

export function reducer(
  state = initialState,
  action: AcreageOverviewActions
): AcreageOverviewState {
  switch (action.type) {
    case AcreageOverviewActionTypes.LoadAcreageOverviewAction: {
      const isSnapshotEnabled = action.payload;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isOverviewLoaded: false,
          isOverviewLoading: true,
          isOverviewErred: false,
          isSnapshotEnabled
        }
      };
    }
    case AcreageOverviewActionTypes.LoadAcreageOverviewFailAction: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isOverviewLoaded: false,
          isOverviewLoading: false,
          isSnapshotEnabled: false,
          isOverviewErred: true
        }
      };
    }
    case AcreageOverviewActionTypes.LoadAcreageOverviewSuccessAction: {
      const overview = action.payload;
      // if a snapshot is desired
      // keep previous overview as a previous snapshot
      const previousSnapshot = state.viewModel.isSnapshotEnabled ? state.model.overview : null;

      return {
        ...state,
        model: {
          ...state.model,
          overview,
          previousSnapshot
        },
        viewModel: {
          ...state.viewModel,
          isOverviewLoaded: true,
          isOverviewLoading: false,
          isSnapshotEnabled: false,
          isOverviewErred: false
        }
      };
    }

    case AcreageOverviewActionTypes.ToggleAcreageOverviewPanelExpandedAction: {
      const isPanelExpanded: boolean = !state.viewModel.isPanelExpanded;

      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          isPanelExpanded
        }
      };
    }

    default:
      return state;
  }
}

export const getAcreageOverview = (state: AcreageOverviewState) => state.model.overview;
export const getAcreageOverviewPreviousSnapshot = (state: AcreageOverviewState) =>
  state.model.previousSnapshot;
export const getAcreageOverviewLoaded = (state: AcreageOverviewState) =>
  state.viewModel.isOverviewLoaded;
export const getAcreageOverviewLoading = (state: AcreageOverviewState) =>
  state.viewModel.isOverviewLoading;
export const getAcreageOverviewErred = (state: AcreageOverviewState) =>
  state.viewModel.isOverviewErred;
export const getAcreageOverviewIsPanelExpanded = (state: AcreageOverviewState) =>
  state.viewModel.isPanelExpanded;
