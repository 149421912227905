import { Action } from '@ngrx/store';

export enum LotsActionTypes {
  CheckLotExistsAction = '[Global: Lots] Check Lot Exists',
  CheckLotExistsFailAction = '[Global: Lots] Check Lot Exists Fail',
  CheckLotExistsSuccessAction = '[Global: Lots] Check Lot Exists Success',

  NavigateToLotPageAction = '[Global: Lots] Navigate To Lot Page'
}

export class CheckLotExists implements Action {
  readonly type = LotsActionTypes.CheckLotExistsAction;
  constructor(public payload: string) {}
}
export class CheckLotExistsFail implements Action {
  readonly type = LotsActionTypes.CheckLotExistsFailAction;
}
export class CheckLotExistsSuccess implements Action {
  readonly type = LotsActionTypes.CheckLotExistsSuccessAction;
  constructor(public payload: number) {}
}

export class NavigateToLotPage implements Action {
  readonly type = LotsActionTypes.NavigateToLotPageAction;
}

export type LotsActions =
  | CheckLotExists
  | CheckLotExistsFail
  | CheckLotExistsSuccess
  | NavigateToLotPage;
