import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BecksSearchInputComponent } from './becks-search-input.component';

@NgModule({
  declarations: [BecksSearchInputComponent],
  imports: [CommonModule, MatIconModule, MatInputModule, FormsModule],
  exports: [BecksSearchInputComponent]
})
export class BecksSearchInputModule {}
