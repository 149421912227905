import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAppDefault } from 'src/app/shared/models/data-model/app-defaults.interface';
import { ApiService } from '../../core/services/api.service';

@Injectable()
export class AppDefaultsService {
  constructor(private _apiService: ApiService) {}

  public fetchAppDefaults(): Observable<IAppDefault[]> {
    return this._apiService
      .get('/shared/defaults')
      .pipe(map((payload) => payload.content.standardDefaults));
  }
}
