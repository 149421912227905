import { Action } from '@ngrx/store';
import { ISite } from 'src/app/shared/models/data-model/region.interface';

export enum SiteActionTypes {
  LoadSitesAction = '[Sites: List] Load Sites',
  LoadSitesFailAction = '[Sites: List] Load Sites Fail',
  LoadSitesSuccessAction = '[Sites: List] Load Sites Success'
}

export class LoadSites implements Action {
  readonly type = SiteActionTypes.LoadSitesAction;
  constructor(public regionID: null | number) {}
}

export class LoadSitesFail implements Action {
  readonly type = SiteActionTypes.LoadSitesFailAction;
  constructor(public payload: any) {}
}

export class LoadSitesSuccess implements Action {
  readonly type = SiteActionTypes.LoadSitesSuccessAction;
  constructor(public payload: ISite[]) {}
}

export type SiteActions = LoadSites | LoadSitesFail | LoadSitesSuccess;
