import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import environment from 'src/app/app.config';

@Component({
  selector: 'ps-environment-badge',
  template: `
    <div class="custom-badge">
      <ng-container *ngIf="sidebarOpened; else sidebarClosed">
        {{
          environmentName === 'qa' ? (environmentName | uppercase) : (environmentName | titlecase)
        }}
        <span
          [ngClass]="{
            env__sidebar: isFromSidebar,
            environment: isFromHome
          }"
          >&nbsp;Environment</span
        >
      </ng-container>
      <ng-template #sidebarClosed>{{ environmentAbbreviation | uppercase }}</ng-template>
    </div>
  `,
  styles: [
    `
      .custom-badge {
        overflow: hidden;
        white-space: nowrap;
        color: #ffffff;
        background-color: #ff0000;
        padding: 2px 16px;
        border-radius: 16px;
        font-weight: 700;
        font-weight: bold;
        display: flex;
        justify-content: center;
        line-height: 24px;
        font-size: 16px;
      }

      @media screen and (max-width: 430px) {
        .custom-badge {
          font-size: 12px;
          padding: 2px;
        }

        .environment {
          display: none;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvironmentBadgeComponent implements OnInit {
  @Input() sidebarOpened: boolean;
  @Input() isFromSidebar: boolean;
  @Input() isFromHome: boolean;
  environmentName: string = environment.name;
  constructor() {}

  ngOnInit(): void {}

  get environmentAbbreviation(): string {
    return this.environmentName === 'local' ? 'LOC' : this.environmentName.substring(0, 4);
  }
}
