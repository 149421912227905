import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as fromRoot from 'src/app/root-store/store/reducers';
import * as fromGlobal from '../reducers';
import * as fromSystemInfo from '../reducers/system-info.reducer';

import { EAppModule } from 'src/app/shared/models/data-model/app-module.interface';
import { EBreakpoints } from 'src/app/shared/models/data-model/layout.interface';

import { getAppModule, getBaseUri } from 'src/app/shared/helpers/app-modules.helper';

export const getSystemInfoState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.systemInfo
);

export const getSystemInfoBreakpointEntities = createSelector(
  getSystemInfoState,
  fromSystemInfo.getSystemInfoBreakpointEntities
);
export const getDisplayLoadingIndicator = createSelector(
  getSystemInfoState,
  fromSystemInfo.getDisplayLoadingIndicator
);

/** Derived Selectors */
export const getSystemInfoActiveBaseUri: MemoizedSelector<object, string | null> = createSelector(
  fromRoot.getRouterState,
  (routerState) => {
    if (!routerState) {
      return null;
    }

    return getBaseUri(routerState.state.urlWithoutQueryParams);
  }
);

export const getSystemInfoActiveAppModule: MemoizedSelector<
  object,
  EAppModule | null
> = createSelector(fromRoot.getRouterState, (routerState) => {
  if (!routerState) {
    return null;
  }

  // choose which module to assign depending on the url segment
  const appModule: EAppModule = getAppModule(routerState.state.urlWithoutQueryParams);
  return appModule;
});

export const getSystemInfoBreakpointActiveByID = (props: { breakpoint: EBreakpoints }) =>
  createSelector(getSystemInfoBreakpointEntities, (entities) => {
    return entities[props.breakpoint] ? entities[props.breakpoint].applied : false;
  });

// returns true if any of the breakpoints from the array are active
export const getSystemInfoBreakpointActiveFromArray = (props: { breakpoints: EBreakpoints[] }) =>
  createSelector(getSystemInfoBreakpointEntities, (entities) => {
    let hasActiveBreakpoint = false;

    props.breakpoints.forEach((breakpoint) => {
      if (entities[breakpoint] && entities[breakpoint].applied && !hasActiveBreakpoint) {
        hasActiveBreakpoint = true;
      }
    });
    return hasActiveBreakpoint;
  });

export const getSystemInfoBreakpointActiveFromArrayWithoutDeprecrated = (props: {
  breakpoints: EBreakpoints[];
}) =>
  createSelector(getSystemInfoBreakpointEntities, (entities) => {
    let hasActiveBreakpoint = false;

    props.breakpoints.forEach((breakpoint) => {
      if (entities[breakpoint] && entities[breakpoint].applied && !hasActiveBreakpoint) {
        hasActiveBreakpoint = true;
      }
    });
    return hasActiveBreakpoint;
  });
