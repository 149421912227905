import { AcreageOverviewEffects } from './acreage-overview.effects';
import { NotificationsEffects } from './notifications.effects';
import { LayoutEffects } from './layout.effects';
import { UserEffects } from './user.effects';
import { SystemInfoEffects } from './system-info.effects';
import { ProductionYearEffects } from './production-year.effects';
import { LotsEffects } from './lots.effects';
import { ProductsEffects } from './products.effects';
import { RegionsEffects } from './regions.effects';
import { SiteEffects } from './sites.effects';
import { GrowerEffects } from 'src/app/root-store/global-store/store/effects/growers.effects';
import { UsersEffects } from 'src/app/root-store/global-store/store/effects/users.effects';
import { AppDefaultsEffects } from './app-defaults.effects';

export const effects: any[] = [
  UserEffects,
  GrowerEffects,
  SystemInfoEffects,
  LayoutEffects,
  ProductionYearEffects,
  LotsEffects,
  NotificationsEffects,
  ProductsEffects,
  AcreageOverviewEffects,
  RegionsEffects,
  SiteEffects,
  UsersEffects,
  AppDefaultsEffects
];

export * from './acreage-overview.effects';
export * from './layout.effects';
export * from './lots.effects';
export * from './user.effects';
export * from './system-info.effects';
export * from './notifications.effects';
export * from './products.effects';
export * from './production-year.effects';
export * from './regions.effects';
export * from './sites.effects';
export * from './growers.effects';
export * from './users.effects';
