export const hasCodeInUrl = (hashOrSearch: string): boolean => {
  return /(code=)/i.test(hashOrSearch);
};

export const hasTokensInHash = (hash: string): boolean => {
  return /((id|access)_token=)/i.test(hash);
};

/**
 * Scrub scopes to ensure `openid` is included
 *
 * @param scopes Okta scopes
 */
export const scrubScopes = (scopes: string[]): string[] => {
  if (scopes.indexOf('openid') >= 0) {
    return scopes;
  }

  return ['openid', ...scopes];
};
