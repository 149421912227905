export enum DialogType {
  Unsaved,
  Delete,
  Confirm
}

export interface IDialogConfig {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  dialogType: DialogType;
  returnReference: boolean;
}

export interface IUnsavedConfig {
  title?: string;
  message?: string;
  continueText?: string;
  discardText?: string;
}

export interface IConfirmConfig {
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
}

export interface IDeleteConfig {
  itemName?: string;
  locationName?: string;
  title?: string;
  message?: string;
  deleteText?: string;
  cancelText?: string;
}
