// why use a route guard instead of a resolve?
// a.  we don't need a resolve because we are using a store
// b.  it gives us a benefit of controlling navigation to navigate away, etc
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadActionGuardFactoryService } from 'src/app/core/services/load-action-guard-factory.service';
import { LoadSeedTreatmentsAction } from 'src/app/root-store/global-store/store/actions';
import {
  getSeedTreatmentsErred,
  getSeedTreatmentsLoaded
} from 'src/app/root-store/global-store/store/selectors';

@Injectable()
export class SeedTreatmentsGuard implements CanActivate {
  constructor(private _guardFactory: LoadActionGuardFactoryService) {}

  canActivate(): Observable<boolean> {
    return this._guardFactory.canAdvance({
      loadAction: new LoadSeedTreatmentsAction(),
      isLoadedSelector: getSeedTreatmentsLoaded,
      hasErrorSelector: getSeedTreatmentsErred
    });
  }
}
