import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core/services/api.service';
import { IRegion, ISite, ISiteRegion } from '../../shared/models/data-model/region.interface';

@Injectable()
export class RegionsService {
  constructor(private _apiService: ApiService) {}

  public fetchRegions(): Observable<IRegion[]> {
    return this._apiService.get('/shared/growers/regions').pipe(map((payload) => payload.content));
  }

  public fetchSitesForRegion(regionID: number): Observable<ISite[]> {
    const apiPath = regionID ? '?regionID=' + regionID : '';
    return this._apiService
      .get('/seedTracker/sites' + apiPath)
      .pipe(map((payload) => payload.sites));
  }

  public fetchSites(): Observable<ISite[]> {
    return this._apiService.get('/seedTracker/sites').pipe(map((payload) => payload.sites));
  }

  public updateSiteRegion(site: ISiteRegion): Observable<void> {
    return this._apiService.put(
      `/seedTracker/sites/${site.ID}/regions/${site.regionDropdown.value.ID}`,
      {
        site
      }
    );
  }

  public updateRegion(region: IRegion): Observable<void> {
    const payload = {
      code: region.code,
      desc: region.desc
    };
    return this._apiService.put('/shared/growers/regions/' + region.ID, payload);
  }

  public createRegion(region: IRegion): Observable<IRegion[]> {
    const payload = {
      code: region.code,
      desc: region.desc
    };
    return this._apiService
      .post('/shared/growers/regions/', payload)
      .pipe(map((payloads) => payloads.content));
  }
}
