import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import environment from 'src/app/app.config';

import { Store } from '@ngrx/store';
import { catchError, filter, map, pairwise, startWith } from 'rxjs/operators';
import { getProductionYearSelectedYear } from 'src/app/root-store/global-store/store';
import { IProductionYear } from 'src/app/shared/models/data-model/production-year.interface';

const WS_ENDPOINT = environment.fsSocket.url;
const RECONNECT_INTERVAL = environment.fsSocket.reconnect_interval;
const RECONNECT_INTERVAL_FACTOR = environment.fsSocket.reconnect_interval_factor;

export type WebSocketSendResult = {
  success: boolean;
  message?: string;
};

export type WebSocketMessage = {
  app_id: string; // app message is coming from (FS, CAPPS, etc.)
  data: string[]; // string contains orgId,year pairs
  msg_type: string; // type of message (fields, crop_zones, etc.)
};

export enum FarmServerWebSocketStatus {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
  ERROR = 4,
  RECONNECTING = 5,
  USER_CLOSED = 6
}

@Injectable({
  providedIn: 'root'
})
export class FarmServerWebSocketService implements OnDestroy {
  // this stays static until we can work in rotational okta tokens on ProdSuite
  private _token = 'rqfh8N6Co0Kk0wKhgCKZrA';
  private _errorClosed = false;
  private _userClosed = false;
  private _debug = true;
  private _reconnectAttemptInterval = RECONNECT_INTERVAL;
  private _ws: WebSocket;
  private _socketSubject$ = new Subject<WebSocketMessage>();
  private _status$ = new BehaviorSubject<number>(FarmServerWebSocketStatus.CLOSED);
  private _reconnectTimeout;

  public cappsImport$: Observable<string[]> = this._socketSubject$.pipe(
    filter((message) => message.msg_type === 'fields' && message.app_id === 'CAPPS'),
    map((message) => message.data),
    catchError((e) => {
      throw e;
    })
  );

  public fsFields$: Observable<string[]> = this._socketSubject$.pipe(
    filter((message) => message.msg_type === 'fields' && message.app_id === 'FS'),
    map((message) => message.data),
    catchError((e) => {
      throw e;
    })
  );

  public cropZones$: Observable<string[]> = this._socketSubject$.pipe(
    filter((message) => message.msg_type === 'crop_zones'),
    map((message) => message.data),
    catchError((e) => {
      throw e;
    })
  );

  public status$ = this._status$.pipe(
    startWith(FarmServerWebSocketStatus.CLOSED),
    pairwise(),
    catchError((e) => {
      throw e;
    })
  );

  public errors$ = new Subject<any>();
  private selectedYear$: Observable<IProductionYear> = this._store.select(
    getProductionYearSelectedYear
  );
  private _subscriptions = new Subscription();

  constructor(private _store: Store) {
    this._subscriptions.add(
      combineLatest([this.selectedYear$, this.status$]).subscribe(([year, wsStatus]) => {
        const prevWebsocketStatus = wsStatus[0];
        const currWebsocketStatus = wsStatus[1];

        if (year?.YearNumber >= 2024) {
          // only run the import on the first time the websocket connects
          // anything after that should be handled by the websocket message
          if (
            (currWebsocketStatus === FarmServerWebSocketStatus.CLOSED ||
              currWebsocketStatus === FarmServerWebSocketStatus.USER_CLOSED) &&
            prevWebsocketStatus !== FarmServerWebSocketStatus.OPEN &&
            prevWebsocketStatus !== FarmServerWebSocketStatus.RECONNECTING
          ) {
            this.connect();
          }
        } else {
          if (currWebsocketStatus !== FarmServerWebSocketStatus.USER_CLOSED) {
            this.close();
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  public connect(reconnect: boolean = false): void {
    // no need to connect
    if (this._ws && this._ws.readyState === WebSocket.OPEN) {
      return;
    }

    // connect to the websocket
    this._ws = new WebSocket(WS_ENDPOINT, 'fs-capps-refresh');

    // set up the event handlers
    this._ws.onopen = () => {
      if (this._debug) {
        console.log('[FarmServerWebSocketService]: connection opened');
      }

      // reset the reconnect interval
      this._reconnectAttemptInterval = RECONNECT_INTERVAL;
      // reset the user closed flag
      this._userClosed = false;
      this._errorClosed = false;

      // when the connection is opened we need to send our token to authenticate
      this._ws.send(
        JSON.stringify({
          app_id: 'CAPPS',
          // dev token, put real token here if you want to test okta
          // and remove --bypass-okta from the server command
          token: this._token
        })
      );

      // update the status observable
      if (this._status$.getValue() !== FarmServerWebSocketStatus.OPEN) {
        this._status$.next(FarmServerWebSocketStatus.OPEN);
      }
    };

    this._ws.onmessage = (event) => {
      const message: WebSocketMessage = JSON.parse(event.data);

      this._socketSubject$.next(message);
    };

    this._ws.onclose = () => {
      if (this._debug) {
        console.log('[FarmServerWebSocketService]: connection closed');
      }

      // update the status observable
      if (this._status$.getValue() !== FarmServerWebSocketStatus.CLOSED) {
        this._status$.next(FarmServerWebSocketStatus.CLOSED);
      }

      // if the connect was not closed by the user or an error, try to reconnect
      if (!this._userClosed && !this._errorClosed) {
        this._reconnect();
      }
    };

    this._ws.onerror = (error) => {
      if (this._debug) {
        console.log('[FarmServerWebSocketService]: connection error');
      }

      this._errorClosed = true;

      // update the status observable
      if (this._status$.getValue() !== FarmServerWebSocketStatus.ERROR) {
        this._status$.next(FarmServerWebSocketStatus.ERROR);
      }

      // try to reconnect after an error
      this._reconnect();

      this.errors$.next(error);
    };
  }

  private _reconnect() {
    if (this._debug) {
      console.log('[FarmServerWebSocketService]: reconnecting');
    }

    if (this._status$.getValue() !== FarmServerWebSocketStatus.RECONNECTING) {
      this._status$.next(FarmServerWebSocketStatus.RECONNECTING);
    }

    // reconnect after a delay when the connection is closed
    this._reconnectTimeout = setTimeout(() => {
      // increase the reconnect interval
      this._reconnectAttemptInterval *= RECONNECT_INTERVAL_FACTOR;
      this.connect(true);
    }, this._reconnectAttemptInterval);
  }

  public close() {
    // if we call the close message we do not want the socket
    // to try to reconnect, so flag it as user closed
    this._userClosed = true;
    clearTimeout(this._reconnectTimeout);

    this._status$.next(FarmServerWebSocketStatus.USER_CLOSED);

    // make sure the connection is open
    if (this._ws && this._ws.readyState === WebSocket.OPEN) {
      this._ws.close();
      this._ws = undefined;
    } else if (this._debug) {
      console.log('[FarmServerWebSocketService]: no connection to close');
    }
  }

  public send(data: any, msg_type: string): WebSocketSendResult {
    if (this._debug) {
      console.log('[FarmServerWebSocketService]: sending message');
    }

    const websocketMessage: WebSocketMessage = {
      app_id: 'CAPPS',
      data,
      msg_type
    };

    try {
      // make sure the connection is open
      if (this._ws && this._ws.readyState === WebSocket.OPEN) {
        this._ws.send(JSON.stringify(websocketMessage));
        return {
          success: true
        };
      } else if (this._debug) {
        console.log('[FarmServerWebSocketService]: no connection to send message');
      }

      return {
        success: false,
        message: 'No connection to send message'
      };
    } catch (e) {
      return {
        success: false,
        message: e.message
      };
    }
  }
}
