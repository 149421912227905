import { Component, OnInit } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import environment from 'src/app/app.config';
import * as fromReducers from 'src/app/root-store/global-store/store/reducers';
import * as fromSelectors from 'src/app/root-store/global-store/store/selectors';
import { EAppModule } from 'src/app/shared/models/data-model/app-module.interface';

@Component({
  selector: 'ps-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  authorizedModules$ = this._store.select(fromSelectors.getUserAuthorizedModules);
  authorizedUmFeatures$ = this._store.select(fromSelectors.getAuthorizedUMFeatures);
  enumAppModule = EAppModule;
  env: string = environment.name;

  centered = false;
  disabled = false;
  unbounded = false;
  loading = false;

  radius: number;
  color: string;

  constructor(private router: Router, private _store: Store<fromReducers.GlobalState>) {
    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  getUMRoute(authorizedUmFeatures: { UMCAPPS: boolean; UMST: boolean; UMLAB: boolean }) {
    if (authorizedUmFeatures.UMCAPPS) {
      return '/userMgmt/capps/users';
    }

    if (authorizedUmFeatures.UMST) {
      return '/userMgmt/st/users';
    }

    if (authorizedUmFeatures.UMLAB) {
      return '/userMgmt/lab/users';
    }
  }

  ngOnInit() {}
}
