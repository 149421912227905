export interface ILayout {
  basePath: string;
  header: IHeader;
  sidebar: ISidebar;
}

export interface ITitleBar {
  title: string;
  subTitle: string;
  headerTag: string;
}

export interface IHeader {
  logoPath: string;
}

export interface ISidebar {
  brandPath: string;
  mobileLogoPath: string;
  menu: IMenuItem[];
}

export interface IMenuItem {
  name: string;
  path: string;
  icon: string;
  features?: string[];
  groups?: string[];
  subMenuItems?: ISubMenuItem[];
  subOpen?: boolean;
  hovered?: boolean;
  external?: boolean;
  queryParams?: {};
}

export interface ISubMenuItem {
  name: string;
  path: string;
  icon?: string;
  features?: string[];
  queryParams?: {};
}

export interface ILayoutMap {
  [appModule: string]: ILayout;
}

export interface IMenuMap {
  [key: string]: IMenuItem[];
}

export interface IBreakpointsObject {
  [breakpointName: string]: IBreakpoint;
}

export interface IBreakpoint {
  id: EBreakpoints;
  mediaQuery: string;
  applied: boolean;
}

export interface ILayoutFeatures {
  [key: string]: boolean;
}

export interface ILayoutFeaturesMap {
  [appModule: string]: ILayoutFeatures;
}

export enum EBreakpoints {
  XL = 'xl',
  GT_LG = 'gt-lg',
  LG = 'lg',
  GT_MD = 'gt-md',
  IPAD_PRO = 'ipad-pro',
  IPAD_PRO_LANDSCAPE = 'ipad-pro-landscape',
  MD = 'md',
  LT_MD = 'lt-md',
  GT_SM = 'gt-sm',
  IPAD_LANDSCAPE = 'ipad-landscape',
  SM = 'sm',
  GT_XS = 'gt-xs',
  XS_MAX = 'xs-max',
  XS = 'xs',
  XS_LANDSCAPE = 'xs-landscape',
  XXS_LANDSCAPE = 'xxs-landscape',
  XXXS_LANDSCAPE = 'xxxs-landscape',
  XXS = 'xxs',
  XXXS = 'xxxs',
  TW_SMALL = 'tw-sm',
  TW_MEDIUM = 'tw-md',
  TW_LARGE = 'tw-lg',
  TW_XL = 'tw-xl',
  TW_XXL = 'tw-xxl'
}

export enum ELayoutFeatures {
  acreageOverview = 'acreage-overview'
}
