import { createSelector } from '@ngrx/store';
import * as fromGlobal from '../reducers';

export const getUsersState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.users
);
export const getUsersLoaded = createSelector(getUsersState, (state) => state.viewModel.usersLoaded);
export const getUsersLoading = createSelector(
  getUsersState,
  (state) => state.viewModel.usersLoading
);
export const getUsersErred = createSelector(getUsersState, (state) => state.viewModel.usersError);

export const getUsers = createSelector(getUsersState, (state) => {
  const { ids, entities } = state.model.users;
  return (ids as number[]).map((id) => entities[id]);
});
