import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DialogType } from '../../models/confirmation-dialog.interfaces';

@Component({
  selector: 'becks-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  dialogType: DialogType;

  get isUnsavedModal() {
    return this.dialogType === DialogType.Unsaved;
  }
  get isDeleteModal() {
    return this.dialogType === DialogType.Delete;
  }
  get isConfirmModal() {
    return this.dialogType === DialogType.Confirm;
  }

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}
}
