import { EAppModule } from '../models/data-model/app-module.interface';

/**
 * Get based route uri from path.
 *
 * @param pathname string containing on or more route paths
 */
export function getBaseUri(pathname: string): string | null {
  // get base segment
  const match = pathname.match(/^\/([^\/]*)/);

  // no segment was found
  if (!Array.isArray(match) || match.length < 1) {
    return null;
  }

  const baseUrl: string = match[1];
  return baseUrl;
}

/**
 * Get EAppModule entry for a given route.
 *
 * @param baseUri string containing on or more route paths
 */
export function getAppModule(pathname: string): EAppModule | null {
  // base app module route. e.g. 'capps' or 'seedtracker'
  const baseUri = getBaseUri(pathname);

  switch (baseUri) {
    case 'capps': {
      return EAppModule.capps;
    }
    case 'seedtracker': {
      return EAppModule.seedtracker;
    }
    case 'labtracker': {
      return EAppModule.labtracker;
    }
    case 'userMgmt': {
      return EAppModule.usermanagement;
    }
    case 'documentation': {
      return EAppModule.documentation;
    }
    default: {
      return null;
    }
  }
}

/**
 * Get app module display name
 *
 * @param appModule module code
 */
export function getAppModuleDisplayName(appModule: EAppModule): string {
  switch (appModule) {
    case EAppModule.capps: {
      return 'CAPPS';
    }
    case EAppModule.seedtracker: {
      return 'SeedTracker';
    }
    case EAppModule.labtracker: {
      return 'Lab Tracker';
    }
    case EAppModule.documentation: {
      return 'Documentation';
    }
    case EAppModule.usermanagement: {
      return 'User Management';
    }
    default: {
      return '';
    }
  }
}
