import { AbstractControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

export function updateTreeValidity(control: AbstractControl) {
  if (control instanceof UntypedFormGroup) {
    const group = control as UntypedFormGroup;

    Object.keys(group.controls).forEach((field) => {
      const c = group.controls[field];

      updateTreeValidity(c);
    });
  } else if (control instanceof UntypedFormArray) {
    const group = control as UntypedFormArray;

    group.controls.forEach((c) => {
      updateTreeValidity(c);
    });
  }

  control.updateValueAndValidity({ onlySelf: false });
}
