export enum ECropCode {
  CORN = 'CORN',
  SOY = 'SOY',
  WHEAT = 'WHEAT'
}

export enum ECropID {
  CORN = 1,
  SOY = 2,
  WHEAT = 3
}

export const cropIdToCodeMap: { [id: number]: string } = {
  [ECropID.CORN]: ECropCode.CORN,
  [ECropID.SOY]: ECropCode.SOY,
  [ECropID.WHEAT]: ECropCode.WHEAT
};

export const cropCodeToIdMap: { [code: string]: number } = {
  [ECropCode.CORN]: ECropID.CORN,
  [ECropCode.SOY]: ECropID.SOY,
  [ECropCode.WHEAT]: ECropID.WHEAT
};

export enum ESeedTreatmentCode {
  SDS = 'ESCALATENEMASECTSDS'
}

export interface IBionikTreatmentComponent {
  ID: number;
  percentage: number;
  multiplier: number;
  order: number;
}
export interface IBionikTreatment {
  ID: number;
  code: string;
  components: IBionikTreatmentComponent[];
  description: string;
  order: number;
}

export interface ICropFamily {
  ID: number;
  code: ECropCode;
  desc: string;
}

export interface IInbred {
  ID: number;
  code: string;
  cropID: ECropID;
  generalNotes: string;
}

export interface IProductsRequest {
  cropCode: string;
  productionYear: number;
}
export interface IGenericDropdownItem {
  ID: number;
  code: string;
  description: string;
  order: number;
}
export interface IPlantingRate extends IGenericDropdownItem {}
export interface IDryingRate extends IGenericDropdownItem {}
export interface IDefolAnswer extends IGenericDropdownItem {}
export interface ISterileBreakRisk extends IGenericDropdownItem {}

export interface IV10ApplicationChoice extends IGenericDropdownItem {}
export interface IInbredSensitivity extends IGenericDropdownItem {}
export interface ICropProducts {
  [cropCode: string]: { [code: string]: IProduct | ICornProduct };
}
export interface IStaggerSheetInbred {
  ID: number;
  code: string;
}
export interface IStaggerSheetBionikOperation {
  treatment?: { ID?: number };
  details?: string;
  f: boolean;
  m1?: boolean;
  m2?: boolean;
  m3?: boolean;
  stage?: string;
  rate: { ID?: number };
  heatUnits?: number;
  midpointHeatUnits?: number;
}
export interface IStaggerSheetOperation {
  rate: { ID?: number };
  f: boolean;
  m: boolean;
  stage?: string;
  heatUnits?: number;
  midpointHeatUnits?: number;
  details?: string;
}
export interface IStaggerSheetOperationType {
  numberOfOperations: number;
  operations: (IStaggerSheetOperation | IStaggerSheetBionikOperation)[];
}
export interface IStaggerSheet {
  ID: number;
  cropID: ECropID;
  code: string;
  productionYearID: number;
  inbreds: { male: IStaggerSheetInbred; female: IStaggerSheetInbred };
  pollinatorClassID: number;
  bionik: IStaggerSheetOperationType;
  tech: IStaggerSheetOperationType;
  decision: IStaggerSheetOperationType;
  generalNotes: string;
  useBioNikForDecision: boolean;
  inbredType?: string;
  planID?: number;
  fieldID?: number;
  hybridID?: number;
  useTechsheetForDecision?: boolean;
}
export interface IProduct {
  ID: number;
  cropID?: number;
  code: string;
}

export interface IMaleFemaleSensitivities {
  acetamide: IInbredSensitivity;
  growthRegulator: IInbredSensitivity;
  SU: IInbredSensitivity;
  HPPDPre: IInbredSensitivity;
  HPPDPost: IInbredSensitivity;
  grayLeafSpot: number;
  commonRust: number;
  northernCornLeafBlight: number;
  gossWilt: number;
  notes: string;
}
export interface ISensitivities {
  okToSprayLiberty: boolean;
  v10DiseaseRiskScore: number;
  v10ApplicationChoice: IV10ApplicationChoice;
  plannedFungicideApplicationCount: number;
  male: IMaleFemaleSensitivities;
  female: IMaleFemaleSensitivities;
}
export interface ICornProduct extends IProduct {
  cropID: number;
  sterileUnitsPerAcre: number;
  fertileUnitsPerAcre: number;
  hybridExternalCodes: string;
  ugPerSeed: number;
  sameStagger: boolean;
  generalNotes: string;
  maleGDU: number;
  inbreds: ICornInbreds;
  pollinatorClassID: number;
  techSheet: ITechSheetInfo;
  actual: ITechSheetInfo;
  genSupplier: IGenSupplier;
  defol: IDefol;
  sensitivities: ISensitivities;
  finalProduct?: string;
  supplier?: string;
  ourMajorCode?: string;
  ourMinorCode?: string;
}

export interface ICornInbreds {
  fertile: ICornInbred;
  sterile?: ICornInbred;
}
export interface ICornInbred {
  male: ICornInbredProduct;
  female: ICornInbredProduct;
}
export interface ICornInbredProduct {
  ID: number;
  code: string;
  generalNotes: string;
  sterileBreakRisk?: ISterileBreakRisk;
}
export interface ITechSheetInfo {
  fertile: ITechSheet;
  sterile: ITechSheet;
  femalePercent: number;
}
export interface IPlantingPattern {
  ID: number;
  femalePercentage: number;
  malePercentage: number;
  order: number;
}

export interface ITechSheet {
  maleStand: number;
  femaleStand: number;
  silkGDU: number;
  maleDelays: number;
  yield: number;
  pile?: number;
  rhsGduTarget1?: number;
  rhsGduTarget2?: number;
}

export interface Supplier {
  ID: number;
  code: string;
  desc: string;
}

export interface IGenSupplier {
  ID: number;
  fertileCode: string;
  sterileCode: string;
}

export interface IDefol {
  flag: boolean;
  SELab: any;
  kerPerLb: number;
  meanSeedSize: number;
  discardPercentage: IDiscardPercentage;
  rangePercentage: IPercentageRange;
  harvestTarget: IPercentageRange;
  dryingRate: IDryingRate;
  harvestNotes: string;
  defolAnswer: IDefolAnswer;
  defolRisk: { ID: number };
}

export interface IDiscardPercentage {
  small: number;
  large: number;
}
export interface IPercentageRange {
  min: number;
  max: number;
  ideal?: number;
}

export interface IPackageType {
  ID: number;
  code: string;
  desc: string;
}

export interface IFertilityType {
  ID: number;
  code: string;
  desc: string;
  order?: number;
}

export interface IInventoryGraph {
  sites: IInventoryGraphSite[];
}

export interface IInventoryGraphSite {
  ID: number;
  balance: number;
  desc: string;
  fertileBalance: number;
  mixedBalance: number;
  sterileBalance: number;
  sub: IInventoryGraphSite[];
}
