import { GlobalState } from './../reducers/index';
import * as fromUserSanitizer from './user.sanitizer';

export function sanitize(state: GlobalState): {} | undefined {
  if (state) {
    return {
      ...state,
      user: fromUserSanitizer.sanitize(state.user)
    };
  } else {
    return;
  }
}
