import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TextMaskModule } from 'angular2-text-mask';
import { BecksInputSearchDirective } from 'src/app/library/components/becks-input/becks-input-search.directive';
import { BecksInputSuffixAnchorDirective } from 'src/app/library/components/becks-input/becks-input-suffix-anchor.directive';
import { BecksInputComponent } from 'src/app/library/components/becks-input/becks-input.component';
import { SelectOnEnterDirective } from 'src/app/library/components/becks-input/select-on-enter.directive';

@NgModule({
  declarations: [
    BecksInputComponent,
    SelectOnEnterDirective,
    BecksInputSearchDirective,
    BecksInputSuffixAnchorDirective
  ],
  imports: [CommonModule, MatInputModule, ReactiveFormsModule, TextMaskModule, MatTooltipModule],
  exports: [BecksInputComponent, BecksInputSearchDirective]
})
export class BecksInputModule {}
