import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as _ from 'lodash';
import {
  ECropCode,
  ICornProduct,
  ICropFamily,
  IPackageType,
  IProduct
} from 'src/app/shared/models/data-model/products.interface';
import { ISeedTreatment } from 'src/app/shared/models/data-model/seed-treatment.interface';
import { ProductsActions, ProductsActionTypes } from '../actions';

interface SeedTreatmentState extends EntityState<ISeedTreatment> {}

const seedTreatmentAdapter = createEntityAdapter<ISeedTreatment>({
  selectId: (seedTreatment) => seedTreatment.ID
});

export interface ProductsState {
  model: {
    productEntities: { [code: string]: { [code: string]: IProduct | ICornProduct } };
    cropFamilyEntities: { [code: string]: ICropFamily };
    packageTypeEntities: { [ID: number]: IPackageType };
    seedTreatments: SeedTreatmentState;
  };
  viewModel: {
    cropFamiliesLoading: boolean;
    cropFamiliesLoaded: boolean;
    cropFamiliesErred: boolean;
    seedTreatmentsLoading: boolean;
    seedTreatmentsLoaded: boolean;
    seedTreatmentsErred: boolean;
    cornProductsLoaded: boolean;
    cornProductsLoading: boolean;
    soybeanProductsLoaded: boolean;
    soybeanProductsLoading: boolean;
    wheatProductsLoaded: boolean;
    wheatProductsLoading: boolean;
    packageTypesLoading: boolean;
    packageTypesLoaded: boolean;
    packageTypesErred: boolean;
  };
}

export const initialState: ProductsState = {
  model: {
    productEntities: {},
    cropFamilyEntities: {},
    packageTypeEntities: {},
    seedTreatments: seedTreatmentAdapter.getInitialState()
  },
  viewModel: {
    cropFamiliesLoading: false,
    cropFamiliesLoaded: false,
    cropFamiliesErred: false,
    seedTreatmentsLoading: false,
    seedTreatmentsLoaded: false,
    seedTreatmentsErred: false,
    cornProductsLoaded: false,
    cornProductsLoading: false,
    soybeanProductsLoaded: false,
    soybeanProductsLoading: false,
    wheatProductsLoaded: false,
    wheatProductsLoading: false,
    packageTypesLoading: false,
    packageTypesLoaded: false,
    packageTypesErred: false
  }
};

export function reducer(state = initialState, action: ProductsActions): ProductsState {
  switch (action.type) {
    case ProductsActionTypes.LoadCropFamilies: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          cropFamiliesLoading: true,
          cropFamiliesLoaded: false,
          cropFamiliesErred: false
        }
      };
    }
    case ProductsActionTypes.LoadCropFamiliesFail: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          cropFamiliesLoading: false,
          cropFamiliesLoaded: false,
          cropFamiliesErred: true
        }
      };
    }
    case ProductsActionTypes.LoadCropFamiliesSuccess: {
      const cropFamilies: ICropFamily[] = action.payload;
      const cropFamilyEntities = _.keyBy(cropFamilies, 'code');
      return {
        ...state,
        model: {
          ...state.model,
          cropFamilyEntities
        },
        viewModel: {
          ...state.viewModel,
          cropFamiliesLoading: false,
          cropFamiliesLoaded: true,
          cropFamiliesErred: false
        }
      };
    }
    case ProductsActionTypes.LoadSeedTreatments: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          seedTreatmentsLoading: true,
          seedTreatmentsLoaded: false,
          seedTreatmentsErred: false
        }
      };
    }
    case ProductsActionTypes.LoadSeedTreatmentsFail: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          seedTreatmentsLoaded: false,
          seedTreatmentsLoading: false,
          seedTreatmentsErred: true
        }
      };
    }
    case ProductsActionTypes.LoadSeedTreatmentsSuccess: {
      const seedTreatments: ISeedTreatment[] = action.payload;
      return {
        ...state,
        model: {
          ...state.model,
          seedTreatments: seedTreatmentAdapter.setAll(seedTreatments, state.model.seedTreatments)
        },
        viewModel: {
          ...state.viewModel,
          seedTreatmentsLoading: false,
          seedTreatmentsLoaded: true,
          seedTreatmentsErred: false
        }
      };
    }
    case ProductsActionTypes.LoadCornProducts: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          cornProductsLoading: true,
          cornProductsLoaded: false
        }
      };
    }
    case ProductsActionTypes.LoadCornProductsFail: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          cornProductsLoading: false,
          cornProductsLoaded: false
        }
      };
    }
    case ProductsActionTypes.LoadCornProductsSuccess: {
      const cornProducts: ICornProduct[] = action.payload;

      return {
        ...state,
        model: {
          ...state.model,
          productEntities: {
            ...state.model.productEntities,
            [ECropCode.CORN]: _.keyBy(cornProducts, 'ID')
          }
        },
        viewModel: {
          ...state.viewModel,
          cornProductsLoading: false,
          cornProductsLoaded: true
        }
      };
    }
    case ProductsActionTypes.LoadSoybeanProducts: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          soybeanProductsLoading: true,
          soybeanProductsLoaded: false
        }
      };
    }
    case ProductsActionTypes.LoadSoybeanProductsFail: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          soybeanProductsLoading: false,
          soybeanProductsLoaded: false
        }
      };
    }
    case ProductsActionTypes.LoadSoybeanProductsSuccess: {
      const soybeanProducts: IProduct[] = action.payload;

      return {
        ...state,
        model: {
          ...state.model,
          productEntities: {
            ...state.model.productEntities,
            [ECropCode.SOY]: _.keyBy(soybeanProducts, 'ID')
          }
        },
        viewModel: {
          ...state.viewModel,
          soybeanProductsLoading: false,
          soybeanProductsLoaded: true
        }
      };
    }
    case ProductsActionTypes.LoadWheatProducts: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          wheatProductsLoading: true,
          wheatProductsLoaded: false
        }
      };
    }
    case ProductsActionTypes.LoadWheatProductsFail: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          wheatProductsLoading: false,
          wheatProductsLoaded: false
        }
      };
    }
    case ProductsActionTypes.LoadWheatProductsSuccess: {
      const wheatProducts: IProduct[] = action.payload;

      return {
        ...state,
        model: {
          ...state.model,
          productEntities: {
            ...state.model.productEntities,
            [ECropCode.WHEAT]: _.keyBy(wheatProducts, 'ID')
          }
        },
        viewModel: {
          ...state.viewModel,
          wheatProductsLoading: false,
          wheatProductsLoaded: true
        }
      };
    }
    case ProductsActionTypes.LoadPackageTypes: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          packageTypesLoading: true,
          packageTypesLoaded: false,
          packageTypesErred: false
        }
      };
    }
    case ProductsActionTypes.LoadPackageTypesFail: {
      return {
        ...state,
        viewModel: {
          ...state.viewModel,
          packageTypesLoading: false,
          packageTypesLoaded: false,
          packageTypesErred: true
        }
      };
    }
    case ProductsActionTypes.LoadPackageTypesSuccess: {
      const packageTypes: IPackageType[] = action.payload;
      const packageTypeEntities = _.keyBy(packageTypes, 'ID');
      return {
        ...state,
        model: {
          ...state.model,
          packageTypeEntities
        },
        viewModel: {
          ...state.viewModel,
          packageTypesLoading: false,
          packageTypesLoaded: true,
          packageTypesErred: false
        }
      };
    }
    default:
      return state;
  }
}

export const getCropFamilyEntities = (state: ProductsState) => state.model.cropFamilyEntities;
export const getCropFamiliesLoaded = (state: ProductsState) => state.viewModel.cropFamiliesLoaded;
export const getCropFamiliesLoading = (state: ProductsState) => state.viewModel.cropFamiliesLoading;
export const getCropFamiliesErred = (state: ProductsState) => state.viewModel.cropFamiliesErred;

// export const getCornProductEntities = (state: ProductsState) => state.model.cornProductEntities;
export const getCornProductLoaded = (state: ProductsState) => state.viewModel.cornProductsLoaded;
export const getCornProductLoading = (state: ProductsState) => state.viewModel.cornProductsLoading;

export const getSeedTreatments = (state: ProductsState) => {
  const { ids, entities } = state.model.seedTreatments;
  return (ids as number[]).map((id) => entities[id]);
};
export const getSeedTreatmentsEntities = (state: ProductsState) =>
  state.model.seedTreatments.entities;
export const getSeedTreatmentsLoaded = (state: ProductsState) =>
  state.viewModel.seedTreatmentsLoaded;
export const getSeedTreatmentsLoading = (state: ProductsState) =>
  state.viewModel.seedTreatmentsLoading;
export const getSeedTreatmentsErred = (state: ProductsState) => state.viewModel.seedTreatmentsErred;

export const getSoybeanProductLoaded = (state: ProductsState) =>
  state.viewModel.soybeanProductsLoaded;
export const getSoybeanProductLoading = (state: ProductsState) =>
  state.viewModel.soybeanProductsLoading;

export const getWheatProductLoaded = (state: ProductsState) => state.viewModel.wheatProductsLoaded;
export const getWheatProductLoading = (state: ProductsState) =>
  state.viewModel.wheatProductsLoading;

export const getProductEntities = (state: ProductsState) => state.model.productEntities;

export const getPackageTypeEntities = (state: ProductsState) => state.model.packageTypeEntities;
export const getPackageTypesLoaded = (state: ProductsState) => state.viewModel.packageTypesLoaded;
export const getPackageTypesLoading = (state: ProductsState) => state.viewModel.packageTypesLoading;
export const getPackageTypesErred = (state: ProductsState) => state.viewModel.packageTypesErred;
