import { createSelector } from '@ngrx/store';
import * as fromGlobal from '../reducers';
import * as fromReducers from '../reducers/regions.reducer';

export const getRegionsState = createSelector(
  fromGlobal.getGlobalState,
  (state: fromGlobal.GlobalState) => state.regions
);

const getModel = createSelector(getRegionsState, (state) => state.model);

const getRegionsAdapter = createSelector(getModel, (model) => model.regions);

export const {
  selectEntities: getRegionEntities,
  selectAll: getAllRegions
} = fromReducers.siteManagementRegionsAdapter.getSelectors(getRegionsAdapter);

export const getRegionsLoaded = createSelector(
  getRegionsState,
  (state) => state.viewModel.regionsLoaded
);
export const getRegionsLoading = createSelector(
  getRegionsState,
  (state) => state.viewModel.regionsLoading
);
export const getRegionsErred = createSelector(
  getRegionsState,
  (state) => state.viewModel.regionsErred
);
