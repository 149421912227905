import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Data,
  Route,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import * as fromActions from 'src/app/root-store/global-store/store/actions';
import * as fromReducers from 'src/app/root-store/global-store/store/reducers';
import * as fromSelectors from 'src/app/root-store/global-store/store/selectors';
import { getAppModule } from 'src/app/shared/helpers/app-modules.helper';
import { UserService } from '../../services/user.service';

@Injectable()
export class AuthorizationGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private _store: Store<fromReducers.GlobalState>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkStore(next.data).pipe(
      // returns observable of true if things have gone correctly
      switchMap((isAuthorized) => of(isAuthorized)),
      // else returns observable of false if things have gone awry
      catchError(() => of(false))
    );
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(next, state);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.checkStore(route.data).pipe(
      // returns observable of true if things have gone correctly
      switchMap((isAuthorized) => of(isAuthorized)),
      // else returns observable of false if things have gone awry
      catchError(() => of(false))
    );
  }

  /**
   * This method creates an observable that waits for the `loaded` property
   * of the collection state to turn `true`, emitting one time once loading
   * has finished.
   */
  waitForPermissionsToLoad(): Observable<boolean> {
    return this._store.select(fromSelectors.getUserPermissionsLoaded).pipe(
      filter((loaded) => loaded),
      take(1)
    );
  }

  checkPermissions(data: Data | undefined): Observable<boolean> {
    // there is not data object
    if (!data) {
      return of(true);
    }

    // no features are required
    const features: string[] = data.features;
    if (!Array.isArray(features) || features.length === 0) {
      return of(true);
    }

    return this._store.select(fromSelectors.getUserPermissions).pipe(
      map((permissions) => {
        // the base path coming from the store and the active module are still not set when using canLoad
        // thus, getting the pathname coming from the browser is necessary (https://stackoverflow.com/a/49171264/6163110)
        // or use canActivate (https://stackoverflow.com/a/46689852/6163110)
        const appModule = getAppModule(window.location.pathname);
        const isAuthorized = UserService.hasFeatureFromList(permissions, appModule, features);

        if (!isAuthorized) {
          this._store.dispatch(new fromActions.UserNotAuthorized());
        }

        return isAuthorized;
      }),
      take(1)
    );
  }

  checkStore(data: Data | undefined): Observable<boolean> {
    return this.waitForPermissionsToLoad().pipe(switchMap(() => this.checkPermissions(data)));
  }
}
