import { ApiService } from 'src/app/core/services/api.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LotsService {
  constructor(private _apiService: ApiService) {}

  lotExists(lotCode: string) {
    return this._apiService
      .get(`/seedTracker/harvestLots/exists/${lotCode}`)
      .pipe(map((body) => body.content.ID));
  }
}
