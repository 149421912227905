export interface IUser {
  id?: number;
  beckUserID?: number;
  dsmID?: number;
  atlID?: number;
  rbmID?: number;
  authToken?: string;
  email: string;
  expires?: number;
  firstName: string;
  lastName: string;
  type?: EUserType;
  userName: string;
  userStatusExp?: number;
  pictureUrl: string;
  lastUpdated?: string;
  appAccess?: string[];
}

export interface IUserPermissions {
  [key: string]: IUserAppPermissions;
}

export interface IUserFeatureGroup {
  featureGroupCode: string;
  features: string[];
}

export interface IUserModule {
  moduleCode: string;
  featureGroups: IUserFeatureGroup[];
}

export interface IUserAppPermissions {
  featureGroups: { [key: string]: boolean };
  features: { [key: string]: boolean };
}

export enum EUserType {
  AD_INTERNAL = 'AD_INTERNAL',
  AD_EXTERNAL = 'AD_EXTERNAL'
}

export interface IFeature {
  id: number;
  code: string;
  desc: string;
  disabled?: boolean;
  listOrder?: number;
  optional?: boolean;
}

export interface IUserPreferences {
  [key: string]: IUserAppPreferences;
}

export interface IUserAppPreferences {
  [key: string]: any;
}

export interface IUserPreferenceSettingType {
  ID?: number;
  code: string;
  desc?: string;
}

export interface IPSUser {
  ID: number;
  beckUserID: number;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  pictureUrl: string;
  enabled: boolean;
  isAdmin: boolean;
  mobileNumber: number;
  mobileProvider: {
    ID: string;
    code: string;
    desc: string;
  };
  selected: boolean;
  fullName?: string;
}
