import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import {
  DialogType,
  IConfirmConfig,
  IDeleteConfig,
  IDialogConfig,
  IUnsavedConfig
} from '../models/confirmation-dialog.interfaces';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}

  unsaved(config?: IUnsavedConfig): Observable<boolean>;
  unsaved(
    config: IUnsavedConfig,
    returnReference: boolean
  ): MatDialogRef<ConfirmationDialogComponent>;
  unsaved(
    { title, message, continueText, discardText }: IUnsavedConfig = {},
    returnReference: boolean = false
  ) {
    return this._open({
      title: title ?? 'Discard changes?',
      message: message ?? 'There are changes to this page that have not been saved.',
      confirmText: discardText ?? 'Discard',
      cancelText: continueText ?? 'Continue Editing',
      dialogType: DialogType.Unsaved,
      returnReference
    });
  }

  delete(config: IDeleteConfig): Observable<boolean>;
  delete(
    config: IDeleteConfig,
    returnReference: boolean
  ): MatDialogRef<ConfirmationDialogComponent>;
  delete(
    { itemName, locationName, title, message, deleteText, cancelText }: IDeleteConfig,
    returnReference: boolean = false
  ) {
    return this._open({
      title: title ?? 'Delete item?',
      message:
        message ??
        `Are you sure you want to delete product ${itemName} from this ${locationName} scenario?`,
      confirmText: deleteText ?? 'Delete',
      cancelText: cancelText ?? 'Cancel',
      dialogType: DialogType.Delete,
      returnReference
    });
  }

  confirm(config: IConfirmConfig): Observable<boolean>;
  confirm(
    config: IConfirmConfig,
    returnReference: boolean
  ): MatDialogRef<ConfirmationDialogComponent>;
  confirm(
    { title, message, confirmText, cancelText }: IConfirmConfig,
    returnReference: boolean = false
  ) {
    return this._open({
      title,
      message,
      confirmText: confirmText ?? 'Confirm',
      cancelText: cancelText ?? 'Cancel',
      dialogType: DialogType.Confirm,
      returnReference
    });
  }

  private _open({
    title,
    message,
    confirmText,
    cancelText,
    dialogType,
    returnReference = false
  }: IDialogConfig) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: true
    });
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.confirmText = confirmText;
    dialogRef.componentInstance.cancelText = cancelText;
    dialogRef.componentInstance.dialogType = dialogType;

    return returnReference ? dialogRef : dialogRef.afterClosed();
  }
}
