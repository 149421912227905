import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { IAppDefault } from 'src/app/shared/models/data-model/app-defaults.interface';

import * as fromActions from '../actions';

interface AppDefaultsEntityState extends EntityState<IAppDefault> {}

export const applicationDefaultsStateAdapter = createEntityAdapter<IAppDefault>({
  selectId: ({ prodYearID, stdEntryCode }) => {
    return `${prodYearID}_${stdEntryCode}`;
  }
});

export interface AppDefaultsState {
  model: {
    appDefaults: AppDefaultsEntityState;
  };
  viewModel: {
    appDefaultsLoaded: boolean;
    appDefaultsLoading: boolean;
    appDefaultsErred: boolean;
  };
}

export const initialState: AppDefaultsState = {
  model: {
    appDefaults: applicationDefaultsStateAdapter.getInitialState()
  },
  viewModel: {
    appDefaultsLoaded: false,
    appDefaultsLoading: false,
    appDefaultsErred: false
  }
};

export const appDefaultsReducer = createReducer(
  initialState,
  on(fromActions.loadAppDefaults, (state) => ({
    ...state,
    viewModel: {
      ...state.viewModel,
      appDefaultsLoading: true,
      appDefaultsLoaded: false,
      appDefaultsErred: false
    }
  })),
  on(fromActions.loadAppDefaultsSuccess, (state, { appDefaults }) => ({
    ...state,
    model: {
      ...state.model,
      appDefaults: applicationDefaultsStateAdapter.setAll(appDefaults, state.model.appDefaults)
    },
    viewModel: {
      ...state.viewModel,
      appDefaultsLoading: false,
      appDefaultsLoaded: true,
      appDefaultsErred: false
    }
  })),
  on(fromActions.loadAppDefaultsFailure, (state) => ({
    ...state,
    model: {
      ...state.model,
      appDefaults: applicationDefaultsStateAdapter.getInitialState()
    },
    viewModel: {
      ...state.viewModel,
      appDefaultsLoading: false,
      appDefaultsLoaded: false,
      appDefaultsErred: true
    }
  }))
);
