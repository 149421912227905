export interface IBeckError extends Error {
  status?: number;
  code?: number;
}

export class ApiError extends Error implements IBeckError {
  status: number;

  constructor(message?: string, status?: number) {
    super(message); // ‘Error’ breaks prototype chain here
    this.status = status || null;
    this.name = this.constructor.name;

    // restore prototype chain
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
